import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
// core components

import styles from "assets/jss/material-kit-pro-react/components/customInputStyle.js";

const useStyles = makeStyles(styles);

export const CustomFormInput = React.forwardRef((props, ref) => {
  const {
    name,
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error = {},
    white,
    inputRootCustomClasses,
    success,
    register,
  } = props;
  const classes = useStyles();
  const errorText = error[name]?.message || "";
  const labelClasses = classNames({
    [" " + classes.labelRootError]: errorText,
    [" " + classes.labelRootSuccess]: success && !errorText,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: errorText,
    [classes.underlineSuccess]: success && !errorText,
    [classes.underline]: true,
    [classes.whiteUnderline]: white,
  });
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }
  let newInputProps = {
    maxLength: inputProps ? inputProps.maxLength : undefined,
    minLength: inputProps ? inputProps.minLength : undefined,
  };
  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + " " + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <Input
        classes={{
          input: inputClasses,
          root: marginTop,
          underline: underlineClasses,
        }}
        name={name}
        inputRef={ref}
        id={id}
        {...inputProps}
        inputProps={newInputProps}
      />
      {errorText ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
      {errorText && <p className={classes.labelRootError}>{errorText}</p>}
    </FormControl>
  );
});

CustomFormInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  name: PropTypes.string.required,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.string,
  success: PropTypes.bool,
  white: PropTypes.bool,
};
