import React from "react";
import style from "./DonatePaypalStyle";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(style);

const DonatePaypal = ({ text, buttonText }) => {
  const classes = useStyles();

  return (
    <div className={classes.donateContainer}>
      <h3 className={classes.text}>{text}</h3>
      <div className={classes.buttonContainer}>
        <form
          action="https://www.paypal.com/donate"
          method="post"
          target="_top"
        >
          <input
            type="hidden"
            name="hosted_button_id"
            value={process.env.REACT_APP_PAYPAL_DONATION}
          />
          <button className={classes.paypalButton} type="submit" target="_top">
            <span className="ppvx_icon--svg___6-10-0 ppvx_icon--size_sm___6-10-0 ppvx_btn__icon___5-11-7">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
                width="1em"
                height="1em"
              >
                <path d="M10.365 6.737c.145-.07.299-.105.46-.105h4.356c.542 0 1.045.037 1.495.11a6.486 6.486 0 0 1 .757.17c.06.017.119.036.177.056.059.02.113.042.169.063.088-1.198-.245-2.034-.82-2.674C16.12 3.42 14.636 3 12.721 3H7.546a.796.796 0 0 0-.785.676L4.446 18.448a.48.48 0 0 0 .472.555h3.048l1.81-11.469c.054-.347.274-.645.59-.797z"></path>
                <path d="M17.38 7.667a4.557 4.557 0 0 0-.475-.13 6 6 0 0 0-.345-.067 8.708 8.708 0 0 0-1.38-.101h-4.045a.7.7 0 0 0-.687.591l-.73 4.676a1.42 1.42 0 0 1 .25-.024h1.63c.865 0 1.637-.098 2.32-.295 1.727-.5 2.888-1.63 3.47-3.428.128-.399.222-.766.289-1.11a4.55 4.55 0 0 0-.296-.112z"></path>
                <path d="M18.878 8.518a2.482 2.482 0 0 0-.494-.42c-.45 2.195-1.796 5.286-6.838 5.286l-1.25-.004a.793.793 0 0 0-.782.675s-.972 6.158-1.02 6.481c-.037.256.183.464.415.464h2.509a.697.697 0 0 0 .687-.591l.608-3.818A.697.697 0 0 1 13.4 16h.432c2.802 0 4.995-1.146 5.636-4.46a5.88 5.88 0 0 0 .051-.297c.132-1.11-.047-2.043-.64-2.725z"></path>
              </svg>
            </span>
            {buttonText}
          </button>
        </form>
      </div>
    </div>
  );
};

export default DonatePaypal;
