import {
  ACTIVITY_GALA_DINNER,
  ACTIVITY_THERMAL_BATHS,
  ACTIVITY_MEZCALA,
  ACTIVITY_LOVE_IN_ACTION,
  ACTIVITY_PIRAMIDES,
  HOTEL_LOURDES,
  HOTEL_HIDALGO,
  HOTEL_LOURDES_EXTRA_NIGHT,
  HOTEL_HIDALGO_EXTRA_NIGHT,
  PACKAGE_CONVENTION_WITHOUT_HOTEL,
  ACTIVITY_WORKSHOP,
} from "./registrationForm";

const USD_CURRENCY = "USD";
const MEXICAN_CURRENCY = "MXN";

export const CONVENTION_CURRENCY = MEXICAN_CURRENCY; //Tambien cambiar la currency en el <script> del index.html
export const PACKAGE_CONVENTION_WITHOUT_HOTEL_COST = "conventionWithoutHotel";

const EARLY_BIRD_2024_MEX = {
  justConvention: 8936.42,
  lourdesBaseCost: 12311.15,
  lourdesExtraNight: 1845.69,

  hidalgoBaseCost: 8757.21,
  hidalgoExtraNight: 1354.82,

  galaDinner: 1154.18,
  baths: 964.97,
  mezcala: 983.89,
  loveInAction: 0,
  guachimontones: 1229.87,
  workshop: 850,
};

const ACTIVE_PRICES = EARLY_BIRD_2024_MEX;

export const HOTEL_LOURDES_BASE_COST = ACTIVE_PRICES.lourdesBaseCost;
export const HOTEL_LOURDES_EXTRA_NIGHT_COST = ACTIVE_PRICES.lourdesExtraNight;

export const HOTEL_HIDALGO_BASE_COST = ACTIVE_PRICES.hidalgoBaseCost;
export const HOTEL_HIDALGO_EXTRA_NIGHT_COST = ACTIVE_PRICES.hidalgoExtraNight;

/* export const HOTEL_LOURDES_BASE_EARLY_BIRD_COST = 689.7;
export const HOTEL_LOURDES_EXTRA_NIGHT_EARLY_BIRD_COST = 103.4;
export const HOTEL_HIDALGO_BASE_EARLY_BIRD_COST = 103.4;
export const HOTEL_HIDALGO_EXTRA_NIGHT_EARLY_BIRD_COST = 79.2; */

export const JUST_CONVENTION_COST = ACTIVE_PRICES.justConvention;

export const ACTIVITY_GALA_DINNER_COST = ACTIVE_PRICES.galaDinner;
export const ACTIVITY_THERMAL_BATHS_COST = ACTIVE_PRICES.baths;
export const ACTIVITY_MEZCALA_COST = ACTIVE_PRICES.mezcala;
export const ACTIVITY_LOVE_IN_ACTION_COST = ACTIVE_PRICES.loveInAction;
export const ACTIVITY_PIRAMIDES_COST = ACTIVE_PRICES.guachimontones;
export const ACTIVITY_WORKSHOP_COST = ACTIVE_PRICES.workshop;

const regularCost = {};
regularCost[ACTIVITY_GALA_DINNER] = ACTIVITY_GALA_DINNER_COST;
regularCost[ACTIVITY_THERMAL_BATHS] = ACTIVITY_THERMAL_BATHS_COST;
regularCost[ACTIVITY_MEZCALA] = ACTIVITY_MEZCALA_COST;
regularCost[ACTIVITY_LOVE_IN_ACTION] = ACTIVITY_LOVE_IN_ACTION_COST;
regularCost[ACTIVITY_GALA_DINNER] = ACTIVITY_GALA_DINNER_COST;
regularCost[ACTIVITY_PIRAMIDES] = ACTIVITY_PIRAMIDES_COST;
regularCost[HOTEL_LOURDES] = HOTEL_LOURDES_BASE_COST;
regularCost[HOTEL_HIDALGO] = HOTEL_HIDALGO_BASE_COST;
regularCost[HOTEL_LOURDES_EXTRA_NIGHT] = HOTEL_LOURDES_EXTRA_NIGHT_COST;
regularCost[HOTEL_HIDALGO_EXTRA_NIGHT] = HOTEL_HIDALGO_EXTRA_NIGHT_COST;
regularCost[PACKAGE_CONVENTION_WITHOUT_HOTEL] = JUST_CONVENTION_COST;
regularCost[ACTIVITY_WORKSHOP] = ACTIVITY_WORKSHOP_COST;

/* const earlyBirdCost = { ...regularCost };
earlyBirdCost[HOTEL_LOURDES] = HOTEL_LOURDES_BASE_EARLY_BIRD_COST;
earlyBirdCost[HOTEL_HIDALGO] = HOTEL_HIDALGO_BASE_EARLY_BIRD_COST;
earlyBirdCost[
  HOTEL_LOURDES_EXTRA_NIGHT
] = HOTEL_LOURDES_EXTRA_NIGHT_EARLY_BIRD_COST;
earlyBirdCost[
  HOTEL_HIDALGO_EXTRA_NIGHT
] = HOTEL_HIDALGO_EXTRA_NIGHT_EARLY_BIRD_COST; */

export { regularCost };
