import { error } from "actions/auth";
import { types } from "../types/authTypes";

export const authReducer = (state = {}, action) => {
  switch (action.type) {
    case types.login:
      return {
        uid: action.payload.uid,
        name: action.payload.displayName,
        email: action.payload.email,
      };
    case types.logout:
      return {};

    case types.error:
      return { error: { ...action.payload.error } };
    default:
      return state;
  }
};
