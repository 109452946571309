export const STEP_PERSONAL_INFO = "personalInfo";
export const STEP_ACCOMMODATION_INFO = "accommodationInfo";
export const STEP_ACTIVITY_INFO = "activityInfo";
export const STEP_ADDITIONAL_INFO = "additionalInfo";
export const STEP_PAYMENT = "payment";
export const STEP_SUMMARY = "summary";

export const PACKAGE_CONVENTION_WITH_HOTEL = "conventionWithHotel";
export const PACKAGE_CONVENTION_WITHOUT_HOTEL = "conventionWithoutHotel";
export const PACKAGE_NO_CONVENTION = "noConvention";

export const TITLE_NONE = "none";
export const TITLE_MINISTER = "minister";
export const TITLE_PRACTITIONER = "practitioner";

export const HOTEL_LOURDES = "lourdes";
export const HOTEL_HIDALGO = "hidalgo";

export const COMPANION_NO_COMPANION = "noCompanion";
export const COMPANION_HAS_COMPANION_NO_PAY = "hasCompanionNoPay";
export const COMPANION_HAS_COMPANION_WILL_PAY = "hasCompanionWillPay";

export const ACTIVITY_NONE = "none";
export const ACTIVITY_MEZCALA = "mezcala";
export const ACTIVITY_LOVE_IN_ACTION = "loveInAction";
export const ACTIVITY_THERMAL_BATHS = "thermalBaths";
export const ACTIVITY_WORKSHOP = "workshop";

export const ACTIVITY_GALA_DINNER = "galaDinner";
export const ACTIVITY_PIRAMIDES = "piramides";

export const HOTEL_LOURDES_EXTRA_NIGHT = "hotelLourdesExtraNight";
export const HOTEL_HIDALGO_EXTRA_NIGHT = "hotelHidalgoExtraNight";

export const PAYMENT_TYPE_BANK = "bank";
export const PAYMENT_TYPE_PAYPAL = "paypal";

export const TABLE_TITLE_DATE = "date";
export const TABLE_TITLE_NAME = "name";
export const TABLE_TITLE_PACKAGE = "package";
export const TABLE_TITLE_EXTRANIGHTS = "extraNigths";
export const TABLE_TITLE_PEOPLE = "people";
export const TABLE_TITLE_GALADINNER = "galaDinner";
export const TABLE_TITLE_THURSDAYACTIVITY = "thursdayActivity";
export const TABLE_TITLE_PIRAMIDES = "piramides";
export const TABLE_TITLE_DONATIONS = "donations";
export const TABLE_TITLE_COMMENTS = "comments";
export const TABLE_TITLE_TOTAL = "total";
export const TABLE_TITLE_PAYMENTMETHOD = "paymentMethod";

export const EXCURSION_URL_SPANISH =
  "https://sites.google.com/view/convenciondelasamericas/pagina-principal";

export const EXCURSION_URL_ENGLISH =
  "https://sites.google.com/view/convention-of-the-americas/home";
