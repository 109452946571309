const loadingStyle = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "200px",
    color: "black",
  },
};

export default loadingStyle;
