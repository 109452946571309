import React from "react";

import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { OnlyRegistrationSummary } from "components/Registrer/components/ShortSummaryComponents/OnlyRegistrationSummary";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { PACKAGE_NO_CONVENTION } from "constants/registrationForm";
import { useRegistrationFormUtils } from "hooks/useRegistrationFormUtils";
import { ACTIVITY_NONE } from "constants/registrationForm";
import { MoneyFormatter } from "components/Registrer/components/MoneyFormatter";
import { PAYMENT_TYPE_PAYPAL } from "constants/registrationForm";
import { PAYMENT_TYPE_BANK } from "constants/registrationForm";
import Badge from "components/Badge/Badge";
import { PACKAGE_CONVENTION_WITH_HOTEL } from "constants/registrationForm";
import { COMPANION_HAS_COMPANION_NO_PAY } from "constants/registrationForm";
import { COMPANION_HAS_COMPANION_WILL_PAY } from "constants/registrationForm";
import { PACKAGE_CONVENTION_WITHOUT_HOTEL } from "constants/registrationForm";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export const RegistrationRow = ({ data }) => {
  const row = data;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const {
    getActivityLabel,
    getPaymentLabel,
    getPackageLabelShort,
    getHotelLabelShort,
    getCompanionLabelShort,
  } = useRegistrationFormUtils();

  const { t } = useTranslation(["registrationForm"]);

  const getVoidIcon = () => {
    return "--";
  };
  const getPeopleCount = (count) => {
    if (!count) return getVoidIcon();
    if (count === 1) return "1 " + t("person");
    return count + " " + t("people");
  };

  const getActivityDetail = (activity, count) => {
    if (activity === ACTIVITY_NONE) return getVoidIcon();
    return getActivityLabel(activity) + " - " + getPeopleCount(count);
  };

  const getPayment = (payment) => {
    if (payment === PAYMENT_TYPE_PAYPAL)
      return <Badge color="success">{getPaymentLabel(payment)}</Badge>;
    if (payment === PAYMENT_TYPE_BANK)
      return <Badge color="warning">{getPaymentLabel(payment)}</Badge>;
  };

  const getPackage = (selectedPackage, hotel) => {
    if (selectedPackage === PACKAGE_CONVENTION_WITH_HOTEL)
      return (
        getPackageLabelShort(selectedPackage) +
        " - " +
        getHotelLabelShort(hotel)
      );

    return getPackageLabelShort(selectedPackage);
  };

  const getExtraNights = (selectedPackage, { daysBefore, daysAfter }) => {
    if (selectedPackage === PACKAGE_CONVENTION_WITH_HOTEL)
      return daysBefore + daysAfter;
    return getVoidIcon();
  };
  const getPackagePeople = (
    selectedPackage,
    {
      noConventionPeopleCount,
      justConventionPeopleCount,
      travelingWithCompanion,
    }
  ) => {
    if (selectedPackage === PACKAGE_CONVENTION_WITH_HOTEL) {
      if (travelingWithCompanion === COMPANION_HAS_COMPANION_NO_PAY)
        return (
          <Badge color="info">
            {getCompanionLabelShort(travelingWithCompanion)}
          </Badge>
        );
      if (travelingWithCompanion === COMPANION_HAS_COMPANION_WILL_PAY)
        return (
          <Badge color="success">
            {getCompanionLabelShort(travelingWithCompanion)}
          </Badge>
        );
      return <Badge>{getCompanionLabelShort(travelingWithCompanion)}</Badge>;
    }
    if (selectedPackage === PACKAGE_CONVENTION_WITHOUT_HOTEL)
      return getPeopleCount(justConventionPeopleCount);
    if (selectedPackage === PACKAGE_NO_CONVENTION)
      return getPeopleCount(noConventionPeopleCount);
  };

  const getComments = (comment) => {
    if (!comment) return getVoidIcon();
    if (comment.length > 50) return comment.slice(0, 50) + "...";
    return comment;
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" align="right">
          {moment(row.date).format("DD/MM/YYYY HH:mm")}
        </TableCell>
        <TableCell align="right">{row.registration.name}</TableCell>
        <TableCell align="right">
          {getPackage(row.selectedPackage, row.hotelReservation.hotelName)}
        </TableCell>
        <TableCell align="right">
          {getExtraNights(row.selectedPackage, row.hotelReservation)}
        </TableCell>
        <TableCell align="center">
          {getPackagePeople(row.selectedPackage, row.hotelReservation)}
        </TableCell>
        <TableCell align="right">
          {getPeopleCount(row.extraActivities.galaDinnerPeopleCount)}
        </TableCell>
        <TableCell align="right">
          {getActivityDetail(
            row.extraActivities.excursionDay1.name,
            row.extraActivities.excursionDay1.count
          )}
        </TableCell>
        <TableCell align="right">
          {getPeopleCount(row.extraActivities.piramidePeopleCount)}
        </TableCell>
        <TableCell align="right">
          <MoneyFormatter
            value={parseFloat(row.donations)}
            currency={row.currency}
          />
        </TableCell>
        <TableCell align="right">{getComments(row.comments)}</TableCell>
        <TableCell align="right">
          <MoneyFormatter value={row.total} currency={row.currency} />
        </TableCell>
        <TableCell align="center">{getPayment(row.payment)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <OnlyRegistrationSummary registration={row} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
