import { CircularProgress, makeStyles } from "@material-ui/core";
import React from "react";
// @material-ui/icons
import style from "./LoadingStyle";

const useStyles = makeStyles(style);
const Loading = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <CircularProgress color="inherit" />
    </div>
  );
};

export default Loading;
