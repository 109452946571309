import React from "react";
import style from "./VideoStyle";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(style);

const Video = ({ url, title, height = "450" }) => {
  const classes = useStyles();

  return (
    <div className={classes.iframeContainer}>
      <iframe
        height={height}
        src={url}
        frameBorder="0"
        allow="encrypted-media"
        allowFullScreen
        title={title}
      />
    </div>
  );
};

export default Video;
