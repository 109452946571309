/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  firebase,
  googleAuthProvider,
  db,
} from "../../firebase/firebase-config";
import axios from "axios";

// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import "./ShoppingCartPage.css";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";

import style from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js";
import {
  ItemDelete,
  ItemDetail,
  ItemImage,
  ItemPrice,
} from "../../components/ShoppingCartItem/ShoppingCartItem.js";
import { useDispatch, useSelector } from "react-redux";
import CardFooter from "components/Card/CardFooter.js";
import { Link } from "react-router-dom";
import { PayPalButton } from "react-paypal-button-v2";

import {
  startToLoadCourseList,
  startToLoadMyCourseList,
  cleanCart,
  removeCourseFromCart,
} from "actions/courses";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(style);

export default function CoursesPage() {
  const { t } = useTranslation(["shopping-cart"]);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showPaypalButton, setShowPaypalButton] = useState(true);

  useEffect(() => {
    dispatch(startToLoadMyCourseList());
  }, [dispatch, useSelector]);
  const {
    list: allCourses,
    myList: myCourses,
    cart: coursesInCart,
  } = useSelector((state) => state.courses);
  const myCoursesID = myCourses.map((course) => course.id);

  const filterCourses = () => {
    return allCourses.filter(
      (course) =>
        coursesInCart.includes(course.id) && !myCoursesID.includes(course.id)
    );
  };

  const courses = filterCourses();

  let videosID = [];
  let total = 0;

  const calculateTotal = () => {
    let parcial = 0;
    courses.forEach((course) => {
      const parsed = Number.parseInt(course.price);
      if (!Number.isNaN(parsed)) {
        parcial += parsed;
      }
    });
    return parcial;
  };
  total = calculateTotal();
  const removeItemFromCart = (id) => () => {
    dispatch(removeCourseFromCart(id));
  };

  const OnCreateOrder = async (data, actions) => {
    await firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((token) => {
        axios
          .get(`${process.env.REACT_APP_BACKEND_URI}videos/mylist`, {
            headers: { "x-firebase-token": token },
          })
          .then((res) => {
            const mylist = res.data.result;
            const ownedCoursesID = mylist.map((course) => course.id);
            mylist.forEach((c) => {
              if (coursesInCart.includes(c.id)) {
                dispatch(removeCourseFromCart(c.id));
                coursesInCart.splice(coursesInCart.indexOf(c.id), 1);
              }
            });
            total = 0;
            const courses = allCourses.filter(
              (course) =>
                coursesInCart.includes(course.id) &&
                !ownedCoursesID.includes(course.id)
            );
            courses.forEach((course) => {
              const parsed = Number.parseInt(course.price);
              videosID.push(course.id);

              if (!Number.isNaN(parsed)) {
                total += parsed;
              }
            });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });

    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code: "USD",
            value: total,
          },
        },
      ],
    });
  };

  const OnError = async () => {
    await firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((token) => {
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_URI}videos/cancelbuy`,
            { videos: videosID },
            {
              headers: { "x-firebase-token": token },
            }
          )
          .then((res) => {})
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const OnApprove = async () => {
    //setShowPaypalButton(false);
    await firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((token) => {
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_URI}videos/buy`,
            { videos: videosID },
            {
              headers: { "x-firebase-token": token },
            }
          )
          .then((res) => {})
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        //setShowPaypalButton(true);
        console.log(err);
      });
  };

  const OnSuccess = () => {
    dispatch(cleanCart());
    dispatch(startToLoadCourseList());
    dispatch(startToLoadMyCourseList());
  };

  return (
    <div>
      <Header color="primary" />
      <div className={classes.main + " " + classes.fullHeight}>
        <div className={classes.blog}>
          <div className={classes.container}>
            <Card plain>
              <GridContainer>
                <GridItem
                  xs={12}
                  sm={8}
                  md={8}
                  className={
                    classes.mlAuto +
                    " " +
                    classes.mrAuto +
                    " " +
                    classes.textCenter
                  }
                >
                  <h2 className={classes.cardTitle}>{t("title")}</h2>
                  {courses.length === 0 && (
                    <Link to="/courses">
                      <h5 className={classes.descriptionShopping}>
                        {t("empty cart")}
                      </h5>
                    </Link>
                  )}
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={8} md={8}>
                  <CardBody plain>
                    {courses.length > 0 && (
                      <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>{t("courses")}</TableCell>
                              <TableCell></TableCell>
                              <TableCell align="right">{t("price")}</TableCell>
                              <TableCell align="right">{t("remove")}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {courses.map((row) => (
                              <TableRow key={row.title}>
                                <TableCell component="th" scope="row">
                                  <ItemImage {...row} />
                                </TableCell>
                                <TableCell>
                                  <ItemDetail {...row} />
                                </TableCell>
                                <TableCell align="right">
                                  <ItemPrice {...row} />
                                </TableCell>

                                <TableCell align="right">
                                  <ItemDelete
                                    onClickHandle={removeItemFromCart(row.id)}
                                    {...row}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </CardBody>
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  {courses.length > 0 && (
                    <CardFooter plain>
                      <GridItem
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-end"
                      >
                        <GridItem container justify="center">
                          <h4 className={classes.cardTitle}>
                            {t("total")}: ${total}
                          </h4>
                        </GridItem>
                        <GridItem container direction="column" justify="center">
                          {showPaypalButton && (
                            <PayPalButton
                              amount="20"
                              disableFunding="card"
                              disableCard="true"
                              components=""
                              createOrder={OnCreateOrder}
                              onError={OnError}
                              onApprove={(data, actions) => {
                                // Capture the funds from the transaction
                                OnApprove();

                                return actions.order
                                  .capture()
                                  .then(function (details) {
                                    // Show a success message to your buyer
                                    OnSuccess();
                                  })
                                  .catch((err) => {
                                    OnError();
                                    window.alert(
                                      "An error occurred while processing your payment. Please contact us"
                                    );
                                    console.error("Paypal Error", err);
                                  });
                              }}
                              // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                              // onSuccess={(details, data) => {
                              // OnSuccess();
                              //}} */
                              options={{
                                clientId:
                                  process.env.REACT_APP_PAYPAL_CLIENT_ID,
                              }}
                            />
                          )}
                        </GridItem>
                      </GridItem>
                    </CardFooter>
                  )}
                </GridItem>
              </GridContainer>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
