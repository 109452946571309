import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import collage from "assets/img/convention/collageInfo.png";

import style from "./InfoSectionStyle";

import { makeStyles } from "@material-ui/core/styles";
import ContainerFullHeight from "components/ContainerFullHeight/ContainerFullHeight";
import { SecondaryFontColor } from "assets/jss/material-kit-pro-react.js";
const useStyles = makeStyles(style);

export default function InfoSection({ id, t }) {
  const classes = useStyles();
  return (
    <ContainerFullHeight alignItems="start">
      <div className={classes.container} id={id}>
        <GridContainer className={classes.titleContainer}>
          <GridItem xs={12}>
            <h1 className={classes.title}>{t("infoTitle")}</h1>
            <h3 className={classes.subtitle}>
              {t("infoSubtitlePart1")}
              <span style={{ color: SecondaryFontColor }}>
                {t("infoSubtitlePart2")}
              </span>
              {t("infoSubtitlePart3")}
            </h3>
            <br />
            <div className={classes.text}>
              {t("infoText")
                .split("\n")
                .map((c, index) => {
                  return (
                    <p className={classes.text} key={index}>
                      {" "}
                      {c}{" "}
                    </p>
                  );
                })}
            </div>
            <img src={collage} style={{padding: "50px 0"}} width={"100%"} alt="..." />
          </GridItem>
        </GridContainer>
      </div>
    </ContainerFullHeight>
  );
}
