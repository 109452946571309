import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import HeaderLinks from "./HeaderLinks";
import CustomHeader from "../CustomHeader/Header.js";
import { useDispatch, useSelector } from "react-redux";
import { startLogout } from "actions/auth";
// the hook

import { useTranslation } from "react-i18next";

export default function Header(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation(["header"]);
  const user = useSelector((state) => state.auth);
  let isLogged = false;
  if (user?.uid) {
    isLogged = true;
  }
  const logOutHandle = () => {
    dispatch(startLogout());
  };

  const { color } = props;
  return (
    <CustomHeader
      brand={t("Convention of the Americas")}
      links={
        <HeaderLinks
          dropdownHoverColor="info"
          isLogged={isLogged}
          logOutHandle={logOutHandle}
        />
      }
      fixed
      color={color || "transparent"}
      changeColorOnScroll={{
        height: 80,
        color: "primary",
      }}
    />
  );
}

Header.defaultProp = {
  color: "white",
};

Header.propTypes = {
  links: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark",
    ]).isRequired,
  }),
};
