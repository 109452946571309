import { primaryColor, whiteColor } from "assets/jss/material-kit-pro-react.js";

const sectionInterestedStyle = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    height: "100vh",
    color: whiteColor,
    background:
      "linear-gradient(60deg, " +
      primaryColor[4] +
      ", " +
      primaryColor[1] +
      ")",
  },
};

export default sectionInterestedStyle;
