/* eslint-disable */
import React from "react";
import { useTranslation } from "react-i18next";
import Button from "components/CustomButtons/Button.js";

// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "./footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const { t } = useTranslation(["footer"]);

  const { children, content, theme, big, className } = props;
  const classes = useStyles();
  const themeType =
    theme === "transparent" || theme == undefined ? false : true;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes[theme]]: themeType,
    [classes.big]: big || children !== undefined,
    [className]: className !== undefined,
  });
  const aClasses = classNames({
    [classes.a]: true,
  });

  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.disFlex}>
          <div className={classes.left}>
            <a
              href="https://www.facebook.com/conventionoftheamericas"
              target="_blank"
              className={classes.block}
              style={{color: "white", textDecoration: "none"}}
            >
              {t("contact us facebook")}
            </a>
            <Button
              href="https://www.facebook.com/conventionoftheamericas"
              target="_blank"
              color="primary"
              justIcon
              simple
            >
              <i className="fab fa-facebook" style={{color: "#00B2CA"}}/>
            </Button>
          </div>

          <div className={classes.rightLinks}>
            <a
              href="https://www.facebook.com/conventionoftheamericas"
              target="_blank"
              className={classes.footerBrand}
            >
              {t("Convention of the Americas")}
            </a>
          </div>
        </div>

        <div className={classes.clearFix} />
      </div>
    </footer>
  );
}

Footer.propTypes = {
  theme: PropTypes.oneOf(["dark", "white", "transparent"]),
  big: PropTypes.bool,
};
