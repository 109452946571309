import { PrimaryFontColorLanding, subtitleLandingMedium } from "assets/jss/material-kit-pro-react";
import { whiteColor } from "assets/jss/material-kit-pro-react";

const donatePaypal = (theme) => ({
  text: {
    ...subtitleLandingMedium,
    textAlign: "center",
    marginBottom: "30px",
    color: PrimaryFontColorLanding,
  },
  donateContainer: {
    paddingTop: "80px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  paypalButton: {
    ...subtitleLandingMedium,
    position: "relative",
    borderRadius: "1.5rem",
    cursor: "pointer",
    display: "inline-block",
    minWidth: "6rem",
    textAlign: "center",
    textDecoration: "none",
    transition:
      "color .2s ease,background-color .2s ease,border-color .2s ease,box-shadow .2s ease",
    border: "0.125rem solid #142c8e",
    fontSize: "1.125rem",
    lineHeight: "1.5rem",
    fontWeight: "400",
    padding: "0.625rem 1.875rem",
    background: "#ffc43a",
    borderColor: "#ffc43a",
    color: "#121661",
    "&:hover": {
      background: "#fed13c",
      borderColor: "#fed13c",
    },
  },
});

export default donatePaypal;
