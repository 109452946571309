import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js";
import { useTranslation } from "react-i18next";
import { SectionController } from "./SectionController";
const useStyles = makeStyles(blogsStyle);
export const RegisterContainer = () => {
  const classes = useStyles();
  const { t } = useTranslation(["registrationForm"]);

  return (
    <div className="cd-section">
      <div className={classes.blog}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={10}
              md={10}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <br />
              <br />
              <h2 className={classes.title}>{t("registrationFormTitle")}</h2>
              <div> {t("registrationFormSubtitle")}</div>
              <SectionController />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
};
