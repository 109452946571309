import React, { useState } from "react";

export const usePaypal = () => {
  const [loading, setLoading] = useState(false);
  const options = {
    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID, //Si se cambia, cambiar la key en el script de index
  };
  const onApprove = (data, actions, onSuccess) => {
    return actions.order
      .capture()
      .then(function (details) {
        onSuccess(details);
      })
      .catch((err) => {
        // OnError();
        window.alert(
          "An error occurred while processing your payment. Please contact us"
        );
        console.error("Paypal Error", err);
      });
  };

  const createOrder = async (data, actions, currency, total) => {
    const totalNumber = Math.ceil(total * 100) / 100;
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code: currency, //Tambien cambiar la currency en el <script> del index.html
            value: totalNumber,
          },
        },
      ],
    });
  };

  const onError = (data, data2) => {
    console.error("Paypal error");
  };

  return { loading, options, onApprove, createOrder, onError };
};
