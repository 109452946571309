import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { makeStyles } from "@material-ui/core/styles";
import style from "./styles/registrationInfo";
import { useTranslation } from "react-i18next";

import USALogo from "assets/img/convention/icons/usa.svg";
import MxLogo from "assets/img/convention/icons/mx.svg";

import Button from "components/CustomButtons/Button.js";
import {
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useChangeLanguage } from "hooks/useChangeLanguage";
const useStyles = makeStyles(style);

export const RegistrationInfo = ({ onRegisterStart }) => {
  const { t } = useTranslation(["registrationForm"]);
  const classes = useStyles();
  const {
    languageLogo,
    selectedLanguage = "es",
    changeLanguage,
  } = useChangeLanguage();

  const [language, setLanguage] = React.useState(selectedLanguage);

  const onLanguageChange = (event) => {
    setLanguage(event.target.value);
    changeLanguage(event.target.value);
  };

  return (
    <>
      <GridContainer>
        <GridItem className={classes.titleSection}>
          <h2 className={classes.title}> {t("step0_title")} </h2>
          <h4 className={classes.subtitle}>{t("step0_subtitle")}</h4>
        </GridItem>
        <GridItem>
          <p className={classes.text}>{t("step0_text1")}</p>
          <ul>
            <li className={classes.text}>{t("step0_option_1")}</li>

            <li className={classes.text}>
              {t("step0_option_2_part_1")}
              <a href="https://www.xe.com/" target="_blank">
                https://www.xe.com/
              </a>
              {t("step0_option_2_part_2")}
            </li>
            <li className={classes.text}>{t("step0_option_3")}</li>
            <ul>
              <li className={classes.text}>{t("step0_option_3_sub_1")}</li>
              <li className={classes.text}>{t("step0_option_3_sub_2")}</li>
            </ul>
          </ul>
          <p className={classes.text}>{t("step0_text2")}</p>
          <p className={classes.text}>{t("step0_text3")}</p>
          <br />
          <h4 className={classes.subtitle}> {t("step0_goodbye")}</h4>
        </GridItem>
      </GridContainer>
      <br />
      <br />
      <GridContainer
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={5}
        mt={5}
      >
        <GridItem xs={12} sm={6}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              {t("step0_selectLabel")}
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={language}
              onChange={onLanguageChange}
              inputProps={{
                name: "simpleSelect",
                id: "simple-select",
              }}
            >
              {[
                { id: "es", label: "Español", logo: MxLogo },
                { id: "en", label: "English", logo: USALogo },
              ].map(({ id, label, logo }) => (
                <MenuItem
                  key={id}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                  value={id}
                >
                  <ListItemIcon>
                    <img src={logo} className={classes.languageIcon} />
                  </ListItemIcon>
                  <ListItemText primary={label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>

        <GridItem xs={12} className={classes.justifyCenter}>
          <Button variant="contained" color="success" onClick={onRegisterStart}>
            {t("step0_button")}
          </Button>
        </GridItem>
      </GridContainer>
    </>
  );
};
