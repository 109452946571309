import React from "react";
import { useTranslation } from "react-i18next";

import VerticalCourse from "../VerticalCourse/VerticalCourse";
import HorizontalCourse from "../HorizontalCourse/HorizontalCourse";

const Course = (props) => {
  const { t } = useTranslation(["common"]);

  const { verticalCourse, ...courseProps } = props;
  return verticalCourse ? (
    <VerticalCourse courseProps={courseProps} t={t} />
  ) : (
    <HorizontalCourse courseProps={courseProps} t={t} />
  );
};

export default Course;
