import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import mainImage from "assets/img/convention/oradores/Rebeka foto.jpg";
import audioRebeka from "assets/sounds/Audio_Rebeka.mp3";
import AudioPlayer from "material-ui-audio-player";
import style from "./HistorySectionStyle";

import { makeStyles } from "@material-ui/core/styles";
import ContainerFullHeight from "components/ContainerFullHeight/ContainerFullHeight";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card";

const useStyles = makeStyles(style);

export default function InfoSection({ t }) {
  const classes = useStyles();
  const years = ["2007", "2010 - 2012", "2013", "2014", "2015", "2019", "2020"];

  return (
    <ContainerFullHeight alignItems="start" bgColor="beige" paddingTop="150px">
      <div className={classes.container}>
        <GridContainer className={classes.titleContainer}>
          <GridItem xs={12}>
            <h2 className={classes.mainTitle}>{t("mainTitle")}</h2>
            <GridContainer className={classes.titleContainer}>
              <GridItem xs={12} sm={6} md={6}>
                <h2 className={classes.title}>{t("title")}</h2>
                <div className={classes.descriptionWrapper}>
                  <h4 className={classes.title}>{t("subtitle1")}</h4>
                  <div className={classes.description}>{t("description1")}</div>
                </div>
                <div className={classes.descriptionWrapper}>
                  <h4 className={classes.title}>{t("subtitle2")}</h4>
                  <div className={classes.description}>{t("description2")}</div>
                </div>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <div className={classes.imgContainer}>
                  <img
                    className={classes.imgContainer}
                    src={mainImage}
                    alt={t("picDescription")}
                  />
                </div>
              </GridItem>
            </GridContainer>

            <Card plain blog className={classes.card4}>
              <CardBody plain>
                <h3 className={classes.cardTitle}>{t("audio-description")}</h3>
                <GridContainer>
                  <GridItem xs={12}>
                    <div className={classes.audioContainer}>
                      <AudioPlayer
                        src={audioRebeka}
                        elevation={1}
                        variation="primary"
                      />
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            {years.map((year, yearIndex) => (
              <Card plain blog className={classes.card4} key={yearIndex}>
                <CardBody plain>
                  <h3 className={classes.cardTitle}>{year}</h3>
                  <h5 className={classes.description}>
                    {t(year)
                      .split("\n")
                      .map((c, index) => {
                        return (
                          <p className={classes.text} key={index}>
                            {" "}
                            {c}{" "}
                          </p>
                        );
                      })}
                  </h5>
                </CardBody>
              </Card>
            ))}
          </GridItem>
        </GridContainer>
      </div>
    </ContainerFullHeight>
  );
}
