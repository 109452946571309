import React from "react";

import { useRegistrationFormUtils } from "hooks/useRegistrationFormUtils";
import Success from "components/Typography/Success";

export const MoneyFormatter = ({ value = 0.0, currency = "" }) => {
  const { moneyFormatter } = useRegistrationFormUtils();
  if (typeof value === "string") return <Success>{value}</Success>;
  const total = parseFloat(value);

  return <Success>{moneyFormatter(currency)(total)}</Success>;
};
