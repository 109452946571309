import { PrimaryFontColorLanding } from "assets/jss/material-kit-pro-react";
import {
  container,
  titleLanding,
  subtitleLandingMedium,
  subtitleLandingLight,
} from "assets/jss/material-kit-pro-react.js";

const style = (theme) => ({
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    "& h1, & h4, & h6": {
      color: PrimaryFontColorLanding,
    },
    "& h2": {
      marginTop: "none",
    },
  },

  title: {
    ...titleLanding,
    marginTop: "none",
    marginBottom: "15px",
    color: PrimaryFontColorLanding,
  },

  titleContainer: {
    backgroundPosition: "left",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    justifyContent: "flex-start", // Cambiado a flex-start para alinear a la izquierda
  },

  img: {
    width: "100%",
    height: "auto",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    marginTop: "50px"
  },

  subtitle: {
    ...subtitleLandingMedium,
    color: PrimaryFontColorLanding,
  },

  description: {
    ...subtitleLandingLight,
    color: PrimaryFontColorLanding,
  },

  text: {
    ...subtitleLandingLight,
    color: PrimaryFontColorLanding,
    fontSize: "20px",
  },

  imgContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start", // Alineación a la izquierda para el texto
    paddingLeft: "20px",
  },

  beigeContainer: {
    width: "100%",
    backgroundColor: "#DAD1C0",
    borderRadius: "40px",
    padding: "50px",
    marginBottom: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default style;
