import Loading from "components/Loading/Loading";
import { RegistrationTable } from "components/Registrations/RegistrationTable.jsx";
import { useRegistrationForm } from "hooks/useRegistrationForm";
import React, { useEffect } from "react";

export const RegistrationController = () => {
  const {
    error,
    loading,
    registrations,
    getRegistrationsData,
  } = useRegistrationForm();

  useEffect(() => {
    getRegistrationsData();
  }, []);

  if (loading) return <Loading />;
  if (error) return <div>Error</div>;
  return <RegistrationTable registrations={registrations} />;
};
