import React from "react";
import {
  PACKAGE_CONVENTION_WITH_HOTEL,
  PACKAGE_CONVENTION_WITHOUT_HOTEL,
  PACKAGE_NO_CONVENTION,
  TITLE_NONE,
  TITLE_MINISTER,
  TITLE_PRACTITIONER,
  HOTEL_LOURDES,
  HOTEL_HIDALGO,
  COMPANION_NO_COMPANION,
  COMPANION_HAS_COMPANION_NO_PAY,
  COMPANION_HAS_COMPANION_WILL_PAY,
  ACTIVITY_NONE,
  ACTIVITY_MEZCALA,
  ACTIVITY_LOVE_IN_ACTION,
  ACTIVITY_THERMAL_BATHS,
  STEP_PERSONAL_INFO,
  STEP_ACCOMMODATION_INFO,
  STEP_ACTIVITY_INFO,
  STEP_ADDITIONAL_INFO,
  STEP_PAYMENT,
  STEP_SUMMARY,
} from "constants/registrationForm";
import { useTranslation } from "react-i18next";
import { CONVENTION_CURRENCY } from "constants/registrationFormCosts";

const stepsWithTotals = [
  STEP_ACCOMMODATION_INFO,
  STEP_ACTIVITY_INFO,
  STEP_ADDITIONAL_INFO,
];

export const useRegistrationFormUtils = () => {
  const { t } = useTranslation(["registrationForm"]);

  const getStepNameList = () => {
    const stepsId = [
      STEP_PERSONAL_INFO,
      STEP_ACCOMMODATION_INFO,
      STEP_ACTIVITY_INFO,
      STEP_ADDITIONAL_INFO,
      STEP_PAYMENT,
      STEP_SUMMARY,
    ];
    return stepsId.map((id) => ({ id, label: getStepName(id) || id }));
  };

  const getStepName = (id) => {
    return t("stepName_" + id);
  };

  const getHonorTitleList = () => {
    const titles = [TITLE_NONE, TITLE_MINISTER, TITLE_PRACTITIONER];
    return titles.map((id) => ({ id, label: getHonorTitleLabel(id) || id }));
  };

  const getHonorTitleLabel = (id) => {
    return t("honorTitle_" + id);
  };

  const getPackagesList = () => {
    const ids = [
      PACKAGE_CONVENTION_WITH_HOTEL,
      PACKAGE_CONVENTION_WITHOUT_HOTEL,
      PACKAGE_NO_CONVENTION,
    ];
    return ids.map((id) => ({ id, label: getPackageLabel(id) || id }));
  };

  const getPackageLabel = (id) => {
    return t("packageLabel_" + id);
  };

  const getPackageLabelShort = (id) => {
    return t("packageLabelShort_" + id);
  };

  const getHotelList = () => {
    const hotelOptions = [HOTEL_LOURDES, HOTEL_HIDALGO];
    return hotelOptions.map((id) => ({ id, label: getHotelLabel(id) || id }));
  };

  const getHotelLabel = (id) => {
    return t("hotelLabel_" + id);
  };

  const getHotelLabelShort = (id) => {
    return t("hotelLabelShort_" + id);
  };

  const getCompanionList = () => {
    const ids = [
      COMPANION_NO_COMPANION,
      COMPANION_HAS_COMPANION_NO_PAY,
      COMPANION_HAS_COMPANION_WILL_PAY,
    ];
    return ids.map((id) => ({ id, label: getCompanionLabel(id) || id }));
  };

  const getCompanionLabel = (id) => {
    return t("companionLabel_" + id);
  };
  const getCompanionLabelShort = (id) => {
    return t("companionLabelShort_" + id);
  };

  const getIncludedList = () => {
    const text = t("includedList") || "";
    return text.split("/n");
  };

  const getActivitiesDay1List = () => {
    const activities = [
      ACTIVITY_NONE,
      ACTIVITY_MEZCALA,
      ACTIVITY_LOVE_IN_ACTION,
      ACTIVITY_THERMAL_BATHS,
    ];
    return activities.map((id) => ({ id, label: getActivityLabel(id) || id }));
  };

  const getActivityLabel = (id) => {
    return t("activitityLabel_" + id);
  };

  const getActivityDescription = (id) => {
    return t("activitityDescription_" + id);
  };

  const getTableTitle = (id) => {
    return t("tableTitle_" + id);
  };

  const getPaymentLabel = (payment) => {
    return t(payment);
  };
  const getPersonLabel = (id, allowJustMe = false) => {
    if (id !== 1) return id + " " + t("people");
    if (allowJustMe) return t("justMe");
    return id + " " + t("person");
  };

  const getOtherStepsTotals = (partialCosts = {}, stepId) => {
    const filteredIds = stepsWithTotals.filter((el) => el !== stepId);
    return filteredIds.reduce(
      (partialTotal, id) => (partialTotal += partialCosts[id] || 0),
      0
    );
  };
  const moneyFormatter = (currency) => {
    const currencySelected = currency || CONVENTION_CURRENCY;
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currencySelected,
      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    }).format;
  };
  const packagesList = getPackagesList();
  const hotelList = getHotelList();
  const companionList = getCompanionList();
  const includedList = getIncludedList();
  const activitiesDay1 = getActivitiesDay1List();
  const stepNameList = getStepNameList();

  return {
    companionList,
    includedList,
    hotelList,
    packagesList,
    getCompanionLabel,
    getCompanionLabelShort,
    getHonorTitleLabel,
    getHonorTitleList,
    getHotelLabel,
    getHotelLabelShort,
    getPackageLabel,
    activitiesDay1,
    getActivityLabel,
    getActivityDescription,
    stepNameList,
    getStepName,
    getPersonLabel,
    getOtherStepsTotals,
    moneyFormatter,
    getPaymentLabel,
    getPackageLabelShort,
    getTableTitle,
  };
};
