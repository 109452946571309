import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";

import { StepperButtons } from "../StepperButtons";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { CustomRadioInput } from "components/CustomForm";
import { NumberOfPeopleSelect } from "../components/NumberOfPeopleSelect";
import { CustomFormInput } from "components/CustomForm";
import { useTranslation } from "react-i18next";
import { InputAdornment } from "@material-ui/core";
import { STEP_ADDITIONAL_INFO } from "constants/registrationForm";
import { TotalDisplay } from "../components/TotalDisplay";

const useStyles = makeStyles(basicsStyle);
export const Step4 = ({
  onHandleSuccess,
  defaultFormValue = {},
  values,
  handlePrevious,
  total,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(["registrationForm"]);
  const [partialTotal, setPartialTotal] = useState(0);
  const formValue = values || defaultFormValue;
  const { register, handleSubmit, errors, control, watch, trigger } = useForm({
    defaultValues: formValue,
  });

  const validatePositiveNumber = (value) => {
    const num = parseFloat(value);
    if (isNaN(num) || num < 0 || num > 25000) {
      return t("errorValidNumber");
    }
    return true;
  };

  const selectedDonationAmount = watch("donationAmount") || 0;
  useEffect(() => {
    setPartialTotal(parseFloat(selectedDonationAmount) || 0);
  }, [selectedDonationAmount]);

  const handleChange = async () => {
    const isValid = await trigger("donationAmount");
    if (isValid) {
      setPartialTotal(parseFloat(selectedDonationAmount) || 0);
    } else {
      setPartialTotal(0);
    }
  };

  const onSubmit = (data) => {
    onHandleSuccess(STEP_ADDITIONAL_INFO, {
      ...data,
      donationAmount: parseFloat(selectedDonationAmount) || 0,
      partialTotal: parseFloat(partialTotal),
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GridContainer>
        <GridItem xs={12}>
          <TotalDisplay
            partialTotal={partialTotal}
            stepId={STEP_ADDITIONAL_INFO}
            total={total}
          />
          <h3>{t("aditionalInfoStepTitle")}</h3>
        </GridItem>
        <GridItem xs={12}>
          <h4>{t("aditionalInfoTitle")}</h4>
          <CustomFormInput
            labelText={t("aditionalInfoTitle")}
            name={"donationAmount"}
            inputProps={{
              type: "number",
              min: 0,
              inputMode: "numeric",
              onChange: handleChange,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            ref={register({
              validate: validatePositiveNumber,
            })}
            id="donationAmount"
            formControlProps={{ fullWidth: true }}
            error={errors}
          />
        </GridItem>
        <GridItem xs={12}>
          <h4>{t("commentTitle")}</h4>
          <CustomFormInput
            labelText={t("commentDetail")}
            name={"comments"}
            inputProps={{ multiline: true, rows: 5 }}
            ref={register}
            id="comments"
            formControlProps={{ fullWidth: true }}
          />
          <CustomRadioInput
            name="hasGivenPermissions"
            label={t("permissionTitle")}
            control={control}
            defaultValue="yes"
            options={[
              { id: "yes", label: t("yes") },
              { id: "no", label: t("no") },
            ]}
          />
        </GridItem>

        <GridContainer xs="12" justify="flex-end">
          <StepperButtons
            hasNextButton
            hasBackButton
            handleBack={handlePrevious}
          />
        </GridContainer>
      </GridContainer>
    </form>
  );
};
