import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js";
import Course from "../../../components/Course/Course.js";
import { useDispatch, useSelector } from "react-redux";
import { startToLoadCourseList } from "actions/courses.js";
import { addCourseToCart } from "actions/courses.js";
import Loading from "components/Loading/Loading.js";

const useStyles = makeStyles(blogsStyle);

export default function CourseSection({ isLogged, ...rest }) {
  const { t } = useTranslation(["videos"]);

  const classes = useStyles();
  const dispatch = useDispatch();

  const courses = useSelector((state) => state.courses.list);
  const myCourses = useSelector((state) => state.courses.myList);
  const loading = useSelector((state) => state.status.loadingList);
  const cartList = useSelector((state) => state.courses.cart);

  useEffect(() => {
    dispatch(startToLoadCourseList());
  }, [dispatch]);

  const addToCartHandle = (id) => () => {
    dispatch(addCourseToCart(id));
  };

  return (
    <div className="cd-section" {...rest}>
      <div className={classes.blog}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={10}
              md={10}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <br />
              <br />
              <h2 className={classes.title}>{t("title")}</h2>
              {loading && <Loading />}
              {!loading && courses.length === 0 && <p>{t("no videos")}</p>}
              {!loading &&
                courses.map((course) => {
                  const match = myCourses.filter(
                    (myCourse) => myCourse.id === course.id
                  );
                  return match.length > 0 ? (
                    ""
                  ) : (
                    <Course
                      isLogged={isLogged}
                      {...course}
                      key={course.id}
                      addedToCart={cartList.includes(course.id)}
                      handleClick={addToCartHandle(course.id)}
                    />
                  );
                })}
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
