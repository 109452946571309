import React from "react";
import { makeStyles } from "@material-ui/core";
import style from "./ParagraphStyle";

const useStyles = makeStyles(style);

export const Paragraph = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.text}>
      {text.split("\n").map((line, index) => {
        return (
          <p className={classes.text} key={index}>
            {line}
          </p>
        );
      })}
    </div>
  );
};
