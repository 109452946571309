import React from "react";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/material.css";
import "./customStyles.css";

import FormControl from "@material-ui/core/FormControl";
import { Controller } from "react-hook-form";

export const CustomPhoneInput = ({
  name,
  label,
  control,
  defaultValue,
  children,
  ...props
}) => {
  return (
    <FormControl {...props} className={"custom-phone-input-controller"}>
      <Controller
        as={
          <PhoneInput
            country="mx"
            preferredCountries={["mx", "us"]}
            specialLabel={label}
          />
        }
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
    </FormControl>
  );
};
