import { Box, Icon } from "@material-ui/core";
import React from "react";

export const CompanionShortData = ({
  name,
  email,
  phone,
  city,
  relationship,
}) => {
  const displayFlex = { display: "flex", alignItems: "center" };
  const iconBoxStyle = {
    ...displayFlex,
    marginRight: "15px",
    marginBottom: "5px",
  };
  const iconStyle = { marginRight: "5px" };
  const boxContainerStyle = { ...displayFlex, marginTop: "5px" };
  return (
    <Box style={boxContainerStyle}>
      <Box style={iconBoxStyle}>
        <Icon style={iconStyle}>person</Icon>
        {name}
      </Box>
      <Box style={iconBoxStyle}>
        <Icon style={iconStyle}>email</Icon>
        {email}
      </Box>
      <Box style={iconBoxStyle}>
        <Icon style={iconStyle}>phone_iphone</Icon>
        {phone}
      </Box>
      {city && (
        <Box style={iconBoxStyle}>
          <Icon style={iconStyle}>location_city</Icon>
          {city}
        </Box>
      )}
      {relationship && (
        <Box style={iconBoxStyle}>
          <Icon style={iconStyle}>group</Icon>
          {relationship}
        </Box>
      )}
    </Box>
  );
};
