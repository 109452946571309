import React from "react";
import Button from "components/CustomButtons/Button.js";

import { makeStyles } from "@material-ui/core/styles";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles(basicsStyle);

export const StepperButtons = ({
  hasBackButton,
  hasNextButton,
  handleBack,
  handleNext,
  isLastButton,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(["registrationForm"]);

  return (
    <div>
      {hasBackButton && (
        <Button onClick={handleBack} className={classes.button}>
          {t("buttonBack")}
        </Button>
      )}

      {hasNextButton && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          className={classes.button}
          type="submit"
        >
          {t("buttonNext")}
        </Button>
      )}
    </div>
  );
};
