import { main } from "assets/jss/material-kit-pro-react.js";

const coursePageStyle = {
  main: {
    ...main,
  },
  fullHeight: {
    minHeight: "100vh",
  },
};

export default coursePageStyle;
