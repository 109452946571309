import {
  main,
  fullHeightWithFooter,
} from "assets/jss/material-kit-pro-react.js";

const sectionsPageStyle = {
  main: {
    ...main,
  },
  fullHeightWithFooter  ,
};

export default sectionsPageStyle;
