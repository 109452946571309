import React from "react";
import { makeStyles } from "@material-ui/core";
import style from "./SubtitleStyle";

const useStyles = makeStyles(style);

const Subtitle = ({ subtitle, align = "start" }) => {
  const classes = useStyles();
  return (
    <h4
      className={classes.subtitle}
      style={{
        textAlign: align,
      }}
    >
      {subtitle}
    </h4>
  );
};

export default Subtitle;
