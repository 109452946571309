import React from "react";
import { useTranslation } from "react-i18next";
import { MoneyFormatter } from "./MoneyFormatter";
import { Box, makeStyles } from "@material-ui/core";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle";

const useStyles = makeStyles(basicsStyle);
export const SummaryData = ({ label, value, arePeople = false, cost, currency}) => {
  const classes = useStyles();
  const { t } = useTranslation(["registrationForm"]);
  let newValue = value;

  if (arePeople) {
    newValue = value + " " + (value === 1 ? t("person") : t("people"));
  }

  return (
    <Box className={classes.dFlexSpaceBetween}>
      <div>
        <strong>{label}:</strong> <span>{newValue}</span>
      </div>
      {cost !== undefined && <MoneyFormatter value={cost} currency={currency} />}
    </Box>
  );
};
