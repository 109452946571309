import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import blogsStyle from "./NoCourseErrorStyle";
//import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js";

const useStyles = makeStyles(blogsStyle);

export default function NoCourseError({ course, ...rest }) {
  const { t } = useTranslation(["errors"]);

  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.blog}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <br />
              <h2 className={classes.title}>{t("video-problem-title")}</h2>
              <hr></hr>
              <Card plain blog className={classes.card4}>
                <CardBody plain>
                  <h5 className={classes.description}>
                    {t("video-problem-description")}
                  </h5>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
