import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ligthray from "assets/img/convention/ligthray.png";
import Button from "components/CustomButtons/Button.js";
import style from "./MainSectionStyle";
import { makeStyles } from "@material-ui/core/styles";
import ContainerFullHeight from "components/ContainerFullHeight/ContainerFullHeight";
import { useTranslation } from "react-i18next";
import { ParagraphMain } from "components/ParagraphMain/ParagraphMain";
import Portada2024 from "assets/img/24/Imagen principal.png";
import { Link } from "react-router-dom/cjs/react-router-dom";
import DonatePaypal from "components/DonatePaypal/DonatePaypal";

const useStyles = makeStyles(style);

export default function MainSection({ id, t }) {
  const { i18n } = useTranslation(["homePage"]);
  const classes = useStyles();

  const cotaLink = "https://www.facebook.com/conventionoftheamericas";

  const videoPanel = function () {
    return (
      <GridItem xs={12} sm={12} md={6} className={classes.mlAuto}>
        {/*<Video
          url={i18n.language === "es" ? spanishInterview : englishIterview}
          title={titleComplete}
        />*/}
        <a href={cotaLink} target="blank">
          <img
            src={Portada2024}
            width={"100%"}
            alt="..."
            className={classes.imgPortada}
          />
        </a>
        <GridContainer className={classes.scheduleContainer}>
          <GridItem
            xs={12}
            md={6}
            className={classes.navButtonContainer}
          ></GridItem>
          {/*<GridItem xs={12} md={6} className={classes.navButtonContainer}>
            <Button
              color="info"
              size="lg"
              href={graduationLink}
              target="blank"
              className={classes.navButton}
            >
              {t("graduationButton")}
            </Button>
            </GridItem>*/}
        </GridContainer>
      </GridItem>
    );
  };
  return (
    <ContainerFullHeight
      alignItems="start"
      paddingTop="100px"
      bgColor="white"
      paddingBottom="100px"
    >
      <div
        style={{
          position: "absolute",
          width: "35vw",
          height: "35vw",
          backgroundColor: "#CABDA3",
          top: "-20px",
          zIndex: "1",
          borderRadius: "50%",
          right: "-100px",
          minWidth: "300px",
          minHeight: "300px",
        }}
      ></div>
      <div className={classes.container} id={id}>
        <GridContainer className={classes.titleContainer}>
          <GridItem xs={12} sm={12} md={6}>
            <h2 className={classes.title}>{t("mainSubtitle")}</h2>
            <h3 className={classes.subtitle}>{t("meetingTitle")}</h3>
            <h3 className={classes.subtitleI}>{t("meetingSubtitle")}</h3>
            <br />
            <ParagraphMain
              className={classes.paragraph}
              text={t("meetingText")}
            />
            <Link to={"/register"}>
              <Button
                color="info"
                size="lg"
                target="blank"
                className={classes.navButton}
              >
                {t("signUpButton")}
              </Button>
            </Link>
          </GridItem>
          {videoPanel()}
          <GridItem xs={12} sm={12}>
            <DonatePaypal
              text={t("donateText")}
              buttonText={t("donatePaypal")}
            />
          </GridItem>
          {/*<GridItem xs={12} md={12}>
            <Pricing buttonText={t("pricingButton")} />
  </GridItem>*/}
          {/*<GridItem
            xs={12}
            md={6}
            className={classes.navButtonContainerSchedule}
          >
            <Button
              color="info"
              size="lg"
              href={schedulePDF}
              target="blank"
              download="Programa-Schedule"
            >
              {t("scheduleButton")}
            </Button>
  </GridItem>*/}
        </GridContainer>
      </div>
      <div
        style={{ backgroundImage: `url("${ligthray}")` }}
        className={classes.lightray}
      ></div>
    </ContainerFullHeight>
  );
}
