import React from "react";
import { useTranslation } from "react-i18next";
import { useRegistrationFormUtils } from "hooks/useRegistrationFormUtils";
import { makeStyles } from "@material-ui/core/styles";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import { RegistrationInfoSummary } from "./RegistrationInfoSummary";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { STEP_ACCOMMODATION_INFO } from "constants/registrationForm";
import { PACKAGE_CONVENTION_WITH_HOTEL } from "constants/registrationForm";
import { HotelSummary } from "./HotelSummary";
import { ActivitiesInfoSummary } from "./ActivitiesInfoSummary";
import { AdditionalInfoSummary } from "./AdditionalInfoSummary";
import { PACKAGE_CONVENTION_WITHOUT_HOTEL } from "constants/registrationForm";
import { PACKAGE_NO_CONVENTION } from "constants/registrationForm";
import { WithoutHotelSummary } from "./WithoutHotelSummary";
import { NoConventionSummary } from "./NoConventionSummary";
import { TotalMoney } from "../TotalMoney";
const useStyles = makeStyles(basicsStyle);

export const OnlyRegistrationSummary = ({ registration }) => {
  const { t } = useTranslation(["registrationForm"]);
  const { getStepName, getPackageLabel } = useRegistrationFormUtils();
  const classes = useStyles();
  //registration = registration2;
  const registrationData = {
    ...registration.registration,
    email: registration.email,
    hasGivenPermissions: registration.hasGivenPermission,
    emergencyContact: { ...registration.emergencyContact },
  };
  return (
    <>
      <RegistrationInfoSummary registrationData={registrationData} />
      <GridContainer>
        <GridItem xs={12}>
          <h4 className={classes.registrationTitle}>
            {getStepName(STEP_ACCOMMODATION_INFO)}:{" "}
            {getPackageLabel(registration.selectedPackage)}
          </h4>
        </GridItem>
      </GridContainer>
      {registration.selectedPackage === PACKAGE_CONVENTION_WITH_HOTEL && (
        <HotelSummary
          hotelInfo={registration.totalDetailed.withHotel}
          companion={registration.hotelReservation.companionData}
          currency={registration.currency}
        />
      )}
      {registration.selectedPackage === PACKAGE_CONVENTION_WITHOUT_HOTEL && (
        <WithoutHotelSummary
          info={registration.totalDetailed.justConvention}
          guests={registration.hotelReservation.guestData}
          currency={registration.currency}
        />
      )}
      {registration.selectedPackage === PACKAGE_NO_CONVENTION && (
        <NoConventionSummary
          count={registration.hotelReservation.noConventionPeopleCount}
          guests={registration.hotelReservation.guestData}
          currency={registration.currency}
        />
      )}

      <ActivitiesInfoSummary
        activities={registration.totalDetailed.activities}
        currency={registration.currency}
      />
      <AdditionalInfoSummary
        donation={registration.totalDetailed.donation}
        comments={registration.comments}
        currency={registration.currency}
      />
      <TotalMoney
        justify="flex-end"
        total={registration.total}
        currency={registration.currency}
      />
    </>
  );
};
