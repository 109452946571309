import { blackColor, hexToRgb } from "assets/jss/material-kit-pro-react.js";

const videoStyle = (theme) => ({
  iframeContainer: {
    marginTop: "0px",
    "& > iframe": {
      width: "100%",
      boxShadow:
        "0 16px 38px -12px rgba(" +
        hexToRgb(blackColor) +
        ", 0.56), 0 4px 25px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)",
    },
  },
});

export default videoStyle;
