import React from "react";
import { useTranslation } from "react-i18next";

// react component for creating beautiful carousel
import Carousel from "react-slick";
import FreeVideo from "./Slices/FreeVideo.js";
import HouseBand from "./Slices/HouseBand";

export default function SectionHeaders({ ...rest }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };
  const { t } = useTranslation(["main-carousel"]);
  const now = new Date();
  const finalDateFreeVideo = new Date(2021, 0, 10, 0, 0, 0, 0);
  const finalDateFreeMusic = new Date(2021, 0, 20, 0, 0, 0, 0);

  return (
    // we've set the className to cd-section so we can make smooth scroll to it
    <div className="cd-section" {...rest}>
      <div>
        <Carousel {...settings}>
          {now < finalDateFreeVideo ? (
            <FreeVideo t={t} />
          ) : now < finalDateFreeMusic ? (
            <HouseBand t={t} />
          ) : null}
        </Carousel>
      </div>
    </div>
  );
}
