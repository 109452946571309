import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js";
import Course from "../../../components/Course/Course.js";
import { useDispatch, useSelector } from "react-redux";

import { startToLoadMyCourseList } from "actions/courses.js";
import { Link } from "react-router-dom";
import Loading from "components/Loading/Loading.js";

const useStyles = makeStyles(blogsStyle);

export default function CourseSection({ ...rest }) {
  const { t } = useTranslation(["my-videos"]);

  const classes = useStyles();
  const dispatch = useDispatch();

  const courses = useSelector((state) => state.courses.myList);

  const loading = useSelector((state) => state.status.loadingMyList);
  useEffect(() => {
    dispatch(startToLoadMyCourseList());
  }, [dispatch]);

  return (
    <div className="cd-section" {...rest}>
      <div className={classes.blog}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={10}
              md={10}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <br />
              <br />
              <h2 className={classes.title}>{t("title")}</h2>
              {loading && <Loading />}
              {!loading && courses.length === 0 && <p>{t("no videos")}</p>}
              {!loading &&
                courses
                  .filter(({ id }) => !id?.startsWith("bundle_"))
                  .map((course) => (
                    <Link
                      key={course.id}
                      to={`/courseDetail/${course.video_id}`}
                    >
                      <Course {...course} key={course.id} boughted={true} />
                    </Link>
                  ))}
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
