import {
  title,
  cardTitle,
  coloredShadow,
  grayColor,
  dangerColor,
} from "assets/jss/material-kit-pro-react.js";

const sectionInterestedStyle = {
  title,
  cardTitle: { ...cardTitle, marginTop: 0 },
  coloredShadow,
  textCenter: {
    textAlign: "center",
  },
  section: {
    backgroundPosition: "50%",
    backgroundSize: "cover",
    padding: "70px 0",
  },
  description: {
    color: grayColor[0],
  },
  priceContainer: {
    textAlign: "right",
  },
  price: {
    fontSize: "18px",
    color: grayColor[22],
  },
  priceOld: {
    fontSize: "16px",
    textDecoration: "line-through",
  },
  priceNew: {
    color: dangerColor[0],
  },
  imgContainerItem:{
    paddingBottom: "20px",
  }
};

export default sectionInterestedStyle;
