import { PrimaryFontColorLanding } from "assets/jss/material-kit-pro-react";
import { textJustify } from "assets/jss/material-kit-pro-react";
import {
  container,
  title,
  titleLanding,
  whiteColor,
  PrimaryFontColor,
} from "assets/jss/material-kit-pro-react.js";

const style = (theme) => ({
  container: {
    ...container,
    zIndex: "2",
    marginBottom: "100px",
    position: "relative",
    "& h1, & h3, & h4, & h6": {
      color: PrimaryFontColorLanding,
    },
  },

  title: {
    ...titleLanding,
    textAlign: "center",
    marginBottom: "10px",
  },
  pageHeader: {
    position: "relative",
    height: "100vh",
    maxHeight: "1600px",
    backgroundPosition: "50%",
    backgroundSize: "cover",
    margin: "0",
    padding: "0",
    border: "0",
    display: "flex",
    WebkitBoxAlign: "start",
    MsFlexAlign: "start",
    alignItems: "start",

    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''",
    },
  },

  titleContainer: {
    backgroundPosition: "left",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    justifyContent: "flex-end",
    backgroundColor: "#DAD1C0",
    borderRadius: "40px",
    paddingBottom: "60px",
    paddingLeft: "60px",
    paddingRight: "60px",
    paddingTop: "30px"
  },
});

export default style;
