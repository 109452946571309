import React, { useState } from "react";
import { mapFormDataToTotalDTO, mapToRequestBody } from "mappers/formMapper";
import { useRegistrationFormCosts } from "./useRegistrationFormCosts";
import { postRegistration, getRegistration } from "services/registration";
import { CONVENTION_CURRENCY } from "constants/registrationFormCosts";
import { useTranslation } from "react-i18next";
import { getRegistrations } from "services/registration";

export const useRegistrationForm = () => {
  const { i18n } = useTranslation();
  const { getGlobalTotal, getTotalDetailed } = useRegistrationFormCosts();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasToRegistrate, setHasToRegistrate] = useState(false);
  const [registration, setRegistration] = useState(null);
  const [registrations, setRegistrations] = useState([]);

  const submitForm = async (formData, payment, paypalData) => {
    setLoading(true);
    setError(null);
    setRegistration(null);

    try {
      const mappedBody = mapToRequestBody(formData);
      const mappedDataToTotal = mapFormDataToTotalDTO(formData);
      const total = getGlobalTotal(mappedDataToTotal);
      const totalDetailed = getTotalDetailed(mappedDataToTotal);
      const body = {
        ...mappedBody,
        total,
        totalDetailed,
        payment,
        selectedLanguage: i18n.language,
        currency: CONVENTION_CURRENCY,
      };
      if (paypalData) {
        body.paypalData = paypalData;
      }

      const resp = await postRegistration(body);

      return resp;
    } catch (error) {
      setError(error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const getRegistrationData = async () => {
    setLoading(true);
    setError(null);
    setRegistration(null);

    try {
      const data = await getRegistration();
      setRegistration(data);
    } catch (error) {
      setError(error || "An error occurred");

      if (
        error?.response?.data &&
        error.response.data?.reason === "NoRegistration"
      ) {
        setHasToRegistrate(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const getRegistrationsData = async () => {
    setLoading(true);
    setError(null);
    setRegistrations([]);

    try {
      const data = await getRegistrations();
      console.log(data);
      setRegistrations(data);
    } catch (error) {
      setError(error || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    registration,
    registrations,
    submitForm,
    getRegistrationData,
    hasToRegistrate,
    getRegistrationsData,
  };
};
