import React from "react";
import { useTranslation } from "react-i18next";
import GridItem from "components/Grid/GridItem";
import Info from "components/Typography/Info";
import { CompanionDataForm } from "components/Registrer/components/CompanionDataForm";
import { NumberOfPeopleSelect } from "components/Registrer/components/NumberOfPeopleSelect";

export const NoConvention = ({
  register,
  control,
  selectedNoConventionPeopleCount,
}) => {
  const { t } = useTranslation(["registrationForm"]);
  return (
    <>
      <GridItem xs={12}>
        <NumberOfPeopleSelect
          control={control}
          name="noConventionPeopleCount"
          allowJustMe
        />
      </GridItem>
      {selectedNoConventionPeopleCount > 1 && (
        <>
          <GridItem xs={12}>
            <Info>{t("packageNoConventionOtherPeopleInfo")}</Info>
          </GridItem>
          <GridItem xs={12}>
            <h5>{t("packageNoConventionOtherPeopleTitle")}: </h5>
          </GridItem>
          <CompanionDataForm
            register={register}
            numberOfCompanions={selectedNoConventionPeopleCount - 1}
            control={control}
            name="guest"
          />
        </>
      )}
      <GridItem xs={12}>
        <Info>{t("packageNoConventionInfo")}</Info>
      </GridItem>
    </>
  );
};
