import { types } from "../types/statusTypes";
const initialState = { loadingList: false, loadingMyList: false };
export const statusReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.loadingListTrue:
      return {
        ...state,
        loadingList: true,
      };
    case types.loadingListFalse:
      return {
        ...state,
        loadingList: false,
      };

    case types.loadingMyListTrue:
      return {
        ...state,
        loadingMyList: true,
      };
    case types.loadingMyListFalse:
      return {
        ...state,
        loadingMyList: false,
      };
    case types.reset:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
