import {
  STEP_ADDITIONAL_INFO,
  STEP_PERSONAL_INFO,
  STEP_ACTIVITY_INFO,
  STEP_ACCOMMODATION_INFO,
} from "constants/registrationForm";

const mapToRequestBody = (formData, payment) => {
  return {
    registration: {
      name: formData[STEP_PERSONAL_INFO].name,
      phone: formData[STEP_PERSONAL_INFO].phone,
      city: formData[STEP_PERSONAL_INFO].city,
      title: formData[STEP_PERSONAL_INFO].honorTitle,
    },
    emergencyContact: {
      name: formData[STEP_PERSONAL_INFO].emergencyContactName,
      email: formData[STEP_PERSONAL_INFO].emergencyContactEmail,
      phone: formData[STEP_PERSONAL_INFO].emergencyContactPhone,
      relationship: formData[STEP_PERSONAL_INFO].emergencyContactRelation,
    },
    selectedPackage: formData[STEP_ACCOMMODATION_INFO].package,
    hotelReservation: {
      hotelName: formData[STEP_ACCOMMODATION_INFO].hotelOption,
      daysBefore: formData[STEP_ACCOMMODATION_INFO].hotelNightsBefore || 0,
      daysAfter: formData[STEP_ACCOMMODATION_INFO].hotelNightsAfter || 0,
      travelingWithCompanion: formData[STEP_ACCOMMODATION_INFO].hasCompanion,
      justConventionPeopleCount:
        formData[STEP_ACCOMMODATION_INFO].justConventionPeopleCount || 0,
      noConventionPeopleCount:
        formData[STEP_ACCOMMODATION_INFO].noConventionPeopleCount || 0,
      guestData: [1, 2, 3, 4].map((el) => ({
        name: formData[STEP_ACCOMMODATION_INFO]["guest_name_" + el], // Not provided in old formData, assuming it's empty
        phone: formData[STEP_ACCOMMODATION_INFO]["guest_phone_" + el], // Not provided in old formData, assuming it's empty
        city: formData[STEP_ACCOMMODATION_INFO]["guest_city_" + el], // Not provided in old formData, assuming it's empty
        email: formData[STEP_ACCOMMODATION_INFO]["guest_email_" + el], // Not provided in old formData, assuming it's e
      })),

      companionData: {
        name: formData[STEP_ACCOMMODATION_INFO].companion_name_1, // Not provided in old formData, assuming it's empty
        phone: formData[STEP_ACCOMMODATION_INFO].companion_phone_1, // Not provided in old formData, assuming it's empty
        city: formData[STEP_ACCOMMODATION_INFO].companion_city_1, // Not provided in old formData, assuming it's empty
        email: formData[STEP_ACCOMMODATION_INFO].companion_email_1, // Not provided in old formData, assuming it's empty
      },
    },
    extraActivities: {
      workshop: formData[STEP_ACTIVITY_INFO].workshopPeopleCount || 0,
      excursionDay1: {
        name: formData[STEP_ACTIVITY_INFO].activityDay1,
        count: formData[STEP_ACTIVITY_INFO].excursionPeopleCount || 0,
      },
      galaDinnerPeopleCount:
        formData[STEP_ACTIVITY_INFO].galaDinnerPeopleCount || 0,
      piramidePeopleCount:
        formData[STEP_ACTIVITY_INFO].piramidePeopleCount || 0,
    },

    donations: formData[STEP_ADDITIONAL_INFO].donationAmount || 0,
    comments: formData[STEP_ADDITIONAL_INFO].comments,
    hasGivenPermission:
      formData[STEP_ADDITIONAL_INFO].hasGivenPermissions === "yes",

    payment, // Not provided in old formData, assuming it's empty
  };
};

const mapFormDataToTotalDTO = (formData) => {
  const {
    hasCompanion,
    hotelNightsAfter,
    hotelNightsBefore,
    hotelOption,
    package: selectedPackage,
    justConventionPeopleCount = 0,
  } = formData[STEP_ACCOMMODATION_INFO];

  const {
    activityDay1,
    excursionPeopleCount,
    galaDinnerPeopleCount,
    piramidePeopleCount,
    workshopPeopleCount,
  } = formData[STEP_ACTIVITY_INFO];

  const donation =
    parseFloat(formData[STEP_ADDITIONAL_INFO].donationAmount) || 0;
  return {
    accommodationData: mapFormDataToAccomodationDTO(
      selectedPackage,
      hotelOption,
      hotelNightsBefore,
      hotelNightsAfter,
      hasCompanion,
      justConventionPeopleCount
    ),
    activitiesData: mapFormDataToActivitiesDTO(
      activityDay1,
      excursionPeopleCount,
      galaDinnerPeopleCount,
      piramidePeopleCount,
      workshopPeopleCount
    ),
    donation,
  };
};

const mapFormDataToActivitiesDTO = (
  excursionDay1,
  excursionAttendeesCount,
  galaAttendeesCount,
  piramidesAttendeesCount,
  workshopPeopleCount
) => {
  return {
    excursionDay1,
    excursionAttendeesCount,
    galaAttendeesCount,
    piramidesAttendeesCount,
    workshopPeopleCount,
  };
};

const mapFormDataToAccomodationDTO = (
  selectedPackage,
  selectedHotelOption,
  selectedHotelNightsBefore,
  selectedHotelNightsAfter,
  selectedHasCompanion,
  selectedJustConventionPeopleCount
) => {
  return {
    selectedPackage,
    selectedHotel: selectedHotelOption,
    preConventionNights: selectedHotelNightsBefore,
    postConventionNights: selectedHotelNightsAfter,
    companionStatus: selectedHasCompanion,
    conventionAttendeesCount: selectedJustConventionPeopleCount,
  };
};
const mapFromResponse = (responseData) => {
  return {
    userId: responseData.userId,
    // Map other fields as needed
  };
};
export {
  mapToRequestBody,
  mapFromResponse,
  mapFormDataToAccomodationDTO,
  mapFormDataToTotalDTO,
  mapFormDataToActivitiesDTO,
};
