import GridItem from "components/Grid/GridItem";
import React from "react";
import { SummaryData } from "../SummaryData";

import { makeStyles } from "@material-ui/core/styles";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import { useTranslation } from "react-i18next";
import { useRegistrationFormUtils } from "hooks/useRegistrationFormUtils";

import GridContainer from "components/Grid/GridContainer";
import { STEP_ADDITIONAL_INFO } from "constants/registrationForm";
const useStyles = makeStyles(basicsStyle);

export const AdditionalInfoSummary = ({ donation, comments, currency }) => {
  const classes = useStyles();
  const { t } = useTranslation(["registrationForm"]);
  const { getStepName } = useRegistrationFormUtils();

  return (
    <GridContainer>
      <GridItem xs={12}>
        <h4 className={classes.registrationTitle}>
          {getStepName(STEP_ADDITIONAL_INFO)}
        </h4>
        <GridItem sm={12}>
          <SummaryData
            label={t("donationTitle")}
            cost={donation}
            currency={currency}
          />
          {!!comments && (
            <SummaryData label={t("commentTitle")} value={comments} />
          )}
        </GridItem>
      </GridItem>
    </GridContainer>
  );
};
