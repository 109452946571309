import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { firebase } from "../../firebase/firebase-config";

// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
// sections of this Page
import CourseDetailSection from "./Sections/CourseDetailSection.js";

import courseDetailStyle from "./CourseDetailStyle";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import NoCourseError from "./Sections/NoCourseError/NoCourseError";
import Footer from "components/Footer/Footer";

const useStyles = makeStyles(courseDetailStyle);

export default function CourseDetail() {
  const classes = useStyles();
  const [course, setCourse] = useState(null);
  const [error, setError] = useState(false);

  const { id } = useParams();
  if (!course && !error) {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((token) => {
        axios
          .get(`${process.env.REACT_APP_BACKEND_URI}videos/video`, {
            headers: { "x-firebase-token": token },
            params: {
              videoid: id,
            },
          })
          .then((res) => {
            setCourse(res?.data);
          })
          .catch((err) => {
            console.log(err);
            setError(true);
          });
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  }

  return (
    <div>
      <Header color="primary" />
      <div className={classes.main + " " + classes.fullHeightWithFooter}>
        {course && <CourseDetailSection courseData={course} />}
        {error && <NoCourseError />}
      </div>
      <Footer></Footer>
    </div>
  );
}
