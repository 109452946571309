import {
  container,
  title,
  cardTitle,
  coloredShadow,
  description,
  mlAuto,
  mrAuto,
  grayColor,
  justifyCenter,
} from "assets/jss/material-kit-pro-react.js";

const registrationInfo = {
  container,
  title,
  coloredShadow,
  cardTitle,
  mlAuto,
  mrAuto,
  description,
  justifyCenter,
  blog: {
    padding: "50px 0",
  },
  cardCategory: {
    marginBottom: "0",
    marginTop: "10px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "8px",
      lineHeight: "0",
    },
  },
  description1: {
    ...description,
    lineHeight: "1.313rem",
  },
  author: {
    "& a": {
      color: grayColor[1],
      textDecoration: "none",
    },
  },
  card: {
    marginBottom: "80px",
  },
  card4: {
    marginBottom: "60px",
    textAlign: "center",
  },
  text: {
    fontSize: "16px",
  },
  title: {
    ...title,
    textAlign: "center",
  },
  subtitle: {
    textAlign: "center",
  },
  titleSection: {
    marginBottom: "30px",
    marginTop: "70px",
  },
  languageIcon: {
    width: "20px",
    marginLeft: "15px",
  },
  select: {
    display: "flex",
    justifyContent: "space-between",
  },
};

export default registrationInfo;
