import React from "react";
import { Route, Redirect } from "react-router";

function PublicRoute({ component: Component, authed, ...rest }) {
  const redirectRoutes = ["/register"];
  const validLocationFromPaths = ["/login-page", "/signup-page"];

  const getRedirectPath = (location = {}) => {
    const defaultPath = "/";
    if (validLocationFromPaths.includes(location?.pathname)) {
      const prevPath = location?.state?.from?.pathname;
      if (redirectRoutes.includes(prevPath)) {
        return prevPath;
      }
    }
    return defaultPath;
  };
  return (
    <Route
      {...rest}
      render={(props) => {
        return authed ? (
          <Redirect
            to={{
              pathname: getRedirectPath(props.location),
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
}

export default PublicRoute;
