import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import { CustomSelect } from "components/CustomForm";
import GridItem from "components/Grid/GridItem";
import { CustomRadioInput } from "components/CustomForm/CustomRadioInput";

import { makeStyles } from "@material-ui/core/styles";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import { WhatIsIncluded } from "components/Registrer/components/WhatIsIncluded";
import { CompanionDataForm } from "components/Registrer/components/CompanionDataForm";
import { useRegistrationFormUtils } from "hooks/useRegistrationFormUtils";
import { useTranslation } from "react-i18next";
import {
  COMPANION_HAS_COMPANION_NO_PAY,
  COMPANION_HAS_COMPANION_WILL_PAY,
  PACKAGE_CONVENTION_WITH_HOTEL,
} from "constants/registrationForm";
import { Box } from "@material-ui/core";
import { MoneyFormatter } from "components/Registrer/components/MoneyFormatter";
import { useRegistrationFormCosts } from "hooks/useRegistrationFormCosts";

const useStyles = makeStyles(basicsStyle);
export const ConventionWithHotel = ({
  register,
  control,
  selectedHasCompanion,
  selectedHotel,
  selectedNightsBefore,
  selectedNightsAfter,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(["registrationForm"]);
  const { hotelList, companionList } = useRegistrationFormUtils();
  const {
    getHotelExtraNightCostPerNight,
    getCompanionCost,
    getHotelCost,
  } = useRegistrationFormCosts();
  const selectedPackage = PACKAGE_CONVENTION_WITH_HOTEL;

  return (
    <>
      <GridItem xs={12}>
        <CustomRadioInput
          id="hotelOption"
          name="hotelOption"
          label={t("hotelTitle")}
          control={control}
          fullWidth
          defaultValue={hotelList[0].id}
          options={hotelList.map((el) => ({
            ...el,
            label: (
              <Box className={classes.dFlexSpaceBetween}>
                <Box>{el.label}</Box>
                <MoneyFormatter value={getHotelCost(el.id)} />
              </Box>
            ),
          }))}
        />
      </GridItem>
      <GridItem xs={12} md={6}>
        <CustomSelect
          id="hotelNightsBefore"
          name="hotelNightsBefore"
          label={t("numberOfNightsBefore")}
          control={control}
          fullWidth
          defaultValue={0}
          className={
            classes.selectFormControl + " " + classes.selectUnderlineRoot
          }
        >
          {[0, 1, 2, 3].map((item) => (
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={item}
              key={item}
            >
              <Box className={classes.dFlexSpaceBetween}>
                <Box>{item}</Box>
                <MoneyFormatter
                  value={getHotelExtraNightCostPerNight(selectedHotel, item)}
                />
              </Box>
            </MenuItem>
          ))}
        </CustomSelect>
      </GridItem>
      <GridItem xs={12} md={6}>
        <CustomSelect
          id="hotelNightsAfter"
          name="hotelNightsAfter"
          label={t("numberOfNightsAfter")}
          control={control}
          fullWidth
          defaultValue={0}
          className={
            classes.selectFormControl + " " + classes.selectUnderlineRoot
          }
        >
          {[0, 1, 2, 3].map((item) => (
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={item}
              key={item}
            >
              <Box className={classes.dFlexSpaceBetween}>
                <Box>{item}</Box>
                <MoneyFormatter
                  value={getHotelExtraNightCostPerNight(selectedHotel, item)}
                />
              </Box>
            </MenuItem>
          ))}
        </CustomSelect>
      </GridItem>

      <GridItem xs={12}>
        <CustomRadioInput
          id="hasCompanion"
          name="hasCompanion"
          label={t("companionTitle")}
          control={control}
          fullWidth
          defaultValue={companionList[0].id}
          options={companionList.map((el) => ({
            ...el,
            label: (
              <Box className={classes.dFlexSpaceBetween}>
                <Box>{el.label}</Box>
                <MoneyFormatter
                  value={getCompanionCost(
                    el.id,
                    selectedHotel,
                    selectedNightsBefore,
                    selectedNightsAfter
                  )}
                />
              </Box>
            ),
          }))}
        />
      </GridItem>

      {(selectedHasCompanion === COMPANION_HAS_COMPANION_NO_PAY ||
        selectedHasCompanion === COMPANION_HAS_COMPANION_WILL_PAY) && (
        <>
          <GridItem xs={12}>
            <h5>{t("companionTextHelp")}</h5>
          </GridItem>
          <GridItem xs={12}>
            <CompanionDataForm
              name="companion"
              register={register}
              control={control}
              numberOfCompanions={1}
            />
          </GridItem>
        </>
      )}
      <WhatIsIncluded selectedPackage={selectedPackage} />
    </>
  );
};
