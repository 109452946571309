import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
// sections of this Page
import CourseSection from "./Sections/CourseSection.js";

import coursesPageStyle from "styles/pages/course/courseStyle.js";

const useStyles = makeStyles(coursesPageStyle);

export default function CoursesPage({ isLogged }) {
  const classes = useStyles();
  return (
    <div>
      <Header color="primary" />
      <div className={classes.main + " " + classes.fullHeight}>
        <CourseSection id="courses" isLogged={isLogged} />
      </div>
    </div>
  );
}
