import GridItem from "components/Grid/GridItem";
import React from "react";
import { SummaryData } from "../SummaryData";
import { CompanionShortData } from "../CompanionShortData";

import { useTranslation } from "react-i18next";
import { useRegistrationFormUtils } from "hooks/useRegistrationFormUtils";
import { COMPANION_NO_COMPANION } from "constants/registrationForm";

export const HotelSummary = ({ hotelInfo, companion, currency }) => {
  const { t } = useTranslation(["registrationForm"]);
  const { getHotelLabel, getCompanionLabel } = useRegistrationFormUtils();

  return (
    <GridItem sm={12}>
      <SummaryData
        label={t("hotelTitle")}
        value={getHotelLabel(hotelInfo.hotel.id)}
        cost={hotelInfo.hotel.unitCost}
        currency={currency}
      />
      <SummaryData
        label={t("numberOfNightsBefore")}
        value={hotelInfo.nightsBefore.count}
        cost={hotelInfo.nightsBefore.total}
        currency={currency}
      />
      <SummaryData
        label={t("numberOfNightsAfter")}
        value={hotelInfo.nightsAfter.count}
        cost={hotelInfo.nightsAfter.total}
        currency={currency}
      />
      <SummaryData
        label={t("companionTitle")}
        value={getCompanionLabel(hotelInfo.companionStatus.id)}
        cost={hotelInfo.companionStatus.total}
        currency={currency}
      />
      {hotelInfo.companionStatus.id !== COMPANION_NO_COMPANION && (
        <CompanionShortData
          name={companion.name}
          email={companion.email}
          phone={companion.phone}
          city={companion.city}
        />
      )}
    </GridItem>
  );
};
