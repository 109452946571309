import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import ministry from "assets/img/convention/Clarita.jpg";

import style from "./MinistrySectionStyle";

import { makeStyles } from "@material-ui/core/styles";
import ContainerFullHeight from "components/ContainerFullHeight/ContainerFullHeight";
import { SecondaryFontColor } from "assets/jss/material-kit-pro-react.js";
import { useTranslation } from "react-i18next";
import { Paragraph } from "components/Paragraph/Paragraph";
import Subtitle from "components/Subtitle/Subtitle";
import Button from "components/CustomButtons/Button.js";
import { ParagraphLanding } from "components/ParagraphLanding/ParagraphLanding";
import SubtitleSmaller from "components/SubtitleSmaller/SubtitleSmaller";

const useStyles = makeStyles(style);

export default function MinistrySection({ id, t }) {
  const { i18n } = useTranslation(["homePage"]);
  const classes = useStyles();
  const excursionSpanishURL =
    "https://sites.google.com/view/convenciondelasamericas/pagina-principal";
  const excursionEnglishURL =
    "https://sites.google.com/view/conventionoftheamericas/home";

  return (
    <ContainerFullHeight alignItems="start" bgColor="white" paddingTop="200px" paddingBottom="200px">
      <div className={classes.container} id={id}>
        <GridContainer className={classes.titleContainer}>
          <GridItem xs={12}>
            <h3 className={classes.title}>{t("ministryTitle")}</h3>
          </GridItem>
          <GridItem xs={12} md={3}>
            <img
              src={ministry}
              width={"100%"}
              alt="..."
              style={{ marginTop: "40px", borderRadius: "20px" }}
            />
          </GridItem>

          <GridItem xs={12} md={9}>
            <SubtitleSmaller subtitle="Dir. E. Clara Sanchez Anaya" align="left" />
            <ParagraphLanding text={t("ministryText")} />
          </GridItem>
        </GridContainer>
      </div>
    </ContainerFullHeight>
  );
}
