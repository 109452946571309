import { justifyCenter } from "assets/jss/material-kit-pro-react";
import {
  container,
  whiteColor,
  title,
  mlAuto,
  mrAuto,
} from "assets/jss/material-kit-pro-react.js";
import customCheckboxRadioSwitch from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.js";
import { displayPartsToString } from "typescript";

const basicsStyle = {
  mlAuto,
  mrAuto,
  container,
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  sections: {
    padding: "70px 0",
  },
  title: {
    ...title,
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  space50: {
    height: "50px",
    display: "block",
  },
  space70: {
    height: "70px",
    display: "block",
  },
  icons: {
    width: "17px",
    height: "17px",
    color: whiteColor,
  },
  registrationTitle: {
    ...title,
    marginTop: "10px",
    marginBottom: "5px",
    textDecoration: "none",
  },
  registrationSubtitle: {
    fontSize: "16px",
    fontWeight: "400",
    color: "currentColor",
  },
  registrationSelect: {
    marginTop: "15px",
  },
  registrationSection: {
    marginBottom: "20px",
  },
  dFlex: {
    display: "flex",
    alignItems: "center",
  },
  moneyTitle: {
    ...title,
    color: "#4caf50",
  },
  dFlexSpaceBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dFlexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default basicsStyle;
