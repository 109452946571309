import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react component for creating beautiful carousel
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MusicNote from "@material-ui/icons/MusicNote";

// core components

import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js";

/* import dg1 from "assets/img/dg1.jpg";
import dg2 from "assets/img/dg2.jpg";
import dg3 from "assets/img/dg3.jpg";
 */

import dg3 from "assets/img/convention/piano.jpg";

const useStyles = makeStyles(headersStyle);

export default function SectionHeaders({ t, ...rest }) {
  const classes = useStyles();

  return (
    <div>
      <div
        className={classes.pageHeader}
        style={{ backgroundImage: `url("${dg3}")` }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={7}
              md={7}
              className={classNames(classes.mlAuto, classes.textRight)}
            >
              <h1 className={classes.title}>{t("house-band-title")}</h1>
              <h4>{t("house-band-description")}</h4>
              <br />
              <div>
                <a
                  href="https://view.genial.ly/5fdd436f98d5210dac6f9694/presentation-cancionero-song-book"
                  target="blank"
                >
                  <Button color="danger" size="lg">
                    <MusicNote /> {t("house-band-button")}
                  </Button>
                </a>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
