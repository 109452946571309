import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
// sections of this Page
import MyCourseSection from "./Sections/MyCourseSection.js";

import coursesPageStyle from "styles/pages/course/courseStyle.js";

const useStyles = makeStyles(coursesPageStyle);

export default function MyCoursesPage() {
  const classes = useStyles();
  return (
    <div>
      <Header color="primary" />
      <div className={classes.main + " " + classes.fullHeight}>
        <MyCourseSection id="courses" />
      </div>
    </div>
  );
}
