import { types } from "../types/coursesTypes";
import { firebase } from "../firebase/firebase-config";
import axios from "axios";
import {
  loadingListFalse,
  loadingListTrue,
  loadingMyListFalse,
  loadingMyListTrue,
} from "./status.js";

export const startToLoadCourseList = () => {
  return (dispatch) => {
    dispatch(loadingListTrue());
    axios
      .get(`${process.env.REACT_APP_BACKEND_URI}videos/list`)
      .then((res) => {
        let courses = res.data;
        const isArr =
          Object.prototype.toString.call(courses) === "[object Array]";
        if (!isArr) courses = [];
        dispatch(loadCourselist(courses));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(loadingListFalse());
      });
  };
};
export const startToLoadMyCourseList = () => {
  return (dispatch) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((token) => {
        dispatch(loadingMyListTrue());
        axios
          .get(`${process.env.REACT_APP_BACKEND_URI}videos/mylist`, {
            headers: { "x-firebase-token": token },
          })
          .then((res) => {
            let courses = res.data.result;
            const isArr =
              Object.prototype.toString.call(courses) === "[object Array]";
            if (!isArr) courses = [];
            dispatch(loadMyCourselist(courses));
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            dispatch(loadingMyListFalse());
          });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };
};

export const loadCourselist = (courseList = []) => ({
  type: types.loadList,
  payload: {
    data: courseList,
  },
});
export const loadMyCourselist = (courseList = []) => ({
  type: types.loadMyList,
  payload: {
    data: courseList,
  },
});

export const addCourseToCart = (courseID) => ({
  type: types.addToCart,
  payload: {
    data: courseID,
  },
});

export const removeCourseFromCart = (courseID) => ({
  type: types.removeFromCart,
  payload: {
    data: courseID,
  },
});

export const cleanCart = (courseID) => ({
  type: types.cleanCart,
});

export const resetCoursesState = () => ({
  type: types.reset,
});
