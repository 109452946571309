import {
  container,
  primaryColor,
  mrAuto,
  whiteColor,
} from "assets/jss/material-kit-pro-react.js";

const pageHeader = {
  position: "relative",
  backgroundPosition: "50%",
  backgroundSize: "cover",
  margin: "0",
  border: "0",
  display: "flex",
  WebkitBoxAlign: "center",
  MsFlexAlign: "center",
  alignItems: "center",
  backgroundColor: primaryColor[0],

  "&:after,&:before": {
    position: "absolute",
    zIndex: "1",
    width: "100%",
    height: "100%",
    display: "block",
    left: "0",
    top: "0",
    content: "''",
  },
}

const ContainerFullHeight = (theme) => ({
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    "& h1, & h4, & h6": {
      color: whiteColor,
    },
  },

  pageHeader,

  pageHeaderWhite: {
    ...pageHeader,
    backgroundColor: "white",
  },

  pageHeaderBeige: {
    ...pageHeader,
    backgroundColor: "#DAD1C0",
  },

  mrAuto,
});

export default ContainerFullHeight;
