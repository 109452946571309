import React, { useEffect, useState } from "react";
import { createBrowserHistory } from "history";
import { firebase } from "../firebase/firebase-config";
import { Router, Route, Switch } from "react-router";
import { useTranslation } from "react-i18next";

import AboutUsPage from "views/AboutUsPage/AboutUsPage.js";
import BlogPostPage from "views/BlogPostPage/BlogPostPage.js";
import BlogPostsPage from "views/BlogPostsPage/BlogPostsPage.js";
import ComponentsPage from "views/ComponentsPage/ComponentsPage.js";
import ContactUsPage from "views/ContactUsPage/ContactUsPage.js";
import EcommercePage from "views/EcommercePage/EcommercePage.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import PresentationPage from "views/PresentationPage/PresentationPage.js";
import PricingPage from "views/PricingPage/PricingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import ProductPage from "views/ProductPage/ProductPage.js";
import SectionsPage from "views/SectionsPage/SectionsPage.js";
import ShoppingCartPage from "views/ShoppingCartPage/ShoppingCartPage.js";
import SignupPage from "views/SignupPage/SignupPage.js";
import ErrorPage from "views/ErrorPage/ErrorPage.js";

import CoursesPage from "views/CoursesPage/CoursesPage.js";
import MyCoursesPage from "views/MyCoursesPage/MyCoursesPage.js";

import { useDispatch } from "react-redux";
import { login, startLogout } from "actions/auth";
import LoadingPage from "components/LoadingPage/LoadingPage";
import PublicRoute from "components/PublicRoute/PublicRoute";
import PrivateRoute from "../components/PrivateRoute/PrivateRoute.js";
import CourseDetail from "views/CourseDetail/CourseDetail";
import {
  startToLoadCourseList,
  startToLoadMyCourseList,
} from "actions/courses";
import { RegisterPage } from "views/RegisterPage/RegisterPage.jsx";
import { RegistrationsPage } from "views/RegistrationsPage/RegistrationsPage";

export default function AppRouter(props) {
  const hist = createBrowserHistory();
  const dispatch = useDispatch();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [checking, setChecking] = useState(true);
  const [setAccessToken] = useState("");
  const { i18n } = useTranslation();

  useEffect(() => {
    let storagedLanguage = localStorage.getItem("storagedLanguage"),
      browserLan = navigator.language || navigator.userLanguage;
    if (storagedLanguage) {
      i18n.changeLanguage(storagedLanguage);
    } else {
      if (browserLan.includes("es")) {
        localStorage.setItem("storagedLanguage", "es");
        i18n.changeLanguage("es");
      } else {
        i18n.changeLanguage("en");
        localStorage.setItem("storagedLanguage", "en");
      }
    }
  }, [i18n]);
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user?.uid) {
        dispatch(login(user.uid, user.displayName, user.email));
        dispatch(startToLoadCourseList());
        dispatch(startToLoadMyCourseList());
        user.getIdToken().then(function (idToken) {
          // <------ Check this line
          //alert(idToken); // It shows the Firebase token now
          return idToken;
        });
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
        dispatch(startLogout());
      }
      setChecking(false);
    });
  }, [dispatch, setChecking, setIsLoggedIn, setAccessToken]);

  if (checking) {
    return <LoadingPage />;
  }
  return (
    <Router key={Math.random()} history={hist}>
      <Switch>
        <Route path="/about-us" component={AboutUsPage} />
        <Route path="/blog-post" component={BlogPostPage} />
        <Route path="/blog-posts" component={BlogPostsPage} />
        <Route path="/components" component={ComponentsPage} />
        <Route path="/contact-us" component={ContactUsPage} />
        <Route path="/ecommerce-page" component={EcommercePage} />
        <Route path="/landing-page" component={LandingPage} />
        <Route path="/pricing" component={PricingPage} />
        <Route path="/profile-page" component={ProfilePage} />
        <Route path="/product-page" component={ProductPage} />
        <Route path="/sections" component={SectionsPage} />
        <Route path="/courses" isLogged={isLoggedIn} component={CoursesPage} />
        <PrivateRoute
          authed={isLoggedIn}
          path="/courseDetail/:id"
          component={CourseDetail}
        />
         <PrivateRoute
          authed={isLoggedIn}
          path="/register/admin"
          component={RegistrationsPage}
        />
        <PrivateRoute
          authed={isLoggedIn}
          path="/register"
          component={RegisterPage}
        />
        <PublicRoute
          authed={isLoggedIn}
          path="/login-page"
          component={LoginPage}
        />
        <PrivateRoute
          authed={isLoggedIn}
          path="/shopping-cart-page"
          component={ShoppingCartPage}
        />
        <PrivateRoute
          authed={isLoggedIn}
          path="/my-courses"
          component={MyCoursesPage}
        />
        <PublicRoute
          authed={isLoggedIn}
          path="/signup-page"
          component={SignupPage}
        />
        <Route path="/error-page" component={ErrorPage} />
        <Route path="/" component={PresentationPage} />
      </Switch>
    </Router>
  );
}
