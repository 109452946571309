import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import { useRegistrationFormUtils } from "hooks/useRegistrationFormUtils";
import { CompanionShortData } from "../CompanionShortData";
import { STEP_PERSONAL_INFO } from "constants/registrationForm";
import { SummaryData } from "../SummaryData";
import { TITLE_NONE } from "constants/registrationForm";
const useStyles = makeStyles(basicsStyle);

export const RegistrationInfoSummary = ({ registrationData }) => {
  const classes = useStyles();
  const { t } = useTranslation(["registrationForm"]);
  const { getStepName, getHonorTitleLabel } = useRegistrationFormUtils();

  const {
    name,
    city,
    email,
    phone,
    hasGivenPermissions,
    emergencyContact,
    title,
  } = registrationData;
  return (
    <>
      <GridContainer>
        <GridItem xs={12}>
          <h4 className={classes.registrationTitle}>
            {getStepName(STEP_PERSONAL_INFO)}
          </h4>
          <GridItem sm={12}>
            <SummaryData
              label={t("formName")}
              value={
                title !== TITLE_NONE
                  ? getHonorTitleLabel(title) + " " + name
                  : name
              }
            />
            <SummaryData label={t("formEmail")} value={email} />
            <SummaryData label={t("formPhone")} value={phone} />
            <SummaryData label={t("formCity")} value={city} />
            <SummaryData
              label={t("permissionTitle")}
              value={t(hasGivenPermissions ? "yes" : "no")}
            />
            <div>
              {emergencyContact.name && (
                <>
                  <strong>{t("emergencyContactTitle")}:</strong>
                  <CompanionShortData
                    name={emergencyContact.name}
                    phone={emergencyContact.phone}
                    email={emergencyContact.email}
                    relationship={emergencyContact.relationship}
                  />
                </>
              )}
            </div>
          </GridItem>
        </GridItem>
      </GridContainer>
    </>
  );
};
