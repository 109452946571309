import React, { useEffect, useState } from "react";
import SnackbarContent from "components/Snackbar/SnackbarContent";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const LoginError = () => {
  const { t } = useTranslation(["login"]);
  const { error } = useSelector((state) => state.auth);
  const [message, setMessage] = useState("");
  useEffect(() => {
    if (error) {
      const text = getErrorMessage(error);
      setMessage(text);
    }
  }, [error]);

  if (!error) return <></>;

  const getErrorMessage = ({ code }) => {
    switch (code) {
      case "auth/user-not-found":
        return t("user-not-found");
      case "auth/email-already-in-use":
        return t("email-already-in-use");
      case "auth/wrong-password":
        return t("wrongCredentials");
      default:
        return t("default-error");
    }
  };

  return (
    <>
      <SnackbarContent message={message} color="danger" icon="info_outline" />
    </>
  );
};
