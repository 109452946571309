import React from "react";
import { CustomSelect } from "components/CustomForm";
import MenuItem from "@material-ui/core/MenuItem";
import Info from "components/Typography/Info";

import { makeStyles } from "@material-ui/core/styles";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import { useTranslation } from "react-i18next";
import { useRegistrationFormUtils } from "hooks/useRegistrationFormUtils";
import { Box } from "@material-ui/core";
import { MoneyFormatter } from "./MoneyFormatter";
const useStyles = makeStyles(basicsStyle);

export const NumberOfPeopleSelect = ({
  name,
  control,
  costCalculator = null,
  allowNone = false,
  allowJustMe = false,
  defaultValue,
}) => {
  const { t } = useTranslation(["registrationForm"]);
  const { getPersonLabel } = useRegistrationFormUtils();

  const classes = useStyles();
  let guest = [1, 2, 3, 4];
  if (allowNone) guest = [0, 1, 2, 3, 4];

  return (
    <>
      <CustomSelect
        id={name}
        name={name}
        label={t("numberOfPeople")}
        control={control}
        fullWidth
        defaultValue={defaultValue || guest[0]}
        className={
          classes.selectFormControl + " " + classes.selectUnderlineRoot
        }
      >
        {guest.map((item) => (
          <MenuItem
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={item}
            key={item}
          >
            <Box className={classes.dFlexSpaceBetween}>
              <Box> {getPersonLabel(item, allowJustMe)}</Box>
              {costCalculator && (
                <MoneyFormatter value={costCalculator(item)} />
              )}
            </Box>
          </MenuItem>
        ))}
      </CustomSelect>

      <Info>{t("maxPeople")}</Info>
    </>
  );
};
