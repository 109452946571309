/*eslint-disable*/
import React from "react";
import { useTranslation } from "react-i18next";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Face from "@material-ui/icons/Face";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
//import LoginForm from "components/LoginForm/LoginForm.js";
import { useForm } from "react-hook-form";
import loginPageStyle from "./LoginPageStyle.js";
import backgroundImage from "assets/img/convention/beach-1.jpg";

import { useDispatch, useSelector } from "react-redux";
import { startLoginEmailPassword, startGoogleLogin } from "actions/auth";
import { Link } from "react-router-dom";
import ImageLogin from "components/ImageLogin/ImageLogin";
import { LoginError } from "components/LoginForm/LoginError.jsx";

const useStyles = makeStyles(loginPageStyle);

export default function LoginPage({ location }) {
  const { t } = useTranslation(["login"]);
  const previusPath = location?.state?.from?.pathname || "/";

  const { error } = useSelector((state) => state.auth);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = ({ email, pass }) => {
    dispatch(startLoginEmailPassword(email, pass));
  };

  const handleGoogleLogin = (e) => {
    e.preventDefault();
    dispatch(startGoogleLogin());
  };

  const classes = useStyles();

  return (
    <div>
      <Header />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + backgroundImage + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          paddingTop: "250px",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                <CardHeader
                  color="primary"
                  signup
                  className={classes.cardHeader}
                >
                  <ImageLogin />
                  <h4 className={classes.cardTitle}>{t("Login")}</h4>
                  <div className={classes.socialLine}>
                    <Button color="signUpGoogle" onClick={handleGoogleLogin}>
                      <i
                        className="fab fa-google"
                        style={{ marginRight: "15px" }}
                      />
                      {t("loginWithGoogle")}
                    </Button>
                  </div>
                </CardHeader>
                <p className={classes.description + " " + classes.textCenter}>
                  {t("Or Be Classical")}
                </p>
                <CardBody signup>
                  <form
                    className={classes.form}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <LoginError />
                    {false && (
                      <CustomInput
                        id="first"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: t("Name"),
                          type: "text",
                          name: "first",
                          startAdornment: (
                            <InputAdornment position="start">
                              <Face className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                    <CustomInput
                      id="email"
                      register={register({
                        required: true,
                      })}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: t("Email"),
                        type: "email",
                        name: "email",
                        required: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      id="pass"
                      formControlProps={{
                        fullWidth: true,
                        name: "pass",
                      }}
                      register={register({
                        required: t("fieldRequired"),
                        minLength: { value: 6, message: t("fieldMinLength") },
                      })}
                      error={errors?.pass?.message}
                      inputProps={{
                        placeholder: t("Password"),
                        type: "password",
                        name: "pass",

                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon className={classes.inputIconsColor}>
                              lock_utline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                    <div className={classes.textCenter}>
                      <Button simple color="primary" size="lg" type="submit">
                        {t("Get Started")}
                      </Button>
                    </div>
                  </form>
                </CardBody>
                <p className={classes.description + " " + classes.textCenter}>
                  {t("New member")}{" "}
                  <Link
                    to={{
                      pathname: "/signup-page",
                      state: { from: { ...location, pathname: previusPath } },
                    }}
                  >
                    {t("SignUp Here!")}
                  </Link>
                </p>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
