import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ernestHolmes from "assets/img/convention/holmes-2.jpg";
import style from "./ScienceOfMindSectionStyle";
import { makeStyles } from "@material-ui/core/styles";
import ContainerFullHeight from "components/ContainerFullHeight/ContainerFullHeight";

const useStyles = makeStyles(style);

export default function ScienceOfMindSection({ t }) {
  const classes = useStyles();

  return (
    <ContainerFullHeight bgColor="white" paddingBottom="200px" paddingTop="200px">
      <div className={classes.container}>
        <div className={classes.beigeContainer}>
          <GridContainer className={classes.titleContainer}>
            <GridItem xs={12} sm={6} md={6}>
              <div className={classes.imgContainer}>
                <img
                  className={classes.img}
                  src={ernestHolmes}
                  alt={t("science-picDescription")}
                />
              </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} className={classes.textContainer}>
              <h2 className={classes.title}>{t("science-title")}</h2>
              <div className={classes.descriptionWrapper}>
                <h4 className={classes.subtitle}>{t("science-subtitle1")}</h4>
                <div className={classes.description}>
                  {t("science-description1")}
                </div>
              </div>
              <div className={classes.descriptionWrapper}>
                <h4 className={classes.subtitle}>{t("science-subtitle2")}</h4>
                <div className={classes.description}>
                  {t("science-description2")
                    .split("\n")
                    .map((c, index) => (
                      <div className={classes.description} key={index}>
                        {c}
                      </div>
                    ))}
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </ContainerFullHeight>
  );
}
