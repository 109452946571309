import { textJustify } from "assets/jss/material-kit-pro-react";
import { PrimaryFontColorLanding, subtitleLandingLight } from "assets/jss/material-kit-pro-react.js";

const style = (theme) => ({
  text: {
    ...subtitleLandingLight,
    color: PrimaryFontColorLanding,
    fontSize: "18px",
    ...textJustify,
  },
});

export default style;