import axios from "axios";
import firebase from "firebase";

export const postRegistration = async (formData) => {
  try {
    const headers = {
      "x-firebase-token": await getAuthToken(),
    };

    const url = `${process.env.REACT_APP_BACKEND_URI}formRegistration/registration`;
    const response = await axios.post(url, formData, { headers });
    return response?.data?.data || {};
  } catch (error) {
    throw error;
  }
};

export const getRegistration = async () => {
  try {
    const headers = {
      "x-firebase-token": await getAuthToken(),
    };

    const url = `${process.env.REACT_APP_BACKEND_URI}formRegistration/registration`;
    const response = await axios.get(url, { headers });

    return response?.data?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getRegistrations = async () => {
  try {
    const headers = {
      "x-firebase-token": await getAuthToken(),
    };

    const url = `${process.env.REACT_APP_BACKEND_URI}formRegistration/registrations`;
    const response = await axios.get(url, { headers });

    return response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getAuthToken = async () => {
  try {
    const isAuthenticated = await await firebase
      .auth()
      .currentUser.getIdToken(true);
    if (!isAuthenticated) {
      throw new Error("Firebase authentication failed");
    }
    return isAuthenticated;
  } catch (error) {
    throw new Error("Firebase authentication failed");
  }
};
