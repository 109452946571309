import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import styles from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js";
const useStyles = makeStyles(styles);

const ItemImage = ({ thumbnail }) => {
  const classes = useStyles();
  return (
    <div className={classes.imgContainer}>
      <img src={thumbnail} alt="..." className={classes.img} />
    </div>
  );
};

const ItemDetail = ({ title, speakers }) => {
  const classes = useStyles();

  return (
    <span>
      {title}
      <br />
      <small className={classes.tdNameSmall}>by {speakers[0]}</small>
    </span>
  );
};

const ItemPrice = ({ price, discountPrice }) => {
  const classes = useStyles();
  return (
    <span>
      <small className={classes.tdNumberSmall}>$</small>{" "}
      {discountPrice ? discountPrice : price}
    </span>
  );
};

const ItemDelete = ({ onClickHandle }) => {
  const classes = useStyles();

  return (
    <Tooltip
      id="close1"
      title="Remove item"
      placement="left"
      classes={{ tooltip: classes.tooltip }}
    >
      <Button link className={classes.actionButton} onClick={onClickHandle}>
        <Close />
      </Button>
    </Tooltip>
  );
};

export { ItemDelete, ItemDetail, ItemImage, ItemPrice };
