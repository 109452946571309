import React from "react";

import { useRegistrationFormUtils } from "hooks/useRegistrationFormUtils";
import { TotalMoney } from "./TotalMoney";

export const TotalDisplay = ({ stepId, total, partialTotal }) => {
  const { getOtherStepsTotals } = useRegistrationFormUtils();
  return (
    <TotalMoney
      justify="center"
      total={partialTotal + getOtherStepsTotals(total, stepId)}
    />
  );
};
