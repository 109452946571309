import React, { useEffect, useState } from "react";

import USALogo from "assets/img/convention/icons/usa.svg";
import MxLogo from "assets/img/convention/icons/mx.svg";
import { useTranslation } from "react-i18next";

export const useChangeLanguage = () => {
  const { i18n } = useTranslation(["header"]);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [languageLogo, setLanguageLogo] = useState("");
  useEffect(() => {
    let storagedLanguage = localStorage.getItem("storagedLanguage");
    if (storagedLanguage !== i18n.language) changeLanguage(storagedLanguage);
  }, []);

  const changeLanguage = (lng) => {
    switch (lng) {
      case "en":
        localStorage.setItem("storagedLanguage", "en");
        setLanguageLogo(USALogo);
        setSelectedLanguage(lng);
        break;
      case "es":
        localStorage.setItem("storagedLanguage", "es");
        setLanguageLogo(MxLogo);
        setSelectedLanguage(lng);
        break;
      default:
        localStorage.setItem("storagedLanguage", "en");
        setLanguageLogo(USALogo);
        setSelectedLanguage("en");
        break;
    }
    i18n.changeLanguage(lng);
  };
  return { languageLogo, selectedLanguage, changeLanguage };
};
