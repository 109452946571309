import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";

import GridItem from "components/Grid/GridItem";
import { useRegistrationFormUtils } from "hooks/useRegistrationFormUtils";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(basicsStyle);

export const TotalMoney = ({ total = 0, justify = "center", currency }) => {
  const classes = useStyles();
  const { t } = useTranslation(["registrationForm"]);
  const { moneyFormatter } = useRegistrationFormUtils();

  return (
    <GridItem container justify={justify}>
      <h4 className={classes.moneyTitle}>
        {`${t("total")}: ${moneyFormatter(currency)(total)}`}
      </h4>
    </GridItem>
  );
};
