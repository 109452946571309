import React from "react";

import style from "./ContainerFullHeightStyle";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(style);
export default function ContainerFullHeight(props) {
  const { children, alignItems, paddingTop, paddingBottom, bgColor } = props;
  const classes = useStyles();
  const paddingTopDefault = "40px";
  const paddingBottomDefault = "80px";
  const getHeaderClass = () => {
    if(bgColor === 'white') return classes.pageHeaderWhite
    if(bgColor === 'beige') return classes.pageHeaderBeige
    return classes.pageHeader
  }
  return (
    <div>
      <div
        className={getHeaderClass()}
        style={{
          alignItems: alignItems || "center",
          paddingTop: paddingTop || paddingTopDefault,
          paddingBottom: paddingBottom || paddingBottomDefault
        }}
      >
        {children}
      </div>
    </div>
  );
}
