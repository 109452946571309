import { types } from "types/statusTypes";

export const loadingListTrue = () => ({
  type: types.loadingListTrue,
});

export const loadingListFalse = () => ({
  type: types.loadingListFalse,
});

export const loadingMyListTrue = () => ({
  type: types.loadingMyListTrue,
});

export const loadingMyListFalse = () => ({
  type: types.loadingMyListFalse,
});

export const resetStatusState = () => ({
  type: types.reset,
});
