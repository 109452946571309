import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";
import {
  container,
  mrAuto,
  title,
  titleLanding,
  blackColor,
  whiteColor,
  hexToRgb,
  PrimaryFontColor,
  subtitleLandingMedium,
  subtitleLandingLight
} from "assets/jss/material-kit-pro-react.js";
import { SecondaryFontColor } from "assets/jss/material-kit-pro-react";
import { PrimaryFontColorLanding } from "assets/jss/material-kit-pro-react";

const headersSection = (theme) => ({
  ...headerLinksStyle(theme),
  sectionBlank: {
    height: "70px",
    display: "block",
  },
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    "& h1, & h2, & h4, & h6": {
      color: PrimaryFontColorLanding,
    },
  },
  conatinerHeader2: {
    ...container,
    zIndex: "2",
    position: "relative",
    "& h1, & h4, & h6": {
      color: PrimaryFontColorLanding,
    },
    paddingTop: "25vh",
  },
  title: {
    ...titleLanding,
    textAlign: "start",
    color: PrimaryFontColorLanding,
  },
  pageHeader: {
    position: "relative",
    height: "100vh",
    maxHeight: "1600px",
    backgroundPosition: "50%",
    backgroundSize: "cover",
    margin: "0",
    padding: "0",
    border: "0",
    display: "flex",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center",

    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''",
    },
  },
  iframeContainer: {
    marginTop: "0px",
    "& > iframe": {
      width: "100%",
      boxShadow:
        "0 16px 38px -12px rgba(" +
        hexToRgb(blackColor) +
        ", 0.56), 0 4px 25px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)",
    },
  },
  mrAuto,
  textCenter: {
    textAlign: "center",
  },
  card: {
    marginTop: "60px",
  },
  formControl: {
    margin: "0",
    padding: "8px 0 0 0",
  },
  textRight: {
    textAlign: "right",
  },
  button: {
    margin: "0 !important",
  },
  titleContainer: {
    backgroundPosition: "left",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    minHeight: "500px",
    justifyContent: "flex-end",
    paddingTop: "100px"
  },
  lightray: {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: "-200px",
    backgroundSize: "cover",
  },
  subtitle: {
    ...subtitleLandingMedium,
    textAlign: "start",
    color: PrimaryFontColorLanding,
    fontWeight: 600,
  },
  subtitleI: {
    ...subtitleLandingMedium,
    textAlign: "start",
    fontStyle: "italic",
    color: PrimaryFontColorLanding,
    fontWeight: 300,
  },
  houseContainer: {
    paddingTop: "100px",
  },
  houseTitle: {
    fontWeight: "700",
    color: PrimaryFontColorLanding,
    textAlign: "center",
  },
  scheduleContainer: {
    marginTop: "40px",
  },
  scheduleTitle: {
    marginBottom: "25px",
    fontWeight: "700",
    fontSize: "20px",
    color: "white",
    textAlign: "center",
    background: "#1c1e2287",
    padding: "15px",
  },
  navButton: {
    ...subtitleLandingMedium,
    textTransform: "none",
    minWidth: "300px",
    fontSize: "20px",
  },
  navButtonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  navButtonContainerSchedule: {
    display: "flex",
    justifyContent: "center",
    marginTop: "50px",
    marginBottom: "50px",
  },
  imgPortada: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
});

export default headersSection;
