import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { SummaryData } from "../components/SummaryData";
import { StepperButtons } from "../StepperButtons";
import { Box } from "@material-ui/core";

import Button from "components/CustomButtons/Button.js";
import { useTranslation } from "react-i18next";
import {
  ACTIVITY_GALA_DINNER,
  ACTIVITY_LOVE_IN_ACTION,
  ACTIVITY_NONE,
  ACTIVITY_PIRAMIDES,
  COMPANION_NO_COMPANION,
  STEP_ACCOMMODATION_INFO,
  STEP_ACTIVITY_INFO,
  STEP_ADDITIONAL_INFO,
  STEP_PAYMENT,
  STEP_PERSONAL_INFO,
  PACKAGE_CONVENTION_WITH_HOTEL,
  PACKAGE_NO_CONVENTION,
  PACKAGE_CONVENTION_WITHOUT_HOTEL,
  ACTIVITY_WORKSHOP,
} from "constants/registrationForm";
import { useRegistrationFormUtils } from "hooks/useRegistrationFormUtils";
import { CompanionShortData } from "../components/CompanionShortData";
import { useRegistrationFormCosts } from "hooks/useRegistrationFormCosts";
import { MoneyFormatter } from "../components/MoneyFormatter";
import { TotalMoney } from "../components/TotalMoney";
import { mapFormDataToTotalDTO } from "mappers/formMapper";
import SnackbarContent from "components/Snackbar/SnackbarContent";
import { useRegistrationForm } from "hooks/useRegistrationForm";
import { PAYMENT_TYPE_BANK } from "constants/registrationForm";
import { usePaypal } from "hooks/usePaypal";
import { PayPalButton } from "react-paypal-button-v2";
import { CONVENTION_CURRENCY } from "constants/registrationFormCosts";
import { PAYMENT_TYPE_PAYPAL } from "constants/registrationForm";
import { TITLE_NONE } from "constants/registrationForm";

const useStyles = makeStyles(basicsStyle);

export const Summary = ({ data, handlePrevious, onHandleSuccess }) => {
  const { t } = useTranslation(["registrationForm"]);
  const {
    getStepName,
    getActivityLabel,
    getCompanionLabel,
    getHotelLabel,
    getPackageLabel,
    getHonorTitleLabel,
  } = useRegistrationFormUtils();
  const {
    getActivityCostByPerson,
    getHotelCost,
    getHotelExtraNightCostPerNight,
    getCompanionCost,
    getJustConventionCosts,
    getGlobalTotal,
  } = useRegistrationFormCosts();
  const { submitForm, loading, error, registration } = useRegistrationForm();
  const classes = useStyles();
  const { createOrder, onError, onApprove, options } = usePaypal();

  const onBankPayment = async () => {
    const resp = await submitForm(data, PAYMENT_TYPE_BANK);
    onHandleSuccess(resp);
  };

  const onPaypalSuccess = async (details) => {
    const resp = await submitForm(data, PAYMENT_TYPE_PAYPAL, details);
    onHandleSuccess(resp);
  };

  const onPaypalApprove = (data, actions) => {
    onApprove(data, actions, onPaypalSuccess);
  };

  const createPaypalOrder = (paypalData, actions) => {
    return createOrder(
      paypalData,
      actions,
      CONVENTION_CURRENCY,
      getGlobalTotal(mapFormDataToTotalDTO(data))
    );
  };
  return (
    <div>
      <GridContainer style={{ marginBottom: "80px" }}>
        <GridItem sm={12}>
          <h3> {getStepName(STEP_PAYMENT)}:</h3>
          <h4 className={classes.registrationTitle}>
            {getStepName(STEP_PERSONAL_INFO)}
          </h4>
          <GridItem sm={12}>
            <SummaryData
              label={t("formName")}
              value={
                data[STEP_PERSONAL_INFO]?.honorTitle !== TITLE_NONE
                  ? getHonorTitleLabel(data[STEP_PERSONAL_INFO]?.honorTitle) +
                    " " +
                    data[STEP_PERSONAL_INFO]?.name
                  : data[STEP_PERSONAL_INFO]?.name
              }
            />
            <SummaryData
              label={t("formEmail")}
              value={data[STEP_PERSONAL_INFO]?.email} //TODO: Agregar email en el paso 1
            />
            <SummaryData
              label={t("formPhone")}
              value={data[STEP_PERSONAL_INFO]?.phone}
            />
            <SummaryData
              label={t("formCity")}
              value={data[STEP_PERSONAL_INFO]?.city}
            />
            <SummaryData
              label={t("permissionTitle")}
              value={t(data[STEP_ADDITIONAL_INFO]?.hasGivenPermissions)}
            />
          </GridItem>

          <h4 className={classes.registrationTitle}>
            {t("emergencyContactTitle")}
          </h4>
          <GridItem sm={12}>
            <SummaryData
              label={t("formName")}
              value={data[STEP_PERSONAL_INFO]?.emergencyContactName}
            />
            <SummaryData
              label={t("formEmail")}
              value={data[STEP_PERSONAL_INFO]?.emergencyContactEmail}
            />
            <SummaryData
              label={t("formPhone")}
              value={data[STEP_PERSONAL_INFO]?.emergencyContactPhone}
            />
            <SummaryData
              label={t("formRelation")}
              value={data[STEP_PERSONAL_INFO]?.emergencyContactRelation}
            />
          </GridItem>
        </GridItem>
        <GridItem sm={12}>
          <h4 className={classes.registrationTitle}>
            {getStepName(STEP_ACCOMMODATION_INFO)}:{" "}
            {getPackageLabel(data[STEP_ACCOMMODATION_INFO]?.package)}
          </h4>
          {data[STEP_ACCOMMODATION_INFO]?.package ===
            PACKAGE_CONVENTION_WITH_HOTEL && (
            <GridItem sm={12}>
              <SummaryData
                label={t("hotelTitle")}
                value={getHotelLabel(
                  data[STEP_ACCOMMODATION_INFO]?.hotelOption
                )}
                cost={getHotelCost(data[STEP_ACCOMMODATION_INFO]?.hotelOption)}
              />
              <SummaryData
                label={t("numberOfNightsBefore")}
                value={data[STEP_ACCOMMODATION_INFO]?.hotelNightsBefore}
                cost={getHotelExtraNightCostPerNight(
                  data[STEP_ACCOMMODATION_INFO]?.hotelOption,
                  data[STEP_ACCOMMODATION_INFO]?.hotelNightsBefore
                )}
              />
              <SummaryData
                label={t("numberOfNightsAfter")}
                value={data[STEP_ACCOMMODATION_INFO]?.hotelNightsAfter}
                cost={getHotelExtraNightCostPerNight(
                  data[STEP_ACCOMMODATION_INFO]?.hotelOption,
                  data[STEP_ACCOMMODATION_INFO]?.hotelNightsAfter
                )}
              />
              <SummaryData
                label={t("companionTitle")}
                value={getCompanionLabel(
                  data[STEP_ACCOMMODATION_INFO]?.hasCompanion
                )}
                cost={getCompanionCost(
                  data[STEP_ACCOMMODATION_INFO]?.hasCompanion,
                  data[STEP_ACCOMMODATION_INFO]?.hotelOption,
                  data[STEP_ACCOMMODATION_INFO]?.hotelNightsBefore,
                  data[STEP_ACCOMMODATION_INFO]?.hotelNightsAfter
                )}
              />
              {data[STEP_ACCOMMODATION_INFO]?.hasCompanion !==
                COMPANION_NO_COMPANION && (
                <CompanionShortData
                  name={data[STEP_ACCOMMODATION_INFO].companion_name_1}
                  email={data[STEP_ACCOMMODATION_INFO].companion_email_1}
                  phone={data[STEP_ACCOMMODATION_INFO].companion_phone_1}
                  city={data[STEP_ACCOMMODATION_INFO].companion_city_1}
                />
              )}
            </GridItem>
          )}

          {data[STEP_ACCOMMODATION_INFO]?.package ===
            PACKAGE_CONVENTION_WITHOUT_HOTEL && (
            <GridItem sm={12}>
              <SummaryData
                label={t("people")}
                value={
                  data[STEP_ACCOMMODATION_INFO]?.justConventionPeopleCount === 1
                    ? t("justMe")
                    : data[STEP_ACCOMMODATION_INFO]?.justConventionPeopleCount +
                      " " +
                      t("people")
                }
                cost={getJustConventionCosts(
                  data[STEP_ACCOMMODATION_INFO]?.justConventionPeopleCount
                )}
              />
              {data[STEP_ACCOMMODATION_INFO]?.justConventionPeopleCount > 0 &&
                [1, 2, 3, 4]
                  .slice(
                    0,
                    data[STEP_ACCOMMODATION_INFO]?.justConventionPeopleCount - 1
                  )
                  .map((el) => (
                    <CompanionShortData
                      key={el}
                      name={data[STEP_ACCOMMODATION_INFO]["guest_name_" + el]}
                      email={data[STEP_ACCOMMODATION_INFO]["guest_email_" + el]}
                      phone={data[STEP_ACCOMMODATION_INFO]["guest_phone_" + el]}
                      city={data[STEP_ACCOMMODATION_INFO]["guest_city_" + el]}
                    />
                  ))}
            </GridItem>
          )}
          {data[STEP_ACCOMMODATION_INFO]?.package === PACKAGE_NO_CONVENTION && (
            <GridItem sm={12}>
              <SummaryData
                label={t("people")}
                value={
                  data[STEP_ACCOMMODATION_INFO]?.noConventionPeopleCount === 1
                    ? t("justMe")
                    : data[STEP_ACCOMMODATION_INFO]?.noConventionPeopleCount +
                      " " +
                      t("people")
                }
              />
              {data[STEP_ACCOMMODATION_INFO]?.noConventionPeopleCount > 0 &&
                [1, 2, 3, 4]
                  .slice(
                    0,
                    data[STEP_ACCOMMODATION_INFO]?.noConventionPeopleCount - 1
                  )
                  .map((el) => (
                    <CompanionShortData
                      key={el}
                      name={data[STEP_ACCOMMODATION_INFO]["guest_name_" + el]}
                      email={data[STEP_ACCOMMODATION_INFO]["guest_email_" + el]}
                      phone={data[STEP_ACCOMMODATION_INFO]["guest_phone_" + el]}
                      city={data[STEP_ACCOMMODATION_INFO]["guest_city_" + el]}
                    />
                  ))}
            </GridItem>
          )}
        </GridItem>
        <GridItem sm={12}>
          <h4 className={classes.registrationTitle}>
            {getStepName(STEP_ACTIVITY_INFO)}
          </h4>
          <GridItem sm={12}>
            {!!data[STEP_ACTIVITY_INFO]?.workshopPeopleCount && (
              <SummaryData
                label={getActivityLabel(ACTIVITY_WORKSHOP)}
                value={data[STEP_ACTIVITY_INFO]?.workshopPeopleCount}
                arePeople
                cost={getActivityCostByPerson(
                  ACTIVITY_WORKSHOP,
                  data[STEP_ACTIVITY_INFO]?.workshopPeopleCount
                )}
              />
            )}
            {data[STEP_ACTIVITY_INFO]?.activityDay1 !== ACTIVITY_NONE && (
              <SummaryData
                label={getActivityLabel(data[STEP_ACTIVITY_INFO]?.activityDay1)}
                value={data[STEP_ACTIVITY_INFO]?.excursionPeopleCount}
                arePeople
                cost={getActivityCostByPerson(
                  data[STEP_ACTIVITY_INFO]?.activityDay1,
                  data[STEP_ACTIVITY_INFO]?.excursionPeopleCount
                )}
              />
            )}
            {!!data[STEP_ACTIVITY_INFO]?.galaDinnerPeopleCount && (
              <SummaryData
                label={getActivityLabel(ACTIVITY_GALA_DINNER)}
                value={data[STEP_ACTIVITY_INFO]?.galaDinnerPeopleCount}
                arePeople
                cost={getActivityCostByPerson(
                  ACTIVITY_GALA_DINNER,
                  data[STEP_ACTIVITY_INFO]?.galaDinnerPeopleCount
                )}
              />
            )}
            {!!data[STEP_ACTIVITY_INFO]?.piramidePeopleCount && (
              <SummaryData
                label={getActivityLabel(ACTIVITY_PIRAMIDES)}
                value={data[STEP_ACTIVITY_INFO]?.piramidePeopleCount}
                arePeople
                cost={getActivityCostByPerson(
                  ACTIVITY_PIRAMIDES,
                  data[STEP_ACTIVITY_INFO]?.piramidePeopleCount
                )}
              />
            )}
          </GridItem>
        </GridItem>
        {(data[STEP_ADDITIONAL_INFO]?.donationAmount ||
          data[STEP_ADDITIONAL_INFO]?.donationAmount === 0) && (
          <GridItem sm={12}>
            <Box className={classes.dFlexSpaceBetween}>
              <h4 className={classes.registrationTitle}>
                {t("donationTitle")}
              </h4>
              <Box style={{ marginRight: "15px" }}>
                <MoneyFormatter
                  value={parseFloat(data[STEP_ADDITIONAL_INFO]?.donationAmount)}
                />
              </Box>
            </Box>
          </GridItem>
        )}
        {data[STEP_ADDITIONAL_INFO]?.comments && (
          <GridItem sm={12}>
            <SummaryData
              label={t("commentTitle")}
              value={data[STEP_ADDITIONAL_INFO]?.comments}
            />
          </GridItem>
        )}
        <TotalMoney
          justify="flex-end"
          total={getGlobalTotal(mapFormDataToTotalDTO(data))}
        />
        <GridContainer xs="12" justify="flex-end">
          <StepperButtons hasBackButton handleBack={handlePrevious} />
        </GridContainer>
        <br />
        <GridItem xs="12" style={{ marginTop: "20px" }}>
          <SnackbarContent
            message={
              <span>
                <b>{t("paymentOptionsTitle")}</b>
              </span>
            }
            color="success"
            icon="paid"
          />
        </GridItem>
        <GridItem xs={12} md={6} style={{ marginBottom: "20px" }}>
          <h4 className={classes.registrationTitle}>
            {t("paymentOptionBankTitle")}
          </h4>
          <Box>{t("paymentOptionBankDescription")}</Box>
          <br />
          <GridItem className={classes.dFlexCenter}>
            <Button
              variant="contained"
              color="success"
              disable={loading}
              onClick={onBankPayment}
            >
              {t("paymentOptionBankButton")}
            </Button>
          </GridItem>
        </GridItem>
        <GridItem xs={12} md={6} style={{ marginBottom: "20px" }}>
          <h4 className={classes.registrationTitle}>
            {t("paymentOptionPaypalTitle")}
          </h4>
          <Box>{t("paymentOptionPaypalDescription")}</Box>
          <br />
          <GridItem className={classes.dFlexCenter}>
            <PayPalButton
              disableFunding="true"
              disableCard="true"
              components="buttons"
              createOrder={createPaypalOrder}
              onError={onError}
              onApprove={onPaypalApprove}
              shippingPreference="NO_SHIPPING"
              options={options}
            />
          </GridItem>
        </GridItem>
      </GridContainer>
    </div>
  );
};
