import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import lago from "assets/img/convention/Lago Chapala.jpg";
import idioma from "assets/img/convention/InfoSectionIcons/Idioma.png";
import moneda from "assets/img/convention/InfoSectionIcons/Moneda.png";
import ropa from "assets/img/convention/InfoSectionIcons/Ropa.png";
import taxi from "assets/img/convention/InfoSectionIcons/Taxi.png";
import uber from "assets/img/convention/InfoSectionIcons/Uber.png";
import ubicacion from "assets/img/convention/InfoSectionIcons/Ubicación.png";

import style from "./InfoSectionStyle";

import { makeStyles } from "@material-ui/core/styles";
import ContainerFullHeight from "components/ContainerFullHeight/ContainerFullHeight";
import { SecondaryFontColor } from "assets/jss/material-kit-pro-react.js";
import { useTranslation } from "react-i18next";
import { Paragraph } from "components/Paragraph/Paragraph";
import Subtitle from "components/Subtitle/Subtitle";
import Button from "components/CustomButtons/Button.js";
import { ParagraphLanding } from "components/ParagraphLanding/ParagraphLanding";

const useStyles = makeStyles(style);

export default function InfoSectionChapala({ id, t }) {
  const { i18n } = useTranslation(["homePage"]);
  const classes = useStyles();
  const excursionSpanishURL =
    "https://sites.google.com/view/convenciondelasamericas/pagina-principal";
  const excursionEnglishURL =
    "https://sites.google.com/view/conventionoftheamericas/home";

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <ContainerFullHeight
      alignItems="start"
      bgColor="white"
      paddingBottom="200px"
      paddingTop="100px"
    >
      <div className={classes.container} id={id}>
        <GridContainer className={classes.titleContainer}>
          <div className={classes.beigeContainer}>
            <GridItem xs={12}>
              <h2 className={classes.title}>{t("jaliscoTitle")}</h2>
              <h4 className={classes.subtitle}>{t("jaliscoShortText")}</h4>
              <br />
            </GridItem>
            <GridItem xs={12} md={12}>
              {/* <Card>
                <Carousel {...settings}>
                  <div>
                    <img
                      src={jalisco}
                      alt="First slide"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h4>
                        <LocationOn className="slick-icons" />
                        Yellowstone National Park, United States
                      </h4>
                    </div>
                  </div>
                  <div>
                    <img
                      src={lago}
                      alt="Second slide"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h4>
                        <LocationOn className="slick-icons" />
                        Somewhere Beyond, United States
                      </h4>
                    </div>
                  </div>
                  <div>
                    <img
                      src={image3}
                      alt="Third slide"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h4>
                        <LocationOn className="slick-icons" />
                        Yellowstone National Park, United States
                      </h4>
                    </div>
                  </div>
                </Carousel>
              </Card> */}
              <img src={lago} width={"100%"} alt="..." />
            </GridItem>

            <GridItem className={classes.text} xs={12} md={12}>
              <ParagraphLanding text={t("jaliscoDescription1")} />
              <ParagraphLanding text={t("jaliscoDescription2")} />
            </GridItem>
          </div>

          {/*} <GridItem className={classes.navButtonContainer}>
            <Button
              color="info"
              size="lg"
              href={
                i18n.language === "es"
                  ? excursionSpanishURL
                  : excursionEnglishURL
              }
              target="blank"
              className={classes.navButton}
            >
              {t("excursion")}
            </Button>
            </GridItem>*/}

          {/*<GridItem>
            <Subtitle subtitle={t("covidTitle")} />
            <Paragraph text={t("infoCovid")} />
            </GridItem>*/}
          <GridItem className={classes.text}>
            <div className={classes.textContainer}>
              <img
                src={ubicacion}
                className={classes.image}
                alt="location icon"
              />
              <div className={classes.content}>
                <Subtitle subtitle={t("howToGetTitle")} />
                <ParagraphLanding text={t("howToGetSubtitle")} />
              </div>
            </div>
          </GridItem>
          <GridItem className={classes.text}>
            <div className={classes.textContainer}>
              <img src={taxi} className={classes.image} alt="taxi icon" />
              <div className={classes.content}>
                <Subtitle subtitle={t("taxiTitle")} />
                <ParagraphLanding text={t("taxiSubtitle")} />
              </div>
            </div>
          </GridItem>
          <GridItem className={classes.text}>
            <div className={classes.textContainer}>
              <img src={moneda} className={classes.image} alt="money icon" />
              <div className={classes.content}>
                <Subtitle subtitle={t("moneyTitle")} />
                <ParagraphLanding text={t("moneySubtitle")} />
              </div>
            </div>
          </GridItem>
          <GridItem className={classes.text}>
            <div className={classes.textContainer}>
              <img src={ropa} className={classes.image} alt="clothes icon" />
              <div className={classes.content}>
                <Subtitle subtitle={t("weatherTitle")} />
                <ParagraphLanding text={t("weatherSubtitle")} />
              </div>
            </div>
          </GridItem>
          <GridItem className={classes.text}>
            <div className={classes.textContainer}>
              <img src={idioma} className={classes.image} alt="language icon" />
              <div className={classes.content}>
                <Subtitle subtitle={t("languageTitle")} />
                <ParagraphLanding text={t("languageSubtitle")} />
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </ContainerFullHeight>
  );
}
