/*eslint-disable*/
import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
// sections for this page
import sectionsPageStyle from "assets/jss/material-kit-pro-react/views/sectionsPageStyle.js";
import MainSection from "./Sections/MainSection/MainSection";
import DatesSection from "./Sections/DatesSection/DatesSection";
import InfoSection from "./Sections/InfoSection/InfoSection";
import InfoSectionChapala from "./Sections/InfoSectionChapala/InfoSectionChapala";

import SpeakerSection from "./Sections/SpeakerSection/SpeakerSection";
import CarouselSection from "./Sections/CarouselSection/CarouselSection";

import Footer from "components/Footer/Footer";
import ScrollList from "components/ScrollList/ScrollList";
import MinistrySection from "./Sections/MinistrySection/MinistrySection";

const useStyles = makeStyles(sectionsPageStyle);

export default function PresentationPage() {
  const { t } = useTranslation(["homePage"]);
  const sections = ["main", "info", "speakers", "ministry"];

  const classes = useStyles();
  return (
    <div>
      <Header fixed />
      <div className={classes.main}>
        <CarouselSection />
        <div id="main">
          <MainSection t={t} />
        </div>
        {/*         <div id="dates">
          <DatesSection t={t} />
        </div> */}
        <div id="info">
          <InfoSectionChapala t={t} />
        </div>
        <div id="speakers">
          <SpeakerSection t={t} />
        </div>
        <div id="ministry">
          <MinistrySection t={t} />
        </div>
      </div>
      <ScrollList scrolls={sections} translate={t} />
      <Footer />
    </div>
  );
}
