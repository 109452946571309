import React from "react";
import { Provider } from "react-redux";
import { store } from "store/store.js";
import AppRouter from "routers/AppRouter";

// pages for this product

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";

export default function App() {
  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
}
