import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import Info from "components/Typography/Info.js";
import style from "./VerticalCourseStyle.js";
import classNames from "classnames";
import bg5 from "assets/img/bg5.jpg";

const useStyles = makeStyles(style);

const VerticalCourse = ({courseProps}) => {
  const classes = useStyles();
  const { title, imageUrl, description, price, discountPrice } = courseProps;
  return (
    <Card plain blog>
      <CardHeader image plain>
        <a href="#pablo">
          <img src={bg5} alt="..." />
        </a>
        <div
          className={classes.coloredShadow}
          style={{
            backgroundImage: "url(" + { imageUrl } + ")",
            opacity: "1",
          }}
        />
      </CardHeader>
      <CardBody plain>
        <Info>
          <h6>ENTERPRISE</h6>
        </Info>
        {title && <h4 className={classes.cardTitle}>{title}</h4>}
        {description && (
          <p className={classes.description}>
            {description}
            <a href="#pablo"> Read More </a>
          </p>
        )}
      </CardBody>

      <CardFooter plain>
        <div className={classes.priceContainer}>
          <span className={classNames(classes.price, classes.priceOld)}>
            {" "}
            ${price}
          </span>
          {discountPrice && (
            <span className={classNames(classes.price, classes.priceNew)}>
              {" "}
              ${discountPrice}
            </span>
          )}
        </div>
      </CardFooter>
    </Card>
  );
};

export default VerticalCourse;
