import GridItem from "components/Grid/GridItem";
import { PACKAGE_CONVENTION_WITH_HOTEL } from "constants/registrationForm";
import { useRegistrationFormUtils } from "hooks/useRegistrationFormUtils";
import React from "react";
import { useTranslation } from "react-i18next";

export const WhatIsIncluded = ({ selectedPackage, title }) => {
  const { t } = useTranslation(["registrationForm"]);
  const { includedList } = useRegistrationFormUtils();

  let includeListFirst = [];
  let titleKey = "includedTitle";

  if (selectedPackage === PACKAGE_CONVENTION_WITH_HOTEL) {
    includeListFirst = [t("hotelIncludeList")];
    titleKey = "hotelIncludedTitle";
  }

  const titleText = title || t(titleKey);

  const includeListMap = [...includeListFirst, ...includedList];
  return (
    <GridItem xs={12}>
      <h5>
        <strong>{titleText}</strong>
      </h5>
      <ul>
        {includeListMap.map((text, index) => (
          <li key={index}>{text}</li>
        ))}
      </ul>
    </GridItem>
  );
};
