import { main } from "assets/jss/material-kit-pro-react.js";
import footerStyle from "assets/jss/material-kit-pro-react/views/componentsSections/footerStyle.js";
const sectionsPageStyle = {
  ...footerStyle,
  main: {
    ...main,
  },
};

export default sectionsPageStyle;
