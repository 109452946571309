import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { RegistrationRow } from "./RegistrationRow";
import { useRegistrationFormUtils } from "hooks/useRegistrationFormUtils";
import {
  TABLE_TITLE_DATE,
  TABLE_TITLE_NAME,
  TABLE_TITLE_PACKAGE,
  TABLE_TITLE_EXTRANIGHTS,
  TABLE_TITLE_GALADINNER,
  TABLE_TITLE_THURSDAYACTIVITY,
  TABLE_TITLE_PIRAMIDES,
  TABLE_TITLE_DONATIONS,
  TABLE_TITLE_COMMENTS,
  TABLE_TITLE_TOTAL,
  TABLE_TITLE_PEOPLE,
  TABLE_TITLE_PAYMENTMETHOD,
} from "constants/registrationForm";

export const CollapsibleTable = ({ registrations }) => {
  const { getTableTitle } = useRegistrationFormUtils();
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="right">
              {getTableTitle(TABLE_TITLE_DATE)}
            </TableCell>
            <TableCell align="right">
              {getTableTitle(TABLE_TITLE_NAME)}
            </TableCell>
            <TableCell align="right">
              {getTableTitle(TABLE_TITLE_PACKAGE)}
            </TableCell>
            <TableCell align="right">
              {getTableTitle(TABLE_TITLE_EXTRANIGHTS)}
            </TableCell>
            <TableCell align="center">
              {getTableTitle(TABLE_TITLE_PEOPLE)}
            </TableCell>
            <TableCell align="right">
              {getTableTitle(TABLE_TITLE_GALADINNER)}
            </TableCell>
            <TableCell align="right">
              {getTableTitle(TABLE_TITLE_THURSDAYACTIVITY)}
            </TableCell>
            <TableCell align="right">
              {getTableTitle(TABLE_TITLE_PIRAMIDES)}
            </TableCell>
            <TableCell align="right">
              {getTableTitle(TABLE_TITLE_DONATIONS)}
            </TableCell>
            <TableCell align="right">
              {getTableTitle(TABLE_TITLE_COMMENTS)}
            </TableCell>
            <TableCell align="right">
              {getTableTitle(TABLE_TITLE_TOTAL)}
            </TableCell>
            <TableCell align="center">
              {" "}
              {getTableTitle(TABLE_TITLE_PAYMENTMETHOD)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {registrations.map((row, index) => (
            <RegistrationRow key={index} data={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
