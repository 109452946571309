import imgKathianneLewis from "assets/img/convention/oradores/kathianne_Lewis-23.jpeg";
import imgMaureenJones from "assets/img/convention/oradores/RevMaureenJones.jpeg";
import imgRebekaPina from "assets/img/convention/oradores/Rebeka_foto.jpeg";
import imgTimSchubert from "assets/img/convention/oradores/Tim.jpeg";
import imgJeffreyRyan from "assets/img/convention/oradores/RevJeffreyRyan.png";

export default [
  {
    code: "martes_6",
    description: {
      es: "PRÓXIMAMENTE",
      en: "SOON",
    },
    speakers: [
      {
        name: "Kathianne Lewis",
        title: "Rev Dra",
        description: {
          en:
            "In her more than 30 years of service as Spiritual Director and Senior Minister at the Center for Spiritual Living, Rev. Dr. Kathianne Lewis has inspired and served countless numbers of people—whose lives, she hopes, have been changed for the better by practicing spiritual principles. Through her mission of Empowering people to live a thriving life, Rev. Kathianne leads worship services, classes and retreats, both locally and internationally, that engage, entertain, and most importantly, transform the lives of participants. Having been a spiritual seeker for most of her life, Rev. Kathianne offers practical spiritual tools for use in an often-complex world. As a leading scholar in New Thought philosophy, she brings into harmony those teachings with the insights that ancient and contemporary religious study offer. Rev. Kathianne was ordained by the United Centers for Spiritual Living, a national New Thought denomination, and received her Doctorate of Divinity from the same institution in 1995.",
          es:
            "En sus más de 30 años de servicio como Directora Espiritual y Ministra Principal en el Centro para la Vida Espiritual, la Rev. Dra. Kathianne Lewis ha inspirado y servido a innumerables personas, cuyas vidas, espera, hayan mejorado al practicar principios espirituales. A través de su misión de empoderar a las personas para que vivan una vida próspera, la Rev Dr Kathianne dirige servicios de adoración, clases y retiros, tanto a nivel local como internacional, que involucran, entretienen y, lo que es más importante, transforman la vida de los participantes. Habiendo sido una buscadora espiritual durante la mayor parte de su vida, la Rev. Kathianne ofrece herramientas espirituales prácticas para usar en un mundo a menudo complejo. Como destacada académica en la filosofía del Nuevo Pensamiento, armoniza esas enseñanzas con los conocimientos que ofrecen los estudios religiosos antiguos y contemporáneos. Fue ordenada por los Centros Unidos para la Vida Espiritual, una denominación nacional del Nuevo Pensamiento, y recibió su Doctorado en Divinidad de la misma institución en 1995.",
        },
        url: "https://www.spiritualliving.org/people/kathianne-lewis-dd/ ",
        img: imgKathianneLewis,
      },
      {
        name: "Jeffrey Ryan",
        title: "Rev",
        description: {
          en:
            "Rev. Jeffrey Ryan was a member of the Centers for Spiritual Living Visioning Team. For him, Visioning is much more than an occasional spiritual tool to use to gain clarity or guidance about a project or a path forward, it is a way of life. He is committed to sharing this transformative process with as many people as possible. He regularly facilitates Visioning sessions and workshops within CSL as well as for secular, interfaith, and business organizations.",
          es:
            "El Rev. Jeffrey Ryan fue miembro del Equipo de Sintonización de los Centros para la Vida Espiritual. Para él, la Sintonización es mucho más que una herramienta espiritual que se usa ocasionalmente para obtener claridad u orientación sobre un proyecto o un camino a seguir, es una forma de vida. Está comprometido a compartir este proceso transformador con tantas personas como sea posible. Regularmente facilita sesiones y talleres de Sintonización dentro de CSL, así como para organizaciones de todo tipo, interreligiosas y comerciales.",
        },
        url:
          "https://csl.org/spiritual-development/online-education/online-instructors/jeffrey-ryan/",
        img: imgJeffreyRyan,
      },
      {
        name: "Tim Schubert",
        title: "Rev",
        description: {
          en:
            "Rev Tim founded the Center for Spiritual Living in 2009 when he moved here from Tacoma WA where he served as a staff minister. His journey includes graduating Stanford Medical School, being a medical researcher and professor, immersion in the 12 Steps and human potential movement and finding Science of Mind in 1995. He loves groups for support and mirroring what each participant needs to realize. He is enthusiastic about Toastmasters, Duolingo, hiking, biking and tennis.",
          es:
            "El Rev Tim fundó el Centro para la Vida Espiritual en 2009 cuando se mudó aquí desde Tacoma WA, donde se desempeñó como ministro de personal. Su viaje incluye graduarse de la Escuela de Medicina de Stanford, ser investigador médico y profesor, sumergirse en los 12 Pasos y el movimiento del potencial humano y encontrar la Ciencia de la Mente en 1995. Le encantan los grupos de apoyo y reflejan lo que cada participante necesita realizar. Le entusiasman los Toastmasters, Duolingo, el senderismo, el ciclismo y el tenis.",
        },
        url:
          "http://www.cslchapala.com/",
        img: imgTimSchubert,
      },
      {
        name: "Rebeka Pina",
        title: "Rev",
        description: {
          en:
            "Born in Aguascalientes, Mexico Rebeka moved to Canada in 1968. in 1995 she moved to Huntington Beach, CA, to attend the School of Ministry. In 1998 she took the teachings of SOM to the Spanish speaking world, founding the first Ciencia de la Mente Center in Mexico City. She oversaw the translation of all the CSL curricula to start training Practitioners. She “retired” in 2010 and went back to Canada, but her Online ministries started to grow and in 2014 she returned to Mexico and began the yearly Convention of the Americas in Chapala. In 2015, he founded the School of Spiritual Leadership, graduating for the first time in the Hispanic community in 2021 and 2022, 14 Spiritual Directors.",
          es:
            "Nacida en Aguascalientes, México, Rebeka se trasladó a Canadá en 1968. En 1995 se mudó a Huntington Beach, CA, para asistir a la Escuela Ministerial. En 1998 llevó las enseñanzas de  Ciencia de la Mente al mundo de habla hispana, fundando el primer Centro de Ciencia de la Mente en la Ciudad de México. Supervisando también la traducción de todos los planes de estudios de CSL para comenzar a capacitar a los practicantes. Se “retiró” en 2010 y regresó a Canadá, pero sus ministerios en línea comenzaron a crecer y en 2014 regresó a México dando inicio a un gran proyecto  la Convención anual de las Américas en Chapala. En 2015 Fundó  la Escuela de Liderazgo Espiritual graduando por primera vez en la comunidad hispana en el 2021 y 2022 a 14 Directores espirituales.",
        },
        url:
          "https://www.facebook.com/rebeka.pinaalonso",
        img: imgRebekaPina,
      },
      {
        name: "Maureen Jones",
        title: "Rev",
        description: {
          en:
            "Maureen became associated with the Center for Spiritual Living Chapala in 2010 when she retired to Ajijic, Jalisco in Mexico. Before retirement she led a spiritual center in Dallas, Texas. Spiritual Mind Treatment, meditation and sacred channeling in the Christ consciousness were the three main practices at the Center. Jones invites you to experience the great qualities of the divine within by learning more about mystical powers that are within each individual. If you feel inspired by Maureen Jones you can prayer support, schedule a spiritual counseling session and/or attend classes to learn about New Thought. You may reach Maureen Jones at:",
          es:
            "Maureen se incorporó al Centro para la Vida Espiritual del Lago Chapala en 2010 cuando se retiró a Ajijic, Jalisco en México. Antes de jubilarse dirigió un centro espiritual en Dallas, Texas. El tratamiento Espiritual Mental, la meditación y la canalización sagrada en la conciencia de Cristo fueron las tres prácticas principales en esel Centro. Maureen siempre te  invita a experimentar las grandes cualidades de lo divino interior aprendiendo más sobre los poderes místicos que hay dentro de cada individuo. Si te sientes inspirado por Maureen Jones puedes solicitar apoyo en oración, programar una sesión de asesoria espiritual y/o asistir a clases para aprender sobre el Nuevo Pensamiento comunicate a:",
        },
        url:
          "https://mail.google.com/mail/?view=cm&to=maureennkarlos0601@live.com",
        img: imgMaureenJones,
      },
    ],
  },
];
