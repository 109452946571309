import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";
import blogsStyle from "./CourseDetailSectionStyle";
import { Stream } from "@cloudflare/stream-react";
import Button from "components/CustomButtons/Button.js";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

//import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js";

const useStyles = makeStyles(blogsStyle);

export default function CourseDetailSection({ courseData, ...rest }) {
  const { title, description, speakers } = courseData?.data;
  const videoIdOrSignedUrl = courseData.signedkey;
  const classes = useStyles();
  const { t } = useTranslation(["common"]);

  return (
    <div className="cd-section" {...rest}>
      <div className={classes.blog}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <br />
              <h2 className={classes.title}>{title}</h2>
              <hr></hr>
              <Card plain blog className={classes.card4}>
                <CardHeader image plain>
                  <Stream
                    controls
                    width="720px"
                    height="480px"
                    src={videoIdOrSignedUrl}
                  />
                </CardHeader>
                <CardBody plain>
                  <Info className={classes.courseAuthor}>
                    {speakers.map((speaker, index) => (
                      <h6 key={index}>{speaker}</h6>
                    ))}
                  </Info>
                  <h5 className={classes.description}>{description}</h5>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem
              xs={12}
              className={
                classes.mlAuto +
                " " +
                classes.mrAuto +
                " " +
                classes.moreVideosButton
              }
            >
              <Link to="/courses">
                <Button color="danger" size="lg">
                  {t("watch-more-videos")}
                </Button>
              </Link>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
