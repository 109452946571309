import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
// sections of this Page
import coursesPageStyle from "styles/pages/course/courseStyle.js";
import { RegisterContainer } from "./Sections/RegisterContainer";

const useStyles = makeStyles(coursesPageStyle);

export function RegisterPage({ isLogged }) {
  const classes = useStyles();
  return (
    <div>
      <Header color="primary" />
      <div className={classes.main + " " + classes.fullHeight}>
        <RegisterContainer />
      </div>
    </div>
  );
}
