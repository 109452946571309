/*eslint-disable*/
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useForm } from "react-hook-form";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import signupPageStyle from "./SignupPageStyle.js";

import image from "assets/img/convention/beach-1.jpg";
import { startRegisterWithEmailPasswordName } from "actions/auth";
import { useDispatch } from "react-redux";
import ImageLogin from "components/ImageLogin/ImageLogin";
import { startGoogleLogin } from "actions/auth";
import CustomInput from "components/CustomInput/CustomInput";
import { LoginError } from "components/LoginForm/LoginError.jsx";

const useStyles = makeStyles(signupPageStyle);

export default function SignUpPage({ location, ...rest }) {
  const { t } = useTranslation(["singUp"]);
  const dispatch = useDispatch();

  const [checked, setChecked] = React.useState([1]);
  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);
  const classes = useStyles();

  const sucErrorDataTemplate = {
    name: null,
    email: null,
    password1: null,
    password2: null,
  };

  const [formSucErrorData, setformSucErrorData] = useState({
    formError: sucErrorDataTemplate,
    formSuccess: sucErrorDataTemplate,
  });

  let { formSuccess, formError } = formSucErrorData;

  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    const { name, email, password1, password2 } = data;
    if (isFormValid(data)) {
      dispatch(startRegisterWithEmailPasswordName(email, password1, name));
    }
  };

  const isFormValid = ({ name, email, password1, password2 }) => {
    const hasName = name;
    const hasEmail = email;
    const hasValidPassword1 = password1.length >= 6;
    const passwordsMatch = password1 && password1 == password2;

    setSuccessError(hasName, "name", t("nameRequired"));
    setSuccessError(hasEmail, "email", t("emailRequired"));
    setSuccessError(hasValidPassword1, "password1", t("minLengthPass"));
    setSuccessError(passwordsMatch, "password2", t("passDoNotMatch"));

    setformSucErrorData({ formError, formSuccess });
    return hasName && hasEmail, hasValidPassword1, passwordsMatch;
  };

  const setSuccessError = (succeeded, variable, msg) => {
    if (succeeded) {
      formSuccess = { ...formSuccess, [variable]: true };
      formError = {
        ...formError,
        [variable]: null,
      };
    } else {
      formSuccess = { ...formSuccess, [variable]: false };
      formError = {
        ...formError,
        [variable]: msg,
      };
    }
  };

  const handleGoogleLogin = (e) => {
    e.preventDefault();
    dispatch(startGoogleLogin());
  };

  return (
    <div>
      <Header />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={8} md={8} className={classes.cardContainer}>
              <Card className={classes.cardSignup}>
                <ImageLogin />
                <h2 className={classes.cardTitle}>{t("Register")}</h2>
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem xs={12}>
                      <div className={classes.textCenter}>
                        <Button
                          color="signUpGoogle"
                          onClick={handleGoogleLogin}
                        >
                          <i
                            className="fab fa-google"
                            style={{ marginRight: "15px" }}
                          />
                          {t("signUpWithGoogle")}
                        </Button>

                        <h4 className={classes.socialTitle}>
                          {t("Or Be Classical")}
                        </h4>
                      </div>
                      <form
                        className={classes.form}
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <LoginError />
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          register={register}
                          error={formError.name}
                          success={formSuccess.name}
                          inputProps={{
                            name: "name",
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Face className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            placeholder: t("Name"),
                          }}
                        />
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          register={register}
                          error={formError.email}
                          success={formSuccess.email}
                          inputProps={{
                            name: "email",
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Email className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            placeholder: t("Email"),
                          }}
                        />
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          register={register}
                          error={formError.password1}
                          success={formSuccess.password1}
                          inputProps={{
                            name: "password1",
                            type: "password",
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Icon className={classes.inputAdornmentIcon}>
                                  lock_outline
                                </Icon>
                              </InputAdornment>
                            ),
                            placeholder: t("Password"),
                          }}
                        />
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          register={register}
                          error={formError.password2}
                          success={formSuccess.password2}
                          inputProps={{
                            name: "password2",
                            type: "password",
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Icon className={classes.inputAdornmentIcon}>
                                  lock_outline
                                </Icon>
                              </InputAdornment>
                            ),
                            placeholder: t("Confirm Password"),
                          }}
                        />
                        {/* <FormControlLabel
                          classes={{
                            label: classes.label,
                          }}
                          control={
                            <Checkbox
                              tabIndex={-1}
                              onClick={() => handleToggle(1)}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                              checked={checked.indexOf(1) !== -1 ? true : false}
                            />
                          }
                          label={
                            <span>
                              I agree to the{" "}
                              <a href="#pablo">terms and conditions</a>.
                            </span>
                          }
                        /> */}
                        <div className={classes.textCenter}>
                          <Button round color="primary" type="submit">
                            {t("Get Started")}
                          </Button>
                        </div>
                      </form>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        {/* <Footer
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="https://www.creative-tim.com/?ref=mkpr-signup"
                      target="_blank"
                      className={classes.block}
                    >
                      Creative Tim
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="https://www.creative-tim.com/presentation?ref=mkpr-signup"
                      target="_blank"
                      className={classes.block}
                    >
                      About us
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="//blog.creative-tim.com/"
                      className={classes.block}
                    >
                      Blog
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="https://www.creative-tim.com/license?ref=mkpr-signup"
                      target="_blank"
                      className={classes.block}
                    >
                      Licenses
                    </a>
                  </ListItem>
                </List>
              </div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} , made with{" "}
                <Favorite className={classes.icon} /> by{" "}
                <a
                  href="https://www.creative-tim.com?ref=mkpr-signup"
                  target="_blank"
                >
                  Creative Tim
                </a>{" "}
                for a better web.
              </div>
            </div>
          }
        /> */}
      </div>
    </div>
  );
}
