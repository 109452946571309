import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import numbers from "assets/img/convention/numbers_2022.png";
import NumbersNov from "assets/img/convention/NumbersNov.png";
import Fechas2023 from "assets/img/convention/fechas2023.png";
import ConventionImg from "assets/img/convention/COTA2023.png"

import style from "./DatesSectionStyle";

import { makeStyles } from "@material-ui/core/styles";
import ContainerFullHeight from "components/ContainerFullHeight/ContainerFullHeight";
import { SecondaryFontColor } from "assets/jss/material-kit-pro-react.js";
import Button from "components/CustomButtons/Button.js";
import Video from "components/Video/Video";

const useStyles = makeStyles(style);

export default function DatesSection({ id, t }) {
  const classes = useStyles();

  const instagramLink = "https://www.instagram.com/conventionoftheamericas/";
  const urlVideo = "https://www.youtube.com/embed/JtyIKpMGaF0";

  return (
    <ContainerFullHeight>
      <div className={classes.container} id={id}>
        <GridContainer className={classes.titleContainer}>
          <GridItem xs={12}>
            <h1 className={classes.title}>{t("meetingTitle")}</h1>
            <br></br>
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>
            <h1
              className={classes.subtitle}
              style={{ color: SecondaryFontColor }}
            >
              {t("meetingSubtitle")}
            </h1>

            <br />
            {/*<Video url={urlVideo} />*/}
            <img src={ConventionImg} width={"100%"} alt="..." />
            <br />

            <p className={classes.text}>{t("meetingText")}</p>
            <div className={classes.buttonContainer}>
              <Button
                color="info"
                size="lg"
                href={instagramLink}
                target="blank"
                className={classes.navButton}
              >
                {t("facebookButton")}
              </Button>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <div className={classes.numbersContainer}>
              <div
                style={{ backgroundImage: `url("${Fechas2023}")` }}
                className={classes.img}
              ></div>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </ContainerFullHeight>
  );
}
