import React from "react";

const ScrollList = ({ scrolls, translate }) => {
  let currentTarget = null;

  React.useEffect(() => {
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    if (window.location.href.lastIndexOf("#") > 0) {
      document.getElementById(href).scrollIntoView();
    }
    window.addEventListener("scroll", updateView);
    updateView();
    return function cleanup() {
      window.removeEventListener("scroll", updateView);
    };
  }, []);

  const updateView = () => {
    var contentSections = document.getElementsByClassName("cd-section");
    var navigationItems = document
      .getElementById("cd-vertical-nav")
      .getElementsByTagName("a");

    for (let i = 0; i < contentSections.length; i++) {
      var activeSection =
        parseInt(navigationItems[i].getAttribute("data-number"), 10) - 1;
      if (
        contentSections[i].offsetTop - window.innerHeight / 2 <
          window.pageYOffset &&
        contentSections[i].offsetTop +
          contentSections[i].scrollHeight -
          window.innerHeight / 2 >
          window.pageYOffset
      ) {
        navigationItems[activeSection].classList.add("is-selected");
      } else {
        navigationItems[activeSection].classList.remove("is-selected");
      }
    }
  };

  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScroll = (target) => {
    const targetScroll = document.getElementById(target);
    const navHeight = 78;
    scrollGo(
      document.documentElement,
      targetScroll.offsetTop - navHeight,
      1250
    );
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };

  const handleScroll = (nextLocation, e) => {
    var isMobile = navigator.userAgent.match(
      /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
    );
    if (isMobile) {
      // if we are on mobile device the scroll into view will be managed by the browser
    } else {
      e.preventDefault();
      if (currentTarget !== nextLocation) {
        currentTarget = nextLocation;
        smoothScroll(nextLocation);
      }
    }
  };

  return (
    <nav id="cd-vertical-nav">
      <ul>
        {scrolls.map((scroll, index) => {
          return (
            <li>
              <a
                key={scroll}
                href={"#" + scroll}
                data-number={index + 1}
                className="is-selected"
                onClick={(e) => handleScroll(scroll, e)}
              >
                <span className="cd-dot" />
                <span className="cd-label">{translate(scroll)}</span>
              </a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default ScrollList;
