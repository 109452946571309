import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { useSelector } from "react-redux";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import style from "./HorizontalCourseStyle.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Button from "components/CustomButtons/Button.js";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import { Link } from "react-router-dom";

const useStyles = makeStyles(style);

const VerticalCourse = ({ courseProps, t }) => {
  const isLogged = useSelector((state) => {
    return state.auth?.uid;
  });
  const classes = useStyles();
  const {
    title,
    speakers,
    description,
    price,
    date,
    thumbnail,

    oldPrice,
    addedToCart,
    handleClick,
    boughted,
  } = courseProps;
  /* 
  const {
    id,
    duration_formatted,
    title,
    day,
    order,
    speakers,
    description,
    price,
    date,
    duration,
    thumbnail,
    video_id,

    oldPrice,
    author,
    addedToCart,
    handleClick,
    boughted,
  } */

  const descriptionWidt = boughted ? 9 : 7;
  const regularPriceClass = oldPrice
    ? [classes.price, classes.priceOld]
    : classes.price;

  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const now = new Date();
  const videoDate = new Date(date._seconds * 1000);

  const diffDays = Math.round(Math.abs((now - videoDate) / oneDay));
  return (
    <Card plain blog className={classes.card}>
      <GridContainer>
        <GridItem xs={12} sm={3} md={3} className={classes.imgContainerItem}>
          <CardHeader image plain>
            <img src={thumbnail} alt="..." />
            <div
              className={classes.coloredShadow}
              style={{
                backgroundImage: `url(${thumbnail})`,
                opacity: "1",
              }}
            />
            <div
              className={classes.coloredShadow}
              style={{
                backgroundImage: `url(${thumbnail})`,
                opacity: "1",
              }}
            />
          </CardHeader>
        </GridItem>
        <GridItem xs={12} sm={descriptionWidt} md={descriptionWidt}>
          <h3 className={classes.cardTitle}>{title}</h3>
          <p className={classes.description}>{description}</p>
          <p className={classes.author}>
            {t("author")}
            {": "}
            {speakers.map((author, index) => (
              <b key={index}>{author}, </b>
            ))}
            {diffDays + " " + t("days") + " " + t("ago")}
          </p>
        </GridItem>

        {!boughted && (
          <GridItem
            xs={12}
            sm={2}
            md={2}
            container
            direction="column"
            justify="space-evenly"
            alignItems="flex-end"
          >
            <GridItem className={classes.priceContainer}>
              <span className={classNames(regularPriceClass)}>
                {" "}
                ${oldPrice ? oldPrice : price}
              </span>
              {oldPrice && (
                <span className={classNames(classes.price, classes.priceNew)}>
                  {" "}
                  ${price}
                </span>
              )}
            </GridItem>
            {isLogged ? (
              <Button
                size="sm"
                color="primary"
                className={classes.navButton}
                round
                disabled={addedToCart}
                onClick={handleClick}
              >
                <ShoppingCart className={classes.icons} />{" "}
                {addedToCart ? t("added") : t("add to cart")}
              </Button>
            ) : (
              <Link to={"/login-page"}>
                <Button
                  size="sm"
                  color="primary"
                  className={classes.navButton}
                  round
                  disabled={addedToCart}
                >
                  <ShoppingCart className={classes.icons} />{" "}
                  {addedToCart ? t("added") : t("add to cart")}
                </Button>
              </Link>
            )}
          </GridItem>
        )}
      </GridContainer>
    </Card>
  );
};

export default VerticalCourse;
