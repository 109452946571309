/*!

=========================================================
* Material Kit PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import "assets/scss/material-kit-pro-react.scss?v=1.9.0";
import './i18n';

import App from "App.js";

export default function Root() {
  return <App />;
}

ReactDOM.render(<Root />, document.getElementById("root"));
