import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";

import MenuItem from "@material-ui/core/MenuItem";
import { StepperButtons } from "../StepperButtons";
import { CustomSelect } from "components/CustomForm";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { ConventionWithHotel } from "./PackageOptions/ConventionWithHotel";
import { NoConvention } from "./PackageOptions/NoConvention";
import { useRegistrationFormUtils } from "hooks/useRegistrationFormUtils";
import { useTranslation } from "react-i18next";
import {
  PACKAGE_CONVENTION_WITHOUT_HOTEL,
  COMPANION_NO_COMPANION,
  HOTEL_HIDALGO,
  PACKAGE_CONVENTION_WITH_HOTEL,
  PACKAGE_NO_CONVENTION,
} from "constants/registrationForm";
import { ConventionWithoutHotel } from "./PackageOptions/ConventionWithoutHotel";
import { STEP_ACCOMMODATION_INFO } from "constants/registrationForm";
import { COMPANION_HAS_COMPANION_WILL_PAY } from "constants/registrationForm";
import { HOTEL_LOURDES } from "constants/registrationForm";
import { TotalDisplay } from "../components/TotalDisplay";
import { useRegistrationFormCosts } from "hooks/useRegistrationFormCosts";
import { mapFormDataToAccomodationDTO } from "mappers/formMapper";

const useStyles = makeStyles(basicsStyle);
export const Step2 = ({
  onHandleSuccess,
  defaultFormValue = {},
  values,
  handlePrevious,
  total,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(["registrationForm"]);

  const { packagesList, getOtherStepsTotals } = useRegistrationFormUtils();
  const { getAccommodationPartialCost } = useRegistrationFormCosts();
  const [partialTotal, setPartialTotal] = useState(0);
  const formValue = values || defaultFormValue;
  const { register, handleSubmit, errors, control, watch } = useForm({
    defaultValues: formValue,
  });

  const selectedPackage = watch("package") || PACKAGE_CONVENTION_WITH_HOTEL;
  const selectedNoConventionPeopleCount = watch("noConventionPeopleCount") || 1;
  const selectedJustConventionPeopleCount =
    watch("justConventionPeopleCount") || 1;
  const selectedHasCompanion = watch("hasCompanion") || COMPANION_NO_COMPANION;
  const selectedHotelOption = watch("hotelOption") || HOTEL_HIDALGO;
  const selectedHotelNightsBefore = watch("hotelNightsBefore") || 0;
  const selectedHotelNightsAfter = watch("hotelNightsAfter") || 0;

  useEffect(() => {
    const partialTotal = getAccommodationPartialCost(
      mapFormDataToAccomodationDTO(
        selectedPackage,
        selectedHotelOption,
        selectedHotelNightsBefore,
        selectedHotelNightsAfter,
        selectedHasCompanion,
        selectedJustConventionPeopleCount
      )
    );
    setPartialTotal(partialTotal);
  }, [
    selectedPackage,
    selectedHasCompanion,
    selectedHotelOption,
    selectedHotelNightsBefore,
    selectedHotelNightsAfter,
    selectedJustConventionPeopleCount,
  ]);

  const onSubmit = (data) => {
    onHandleSuccess(STEP_ACCOMMODATION_INFO, { ...data, partialTotal });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GridContainer>
        <GridItem md={12} sm={6}>
          <TotalDisplay
            partialTotal={partialTotal}
            stepId={STEP_ACCOMMODATION_INFO}
            total={total}
          />
          <h3>{t("packageTitle")}</h3>

          <CustomSelect
            id="package"
            name="package"
            label={t("packageTitle")}
            control={control}
            fullWidth
            defaultValue={packagesList[0].id}
            className={
              classes.selectFormControl + " " + classes.selectUnderlineRoot
            }
          >
            {packagesList.map((item) => (
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value={item.id}
                key={item.id}
              >
                {item.label}
              </MenuItem>
            ))}
          </CustomSelect>
        </GridItem>
        {selectedPackage === PACKAGE_CONVENTION_WITH_HOTEL && (
          <ConventionWithHotel
            register={register}
            control={control}
            selectedHasCompanion={selectedHasCompanion}
            selectedHotel={selectedHotelOption}
            selectedNightsBefore={selectedHotelNightsBefore}
            selectedNightsAfter={selectedHotelNightsAfter}
          />
        )}

        {selectedPackage === PACKAGE_CONVENTION_WITHOUT_HOTEL && (
          <ConventionWithoutHotel
            register={register}
            control={control}
            justConventionPeopleCount={selectedJustConventionPeopleCount}
          />
        )}

        {selectedPackage === PACKAGE_NO_CONVENTION && (
          <NoConvention
            register={register}
            control={control}
            selectedNoConventionPeopleCount={selectedNoConventionPeopleCount}
          />
        )}

        <GridContainer xs="12" justify="flex-end">
          <StepperButtons
            hasBackButton
            hasNextButton
            handleBack={handlePrevious}
          />
        </GridContainer>
      </GridContainer>
    </form>
  );
};
