import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MenuItem from "@material-ui/core/MenuItem";
import { StepperButtons } from "../StepperButtons";
import { CustomSelect } from "components/CustomForm";
import { NumberOfPeopleSelect } from "../components/NumberOfPeopleSelect";
import { useRegistrationFormUtils } from "hooks/useRegistrationFormUtils";
import {
  ACTIVITY_NONE,
  ACTIVITY_GALA_DINNER,
  ACTIVITY_PIRAMIDES,
  STEP_ACTIVITY_INFO,
  ACTIVITY_WORKSHOP,
} from "constants/registrationForm";
import { Box } from "@material-ui/core";
import { TotalDisplay } from "../components/TotalDisplay";
import { MoneyFormatter } from "../components/MoneyFormatter";
import { useRegistrationFormCosts } from "hooks/useRegistrationFormCosts";
import { mapFormDataToActivitiesDTO } from "mappers/formMapper";
import Warning from "components/Typography/Warning";
import { EXCURSION_URL_ENGLISH } from "constants/registrationForm";
import { EXCURSION_URL_SPANISH } from "constants/registrationForm";
import SnackbarContent from "components/Snackbar/SnackbarContent";

const useStyles = makeStyles(basicsStyle);
export const Step3 = ({
  onHandleSuccess,
  defaultFormValue = {},
  values,
  handlePrevious,
  total,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation(["registrationForm"]);
  const {
    activitiesDay1,
    getActivityLabel,
    getActivityDescription,
  } = useRegistrationFormUtils();
  const {
    getActivityCost,
    getActivityCostByPerson,
    getActivitiesPartialCost,
  } = useRegistrationFormCosts();

  const [partialTotal, setPartialTotal] = useState(0);
  const [excursionURL, setExcursionURL] = useState(EXCURSION_URL_ENGLISH);

  const formValue = values || defaultFormValue;
  const { register, handleSubmit, errors, control, watch } = useForm({
    defaultValues: formValue,
  });

  const selectedExcursion = watch("activityDay1") || ACTIVITY_NONE;
  const selectedExcursionPeopleCount = watch("excursionPeopleCount") || 1;
  const selectedGalaDinnerPeopleCount = watch("galaDinnerPeopleCount") || 0;
  const selectedPiramidePeopleCount = watch("piramidePeopleCount") || 0;
  const selectedWorkshopPeopleCount = watch("workshopPeopleCount") || 0;

  useEffect(() => {
    const partial = getActivitiesPartialCost(
      mapFormDataToActivitiesDTO(
        selectedExcursion,
        selectedExcursionPeopleCount,
        selectedGalaDinnerPeopleCount,
        selectedPiramidePeopleCount,
        selectedWorkshopPeopleCount
      )
    );
    setPartialTotal(partial);
  }, [
    selectedExcursion,
    selectedExcursionPeopleCount,
    selectedGalaDinnerPeopleCount,
    selectedPiramidePeopleCount,
    selectedWorkshopPeopleCount,
  ]);

  useEffect(() => {
    if (i18n.language === "en") setExcursionURL(EXCURSION_URL_ENGLISH);
    if (i18n.language === "es") setExcursionURL(EXCURSION_URL_SPANISH);
  }, []);
  const handleExcursionMoreInfoClick = () => {
    window.open(excursionURL, "_blank");
  };

  const onSubmit = (data) => {
    onHandleSuccess(STEP_ACTIVITY_INFO, { ...data, partialTotal });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GridContainer>
        <GridItem xs={12}>
          <TotalDisplay
            partialTotal={partialTotal}
            stepId={STEP_ACTIVITY_INFO}
            total={total}
          />
          <h3>{t("activitiesTitle")}</h3>
        </GridItem>
        <GridItem xs={12} className={classes.registrationSection}>
          <h4>{t("activitiesWorkshopTitle")}</h4>
          <GridItem xs={12}>
            <h5 className={classes.registrationSubtitle}>
              {getActivityLabel(ACTIVITY_WORKSHOP)}
            </h5>
            <ul>
              <li>{t("kate_workshop_text_1")}</li>
              <li>{t("kate_workshop_text_2")}</li>
              <li>{t("kate_workshop_text_3")}</li>
            </ul>
            <NumberOfPeopleSelect
              name="workshopPeopleCount"
              control={control}
              allowNone
              costCalculator={(count) =>
                getActivityCostByPerson(ACTIVITY_WORKSHOP, count)
              }
            />
          </GridItem>
        </GridItem>
        <GridItem xs={12} className={classes.registrationSection}>
          <SnackbarContent
            onClick={handleExcursionMoreInfoClick}
            message={<span>{t("activitiesMoreInfo")}</span>}
            color="success"
            icon="info_outline"
          />
        </GridItem>
        <GridItem xs={12} className={classes.registrationSection}>
          <h4>{t("activitiesDay1Title")}</h4>
          <GridItem xs={12}>
            <CustomSelect
              id="activityDay1"
              name="activityDay1"
              label={t("activitiesDay1Label")}
              control={control}
              fullWidth
              defaultValue={ACTIVITY_NONE}
              className={
                classes.selectFormControl + " " + classes.selectUnderlineRoot
              }
            >
              {activitiesDay1.map(({ id, label }) => (
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={id}
                  key={id + "test"}
                >
                  <Box className={classes.dFlexSpaceBetween}>
                    <Box>{label}</Box>
                    <MoneyFormatter value={getActivityCost(id)} />
                  </Box>
                </MenuItem>
              ))}
            </CustomSelect>
            {selectedExcursion !== ACTIVITY_NONE && (
              <>
                <Warning>{t("activity_disclaimer")}</Warning>
                <br />
                <span>{getActivityDescription(selectedExcursion)}</span>

                <NumberOfPeopleSelect
                  name="excursionPeopleCount"
                  control={control}
                  costCalculator={(count) =>
                    getActivityCostByPerson(selectedExcursion, count)
                  }
                />
              </>
            )}
          </GridItem>
        </GridItem>

        <GridItem xs={12} className={classes.registrationSection}>
          <h4>{t("activitiesDay2Title")}</h4>
          <GridItem xs={12}>
            <h5 className={classes.registrationSubtitle}>
              {getActivityLabel(ACTIVITY_GALA_DINNER)}
            </h5>
            <span>{getActivityDescription(ACTIVITY_GALA_DINNER)}</span>
            <NumberOfPeopleSelect
              name="galaDinnerPeopleCount"
              control={control}
              allowNone
              costCalculator={(count) =>
                getActivityCostByPerson(ACTIVITY_GALA_DINNER, count)
              }
            />
          </GridItem>
        </GridItem>
        <GridItem xs={12} className={classes.registrationSection}>
          <h4>{t("activitiesDay3Title")}</h4>
          <GridItem xs={12}>
            <Box>
              <h5 className={classes.registrationSubtitle}>
                {getActivityLabel(ACTIVITY_PIRAMIDES)}
              </h5>
            </Box>
            <span>{getActivityDescription(ACTIVITY_PIRAMIDES)}</span>
            <NumberOfPeopleSelect
              name="piramidePeopleCount"
              control={control}
              allowNone
              costCalculator={(count) =>
                getActivityCostByPerson(ACTIVITY_PIRAMIDES, count)
              }
            />
          </GridItem>
        </GridItem>
        <GridContainer xs="12" justify="flex-end">
          <StepperButtons
            hasBackButton
            handleBack={handlePrevious}
            hasNextButton
          />
        </GridContainer>
      </GridContainer>
    </form>
  );
};
