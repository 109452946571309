import GridItem from "components/Grid/GridItem";
import React from "react";
import { SummaryData } from "../SummaryData";
import { CompanionShortData } from "../CompanionShortData";

import { useTranslation } from "react-i18next";

export const NoConventionSummary = ({ count, guests , currency}) => {
  const { t } = useTranslation(["registrationForm"]);

  return (
    <>
      <GridItem sm={12}>
        <SummaryData
          label={t("people")}
          value={count === 1 ? t("justMe") : count + " " + t("people")}
          cost={0}
          currency={currency}
        />
        {count > 1 &&
          guests.map(
            ({ name, email, city, phone }, index) =>
              !!name && (
                <CompanionShortData
                  name={name}
                  key={index}
                  email={email}
                  phone={phone}
                  city={city}
                />
              )
          )}
      </GridItem>
    </>
  );
};
