import { PrimaryFontColorLanding } from "assets/jss/material-kit-pro-react";
import { subtitleLandingBold, textJustify } from "assets/jss/material-kit-pro-react";

const style = (theme) => ({
  subtitle: {
    ...textJustify,
    ...subtitleLandingBold,
    textTransform: "uppercase",
    color: PrimaryFontColorLanding,
    textAlign: "start",
  },
});

export default style;
