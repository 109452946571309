import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer";
import style from "./SpeakerInfoStyle";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar";
import CardBody from "components/Card/CardBody";
import Muted from "components/Typography/Muted";
import CardFooter from "components/Card/CardFooter";
import defaultProfileImage from "assets/img/convention/collageTest.jpg";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";
import i18next from "i18next";

const useStyles = makeStyles(style, styles);

export default function SpeakerInfo({ speaker }) {
  const { t } = useTranslation(["common"]);
  const getCurrentLng = () =>
    i18next.language || window.localStorage.i18nextLng || "en";
  const selectedLanguage = getCurrentLng();
  const { name, description, url, img, title } = speaker;

  const classes = useStyles();

  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => setShowMore(!showMore);

  const truncatedDescription = showMore
    ? description[selectedLanguage]
    : `${description[selectedLanguage].slice(0, 150)}...`;

  return (
    <GridContainer className={classes.speakerContainer}>
      <Card profile>
        <CardAvatar profile>
          <a {...(url ? { href: url } : {})} target="blank">
            <img
              src={img || defaultProfileImage}
              alt={name}
              className={classes.img}
            />
          </a>
        </CardAvatar>
        <CardBody>
          <h6 style={{ color: "black" }} className={classes.title}>
            {title}
          </h6>
          <h4 style={{ color: "black" }} className={classes.cardTitle}>
            {name}
          </h4>
          <p style={{ color: "black" }} className={classes.description}>
            {truncatedDescription}
          </p>
          {description[selectedLanguage].length > 100 && (
            <p
              className={classes.showMoreOrLess}
              onClick={toggleShowMore}
            >
              {showMore ? t("Show less") : t("Show more")}
            </p>
          )}
          {url && (
            <a {...(url ? { href: url } : {})} target="blank">
              <Button round color="info" size="sm" className={classes.btn}>
                {t("More info")}
              </Button>
            </a>
          )}
        </CardBody>
      </Card>
    </GridContainer>
  );
}