import GridItem from "components/Grid/GridItem";
import React from "react";
import { SummaryData } from "../SummaryData";

import { makeStyles } from "@material-ui/core/styles";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import { useRegistrationFormUtils } from "hooks/useRegistrationFormUtils";
import {
  STEP_ACTIVITY_INFO,
  ACTIVITY_NONE,
  ACTIVITY_GALA_DINNER,
  ACTIVITY_PIRAMIDES,
  ACTIVITY_WORKSHOP,
} from "constants/registrationForm";
import GridContainer from "components/Grid/GridContainer";
const useStyles = makeStyles(basicsStyle);

export const ActivitiesInfoSummary = ({ activities, currency }) => {
  const classes = useStyles();
  const { getStepName, getActivityLabel } = useRegistrationFormUtils();

  const mustShow = () =>
    activities?.dayOneActivity?.count ||
    activities?.galaDinner?.count ||
    activities?.piramides?.count ||
    activities?.workshop?.count;

  if (!mustShow()) return <></>;

  return (
    <GridContainer>
      <GridItem xs={12}>
        <h4 className={classes.registrationTitle}>
          {getStepName(STEP_ACTIVITY_INFO)}
        </h4>
        <GridItem sm={12}>
          {!!activities.workshop.count && (
            <SummaryData
              label={getActivityLabel(ACTIVITY_WORKSHOP)}
              value={activities?.workshop.count}
              arePeople
              cost={activities?.workshop.total}
              currency={currency}
            />
          )}
          {activities?.dayOneActivity.id !== ACTIVITY_NONE &&
            !!activities.dayOneActivity.count && (
              <SummaryData
                label={getActivityLabel(activities?.dayOneActivity.id)}
                value={activities?.dayOneActivity.count}
                arePeople
                cost={activities?.dayOneActivity.total}
                currency={currency}
              />
            )}
          {!!activities.galaDinner.count && (
            <SummaryData
              label={getActivityLabel(ACTIVITY_GALA_DINNER)}
              value={activities?.galaDinner.count}
              arePeople
              cost={activities?.galaDinner.total}
              currency={currency}
            />
          )}
          {!!activities.piramides.count && (
            <SummaryData
              label={getActivityLabel(ACTIVITY_PIRAMIDES)}
              value={activities?.piramides.count}
              arePeople
              cost={activities.piramides.total}
              currency={currency}
            />
          )}
        </GridItem>
      </GridItem>
    </GridContainer>
  );
};
