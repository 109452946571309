import { CustomPhoneInput, CustomFormInput } from "components/CustomForm";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import { useTranslation } from "react-i18next";

export const CompanionDataForm = ({
  name,
  register,
  numberOfCompanions,
  errors,
  control,
}) => {
  const { t } = useTranslation(["registrationForm"]);

  const companions = [1, 2, 3, 4, 5].slice(0, numberOfCompanions);

  return (
    <>
      {companions.map((guest) => (
        <GridItem key={guest}>
          <GridContainer>
            <GridItem md={3} sm={12}>
              <CustomFormInput
                ref={register({
                  required: "This field is required",
                })}
                labelText={t("formName")}
                name={name + "_name_" + guest}
                formControlProps={{
                  fullWidth: true,
                  required: true,
                }}
                error={errors}
              />
            </GridItem>

            <GridItem md={3} sm={12}>
              <CustomFormInput
                labelText={t("formEmail")}
                name={name + "_email_" + guest}
                ref={register({
                  required: "This field is required",
                })}
                id="float"
                formControlProps={{ fullWidth: true }}
              />
            </GridItem>
            <GridItem md={3} sm={12}>
              <CustomPhoneInput
                control={control}
                name={name + "_phone_" + guest}
                label={t("formPhone")}
                fullWidth
              />
            </GridItem>
            <GridItem md={3} sm={12}>
              <CustomFormInput
                ref={register({
                  required: "This field is required",
                })}
                labelText={t("formCity")}
                name={name + "_city_" + guest}
                formControlProps={{
                  fullWidth: true,
                  required: true,
                }}
                error={errors}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      ))}
    </>
  );
};
