import React from "react";

import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";

import MenuItem from "@material-ui/core/MenuItem";
import { StepperButtons } from "../StepperButtons";
import {
  CustomFormInput,
  CustomSelect,
  CustomPhoneInput,
} from "components/CustomForm";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { useRegistrationFormUtils } from "hooks/useRegistrationFormUtils";
import { useTranslation } from "react-i18next";
import { STEP_PERSONAL_INFO } from "constants/registrationForm";

const useStyles = makeStyles(basicsStyle);

export const Step1 = ({ onHandleSuccess, defaultFormValue = {}, values }) => {
  const { t } = useTranslation(["registrationForm"]);
  const classes = useStyles();

  const formValue = values || defaultFormValue;
  const { register, handleSubmit, errors, control } = useForm({
    defaultValues: formValue,
  });
  const { email: userEmail, name: userName } = useSelector(
    (state) => state.auth
  );

  const { getHonorTitleList, getHonorTitleLabel } = useRegistrationFormUtils();
  const onSubmit = (data) => {
    onHandleSuccess(STEP_PERSONAL_INFO, { ...data, email: userEmail });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GridContainer xs={12}>
        <GridItem md={12} sm={6}>
          <h3>{t("registrationStepTitle")}</h3>

          <CustomSelect
            id="honorTitle"
            name="honorTitle"
            label={getHonorTitleLabel("title")}
            control={control}
            required
            fullWidth
            defaultValue="none"
            className={
              classes.selectFormControl + " " + classes.selectUnderlineRoot
            }
          >
            <MenuItem
              disabled
              classes={{
                root: classes.selectMenuItem,
              }}
            >
              {getHonorTitleLabel("title")}
            </MenuItem>
            {getHonorTitleList().map(({ id, label }) => (
              <MenuItem
                key={id}
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value={id}
              >
                {label}
              </MenuItem>
            ))}
          </CustomSelect>
          <CustomFormInput
            ref={register({
              required: "This field is required",
              minLength: { value: 4, message: "Minimum length is 4" },
            })}
            labelText={t("formName")}
            name="name"
            inputProps={{ defaultValue: userName }}
            formControlProps={{
              fullWidth: true,
              required: true,
            }}
            error={errors}
          />
          <CustomFormInput
            labelText={t("formEmail")}
            id="email"
            name="email"
            inputProps={{ value: userEmail, disabled: true }}
            formControlProps={{
              fullWidth: true,
            }}
          />

          <CustomPhoneInput
            control={control}
            name="phone"
            label={t("formPhone")}
            fullWidth
          />
          <CustomFormInput
            name="city"
            labelText={t("formCity")}
            id="city"
            formControlProps={{
              fullWidth: true,
            }}
            ref={register}
          />
          <h3>{t("emergencyContactTitle")}</h3>
          <h4>{t("emergencyContactSubtitle")}</h4>
          <CustomFormInput
            labelText={t("formName")}
            id="emergencyContactName"
            name="emergencyContactName"
            formControlProps={{
              fullWidth: true,
            }}
            ref={register}
          />
          <CustomFormInput
            labelText={t("formEmail")}
            id="emergencyContactEmail"
            name="emergencyContactEmail"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{ type: "email" }}
            ref={register}
          />
          <CustomPhoneInput
            control={control}
            id="emergencyContactPhone"
            name="emergencyContactPhone"
            label={t("formPhone")}
            fullWidth
          />
          <CustomFormInput
            labelText={t("formRelation")}
            id="emergencyContactRelation"
            name="emergencyContactRelation"
            formControlProps={{
              fullWidth: true,
            }}
            ref={register}
          />
        </GridItem>
        <GridContainer xs="12" justify="flex-end">
          <StepperButtons hasNextButton />
        </GridContainer>
      </GridContainer>
    </form>
  );
};
