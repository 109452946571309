import React from "react";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import speakers from "localDatabase/speakers2024";
import style from "./SpeakerSectionStyle";

import { makeStyles } from "@material-ui/core/styles";
import ContainerFullHeight from "components/ContainerFullHeight/ContainerFullHeight";
import SpeakerInfo from "components/SpeakerInfo/SpeakerInfo";
import i18next from "i18next";

const useStyles = makeStyles(style);

export default function SpeakerSection({ id, t }) {
  const classes = useStyles();
  const getCurrentLng = () =>
    i18next.language || window.localStorage.i18nextLng || "en";
  const selectedLenguage = getCurrentLng();
  return (
    <ContainerFullHeight alignItems="start" id={id} bgColor="beige" paddingTop="200px" paddingBottom="200px">
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12}>
            <div className={classes.halfCircleWrapper}>
            <h1 className={classes.title}>{t("speekersTitle")}</h1>
            </div>
          </GridItem>
          {speakers.map((day, index) => (
            <GridContainer>
              <GridItem xs={12}>
                {day.description[selectedLenguage] && (
                  <h2 className={classes.subtitle}>
                    {day.description[selectedLenguage]}
                  </h2>
                )}
              </GridItem>
              {day.speakers.map((speaker, index) => (
                <GridItem xs={12} md={6} key={index}>
                  <SpeakerInfo speaker={speaker} />
                </GridItem>
              ))}
            </GridContainer>
          ))}
        </GridContainer>
      </div>
    </ContainerFullHeight>
  );
}
