import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";

const imageLoginStyle = (theme) => ({
  imageContainer: {
    position: "relative",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageLogo: {
    width: "150px",
    position: "absolute",
    top: "-160px",
  },
  ...imagesStyles,
});

export default imageLoginStyle;
