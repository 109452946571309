/* eslint-disable */
import React, { useEffect, useState } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import { useTranslation, Trans } from "react-i18next";

// @material-ui/icons
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Person from "@material-ui/icons/Person";
import ExitToApp from "@material-ui/icons/ExitToApp";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import styles from "./HeaderLinksStyle.js";
import { useSelector } from "react-redux";
import USALogo from "assets/img/convention/icons/usa.svg";
import MxLogo from "assets/img/convention/icons/mx.svg";

import { Hidden } from "@material-ui/core";

const useStyles = makeStyles(styles);
let languageLogo = USALogo;

export default function HeaderLinks(props) {
  const { t, i18n } = useTranslation(["header"]);

  const itemsInCart = useSelector((state) => state.courses.cart);
  const userName = useSelector((state) => state.auth.name);

  const { dropdownHoverColor, logOutHandle, isLogged } = props;
  const classes = useStyles();

  let storagedLanguage = localStorage.getItem("storagedLanguage");
  let language = "en";
  if (storagedLanguage == "es") {
    languageLogo = MxLogo;
    language = "es";
  }

  const changeLanguage = (lng) => {
    switch (lng) {
      case "en":
        localStorage.setItem("storagedLanguage", "en");
        languageLogo = USALogo;
        language = "en";
        break;
      case "es":
        localStorage.setItem("storagedLanguage", "es");
        languageLogo = MxLogo;
        language = "es";
        break;
      default:
        localStorage.setItem("storagedLanguage", "en");
        languageLogo = USALogo;
        language = "en";
        break;
    }
    i18n.changeLanguage(lng);
  };

  return (
    <List className={classes.list + " " + classes.mlAuto}>
      <ListItem className={classes.listItem}>
        <Link to="/" className={classes.dropdownLink}>
          {t("Home")}
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/courses" className={classes.dropdownLink}>
          {t("Videos")}
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/about-us" className={classes.dropdownLink}>
          {t("About Us")}
        </Link>
      </ListItem>
      {isLogged && (
        <ListItem className={classes.listItem}>
          <Link to="/my-courses" className={classes.dropdownLink}>
            {t("My Videos")}
          </Link>
        </ListItem>
      )}
      {isLogged && (
        <ListItem className={classes.listItem}>
          <Link to="/shopping-cart-page" className={classes.dropdownLink}>
            <div className={classes.cartContainer}>
              <ShoppingCart className={classes.cartIcon} />{" "}
              {itemsInCart.length != 0 && (
                <span className={classes.numberContainer}>
                  {itemsInCart.length}
                </span>
              )}
            </div>
            <Hidden mdUp>
              <div className={classes.languageText}>{t("Shopping Cart")}</div>
            </Hidden>
          </Link>
        </ListItem>
      )}
      {isLogged && (
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            navDropdown
            hoverColor={dropdownHoverColor}
            buttonText={userName}
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={Person}
            dropdownList={[
              <div onClick={logOutHandle}>
                <ExitToApp className={classes.dropdownIcons} /> {t("Log Out")}
              </div>,
            ]}
          />
        </ListItem>
      )}
      {!isLogged && (
        <ListItem className={classes.listItem}>
          <Link to="/login-page" className={classes.dropdownLink}>
            <Person className={classes.dropdownIcons} /> {t("Login")}
          </Link>
        </ListItem>
      )}
      <CustomDropdown
        noLiPadding
        navDropdown
        useMinPossibleWidth
        hoverColor={dropdownHoverColor}
        buttonText={
          <div className={classes.iconContainer}>
            <img
              src={languageLogo}
              className={classes.languageIcon}
              alt={t("Select Language")}
            />
            <Hidden mdUp>
              {language == "en" && (
                <div className={classes.languageText}>{t("English")}</div>
              )}
              {language == "es" && (
                <div className={classes.languageText}>{t("Spanish")}</div>
              )}
            </Hidden>
          </div>
        }
        buttonProps={{
          className: classes.navLink,
          color: "transparent",
        }}
        dropdownList={[
          <div
            className={classes.iconContainer}
            onClick={() => changeLanguage("en")}
          >
            <img
              src={USALogo}
              className={classes.languageIcon}
              alt={t("English")}
            />
            <div className={classes.languageText}>{t("English")}</div>
          </div>,
          <div
            className={classes.iconContainer}
            onClick={() => changeLanguage("es")}
          >
            <img
              src={MxLogo}
              className={classes.languageIcon}
              alt={t("Spanish")}
            />
            <div className={classes.languageText}>{t("Spanish")}</div>
          </div>,
        ]}
      />
      {/*   <FormControl fullWidth className={classes.selectFormControl}>
        <Select
          MenuProps={{
            className: classes.selectMenu,
          }}
          classes={{
            select: classes.select,
          }}
          value={selectedLanguage}
          onChange={handleChangeLanguage}
          inputProps={{
            name: "simpleSelect",
            id: "simple-select",
          }}
        >
          <MenuItem
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value="en"
          >
            <img src={UKLogo} className={classes.languageIcon} alt="English" />
          </MenuItem>
          <MenuItem
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value="es"
          >
            <img
              src={SpainLogo}
              className={classes.languageIcon}
              alt="Spanish"
            />
          </MenuItem>
        </Select>
      </FormControl>
   */}{" "}
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary",
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
  ]),
  logOutHandle: PropTypes.func.isRequired,
  isLogged: PropTypes.bool.isRequired,
};
