import React from "react";
import {
  HOTEL_LOURDES,
  HOTEL_HIDALGO,
  HOTEL_HIDALGO_EXTRA_NIGHT,
  HOTEL_LOURDES_EXTRA_NIGHT,
  PACKAGE_CONVENTION_WITH_HOTEL,
  PACKAGE_CONVENTION_WITHOUT_HOTEL,
  PACKAGE_NO_CONVENTION,
  COMPANION_HAS_COMPANION_WILL_PAY,
  ACTIVITY_GALA_DINNER,
  ACTIVITY_PIRAMIDES,
  ACTIVITY_NONE,
} from "constants/registrationForm";
import { regularCost } from "constants/registrationFormCosts";
import { ACTIVITY_WORKSHOP } from "constants/registrationForm";

export const useRegistrationFormCosts = () => {
  const costs = regularCost;
  const getHotelCost = (hotel) => {
    if (hotel === HOTEL_HIDALGO) return costs[HOTEL_HIDALGO];
    if (hotel === HOTEL_LOURDES) return costs[HOTEL_LOURDES];
  };

  const getHotelExtraNightCost = (hotel) => {
    if (hotel === HOTEL_HIDALGO) return costs[HOTEL_HIDALGO_EXTRA_NIGHT];
    if (hotel === HOTEL_LOURDES) return costs[HOTEL_LOURDES_EXTRA_NIGHT];
  };

  const getHotelExtraNightCostPerNight = (hotel, nights) => {
    return getHotelExtraNightCost(hotel) * nights;
  };
  const getHotelAndNightsCosts = (hotel, nights) => {
    return getHotelCost(hotel) + getHotelExtraNightCostPerNight(hotel, nights);
  };

  const getCompanionCost = (companion, hotel, nightsBefore, nightsAfter) => {
    if (companion === COMPANION_HAS_COMPANION_WILL_PAY)
      return getHotelAndNightsCosts(hotel, nightsBefore + nightsAfter);
    return 0;
  };

  const getHotelTotalCost = (hotel, nightsBefore, nightsAfter, companion) => {
    let totalPerPerson = getHotelAndNightsCosts(
      hotel,
      nightsBefore + nightsAfter
    );
    if (companion === COMPANION_HAS_COMPANION_WILL_PAY) totalPerPerson *= 2;
    return totalPerPerson;
  };

  const getJustConventionCosts = (membersCount) => {
    return costs[PACKAGE_CONVENTION_WITHOUT_HOTEL] * membersCount;
  };

  const getAccommodationPartialCost = ({
    selectedPackage,
    selectedHotel,
    preConventionNights,
    postConventionNights,
    companionStatus,
    conventionAttendeesCount,
  }) => {
    if (selectedPackage === PACKAGE_NO_CONVENTION) return 0;
    if (selectedPackage === PACKAGE_CONVENTION_WITHOUT_HOTEL)
      return getJustConventionCosts(conventionAttendeesCount);
    if (selectedPackage === PACKAGE_CONVENTION_WITH_HOTEL)
      return getHotelTotalCost(
        selectedHotel,
        preConventionNights,
        postConventionNights,
        companionStatus
      );
  };

  const getActivityCost = (activity) => {
    if (activity === ACTIVITY_NONE) return 0;
    return costs[activity] || 0;
  };
  const getActivityCostByPerson = (activity, count = 0) => {
    return getActivityCost(activity) * count;
  };

  const getActivitiesPartialCost = ({
    excursionDay1,
    excursionAttendeesCount,
    galaAttendeesCount,
    piramidesAttendeesCount,
    workshopPeopleCount,
  }) => {
    let newTotal = 0;
    if (excursionDay1 !== ACTIVITY_NONE) {
      newTotal += costs[excursionDay1] * excursionAttendeesCount;
    }

    if (galaAttendeesCount > 0) {
      newTotal += costs[ACTIVITY_GALA_DINNER] * galaAttendeesCount;
    }

    if (piramidesAttendeesCount > 0) {
      newTotal += costs[ACTIVITY_PIRAMIDES] * piramidesAttendeesCount;
    }
    if (workshopPeopleCount > 0) {
      newTotal += costs[ACTIVITY_WORKSHOP] * workshopPeopleCount;
    }
    return newTotal;
  };

  const getGlobalTotal = ({ accommodationData, activitiesData, donation }) =>
    getAccommodationPartialCost(accommodationData) +
    getActivitiesPartialCost(activitiesData) +
    donation;

  const getTotalDetailed = ({
    accommodationData,
    activitiesData,
    donation,
  }) => {
    const totalDetailed = {
      selectedPackage: accommodationData.selectedPackage,
      withHotel: {
        hotel: {
          id: accommodationData.selectedHotel,
          unitCost: getHotelCost(accommodationData.selectedHotel),
        },
        nightsBefore: {
          unitCost: getHotelExtraNightCost(accommodationData.selectedHotel),
          count: accommodationData.preConventionNights || 0,
          total: getHotelExtraNightCostPerNight(
            accommodationData.selectedHotel,
            accommodationData.preConventionNights
          ),
        },
        nightsAfter: {
          unitCost: getHotelExtraNightCost(accommodationData.selectedHotel),
          count: accommodationData.postConventionNights || 0,
          total: getHotelExtraNightCostPerNight(
            accommodationData.selectedHotel,
            accommodationData.postConventionNights
          ),
        },
        companionStatus: {
          id: accommodationData.companionStatus,
          total: getCompanionCost(
            accommodationData.companionStatus,
            accommodationData.selectedHotel,
            accommodationData.preConventionNights,
            accommodationData.postConventionNights
          ),
        },
      },
      justConvention: {
        unitCost: getJustConventionCosts(1),
        count: accommodationData.conventionAttendeesCount || 0,
        total: getJustConventionCosts(
          accommodationData.conventionAttendeesCount
        ),
      },
      noConvention: {
        total: 0,
      },
      activities: {
        workshop: {
          unitCost: getActivityCost(ACTIVITY_WORKSHOP),
          count: activitiesData.workshopPeopleCount || 0,
          total: getActivityCostByPerson(
            ACTIVITY_WORKSHOP,
            activitiesData.workshopPeopleCount
          ),
        },
        dayOneActivity: {
          id: activitiesData.excursionDay1,
          count: activitiesData.excursionAttendeesCount || 0,
          unitCost: getActivityCost(activitiesData.excursionDay1),
          total: getActivityCostByPerson(
            activitiesData.excursionDay1,
            activitiesData.excursionAttendeesCount
          ),
        },
        galaDinner: {
          unitCost: getActivityCost(ACTIVITY_GALA_DINNER),
          count: activitiesData.galaAttendeesCount || 0,
          total: getActivityCostByPerson(
            ACTIVITY_GALA_DINNER,
            activitiesData.galaAttendeesCount
          ),
        },
        piramides: {
          unitCost: getActivityCost(ACTIVITY_PIRAMIDES),
          count: activitiesData.piramidesAttendeesCount || 0,
          total: getActivityCostByPerson(
            ACTIVITY_PIRAMIDES,
            activitiesData.piramidesAttendeesCount
          ),
        },
      },
      donation,
    };
    return totalDetailed;
  };
  return {
    getAccommodationPartialCost,
    getActivityCost,
    getActivityCostByPerson,
    getHotelCost,
    getHotelExtraNightCostPerNight,
    getCompanionCost,
    getJustConventionCosts,
    getActivitiesPartialCost,
    getGlobalTotal,
    getTotalDetailed,
  };
};
