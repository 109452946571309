import React from "react";
import { useTranslation } from "react-i18next";
import { useRegistrationFormUtils } from "hooks/useRegistrationFormUtils";
import { makeStyles } from "@material-ui/core/styles";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import { RegistrationInfoSummary } from "./ShortSummaryComponents/RegistrationInfoSummary";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { STEP_ACCOMMODATION_INFO } from "constants/registrationForm";
import { PACKAGE_CONVENTION_WITH_HOTEL } from "constants/registrationForm";
import { HotelSummary } from "./ShortSummaryComponents/HotelSummary";
import { ActivitiesInfoSummary } from "./ShortSummaryComponents/ActivitiesInfoSummary";
import { AdditionalInfoSummary } from "./ShortSummaryComponents/AdditionalInfoSummary";
import { TotalMoney } from "./TotalMoney";
import SnackbarContent from "components/Snackbar/SnackbarContent";
import { WhatIsIncluded } from "./WhatIsIncluded";
import { Box, Icon } from "@material-ui/core";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import Danger from "components/Typography/Danger";
import { PACKAGE_CONVENTION_WITHOUT_HOTEL } from "constants/registrationForm";
import { PACKAGE_NO_CONVENTION } from "constants/registrationForm";
import { WithoutHotelSummary } from "./ShortSummaryComponents/WithoutHotelSummary";
import { NoConventionSummary } from "./ShortSummaryComponents/NoConventionSummary";
const useStyles = makeStyles(basicsStyle);

export const ShortSummary = ({ registration }) => {
  const { t } = useTranslation(["registrationForm"]);
  const { getStepName, getPackageLabel } = useRegistrationFormUtils();
  const classes = useStyles();

  const registrationData = {
    ...registration.registration,
    email: registration.email,
    hasGivenPermissions: registration.hasGivenPermission,
    emergencyContact: { ...registration.emergencyContact },
  };
  return (
    <>
      <h3 className={classes.registrationTitle}>{t("finalStepTitle")}</h3>
      <SnackbarContent
        message={
          <span>
            <p>
              {t("assist_text_1")} <b>team@conventionoftheamericas.com</b>
              {". "}
              {t("assist_text_2")}
            </p>
          </span>
        }
        color="info"
        icon="info_outline"
      />
      <RegistrationInfoSummary registrationData={registrationData} />
      <GridContainer>
        <GridItem xs={12}>
          <h4 className={classes.registrationTitle}>
            {getStepName(STEP_ACCOMMODATION_INFO)}:{" "}
            {getPackageLabel(registration.selectedPackage)}
          </h4>
        </GridItem>
      </GridContainer>
      {registration.selectedPackage === PACKAGE_CONVENTION_WITH_HOTEL && (
        <HotelSummary
          hotelInfo={registration.totalDetailed.withHotel}
          companion={registration.hotelReservation.companionData}
          currency={registration.currency}
        />
      )}
      {registration.selectedPackage === PACKAGE_CONVENTION_WITHOUT_HOTEL && (
        <WithoutHotelSummary
          info={registration.totalDetailed.justConvention}
          guests={registration.hotelReservation.guestData}
          currency={registration.currency}
        />
      )}
      {registration.selectedPackage === PACKAGE_NO_CONVENTION && (
        <NoConventionSummary
          count={registration.hotelReservation.noConventionPeopleCount}
          guests={registration.hotelReservation.guestData}
          currency={registration.currency}
        />
      )}

      <ActivitiesInfoSummary
        activities={registration.totalDetailed.activities}
        currency={registration.currency}
      />
      <AdditionalInfoSummary
        donation={registration.totalDetailed.donation}
        comments={registration.comments}
        currency={registration.currency}
      />
      <TotalMoney
        justify="flex-end"
        total={registration.total}
        currency={registration.currency}
      />
      <SnackbarContent
        message={
          <span>
            <b>{t("finalStepImportantTitle")}</b>{" "}
            {t("finalStepImportantText_part1")}{" "}
            <b>{t("finalStepImportantText_part2")}</b>
            {t("finalStepImportantText_part3")}
            <b>{t("finalStepImportantText_part4")}</b>
          </span>
        }
        color="danger"
        icon="info_outline"
      />
      <WhatIsIncluded
        selectedPackage={registration.selectedPackage}
        title={t("finalStepWhatIsIncludedTitle")}
      />
      <GridItem xs={12}>
        <h4 className={classes.registrationTitle}>
          {t("finalStepIntructions1Title")}
        </h4>
        <GridItem xs={12}>
          <Box>
            <strong> {t("finalStepIntructions1Text_label1")} </strong>
            {t("finalStepIntructions1Text_text1")} <br />
            <strong> {t("finalStepIntructions1Text_label2")} </strong>
            {t("finalStepIntructions1Text_text2")} <br />
            <strong> {t("finalStepIntructions1Text_label3")} </strong>
            {t("finalStepIntructions1Text_text3")} <br />
            <strong> {t("finalStepIntructions1Text_label4")} </strong>
            {t("finalStepIntructions1Text_text4")} <br />
          </Box>
        </GridItem>
        <h4 className={classes.registrationTitle}>
          {t("finalStepIntructions2Title")}
        </h4>
        <GridItem xs={12}>
          <strong>ConventionOfTheAmericas@gmail.com</strong>
          <Box>
            <ul>
              <li>
                <strong style={{ color: "#f55a4e" }}>
                  {t("finalStepIntructions2Text1")}
                </strong>
                <br />
              </li>
              <li>
                {t("finalStepIntructions2Text2")}
                <br />
              </li>
            </ul>
          </Box>
        </GridItem>
        <h4 className={classes.registrationTitle}>
          {t("finalStepIntructions3Title")} <br />
        </h4>
        <GridItem xs={12}>
          <Box>
            {t("finalStepIntructions3Text1")}
            <br />
            <br />
          </Box>
        </GridItem>
        <GridItem>
          <SnackbarContent
            message={
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Icon
                    style={{
                      marginRight: "8px",
                    }}
                  >
                    email
                  </Icon>
                  <span>ConventionOfTheAmericas@gmail.com</span>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <WhatsAppIcon
                    style={{
                      marginRight: "8px",
                    }}
                  />
                  <span>+1 (575) 650-7344</span>
                </Box>
              </Box>
            }
            color="success"
          />
        </GridItem>
      </GridItem>
    </>
  );
};
