import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Step1, Step2, Step3, Step4, Summary } from ".";
import { useRegistrationFormUtils } from "hooks/useRegistrationFormUtils";
import { STEP_PERSONAL_INFO } from "constants/registrationForm";
import { STEP_ACCOMMODATION_INFO } from "constants/registrationForm";
import { STEP_ACTIVITY_INFO } from "constants/registrationForm";
import { STEP_ADDITIONAL_INFO } from "constants/registrationForm";
import { FinalStep } from "./Steps/FinalStep";

/* const registration2 = {
  date: 1714008587617,
  comments: "",
  hasGivenPermission: true,
  donations: "0",
  emergencyContact: {
    phone: "32564984613",
    name: "fulanito perez",
    relationship: "amigo",
    email: "fperez@gmail.com",
  },
  extraActivities: {
    galaDinnerPeopleCount: 0,
    excursionDay1: {
      name: "none",
      count: 0,
    },
    piramidePeopleCount: 0,
  },
  paypalData: {
    business: "",
    referID: "",
    name: "",
    email: "",
  },
  totalDetailed: {
    selectedPackage: "conventionWithHotel",
    noConvention: {
      total: 0,
    },
    justConvention: {
      total: 0,
      unitCost: 500.64,
      count: 0,
    },
    activities: {
      piramides: {
        total: 0,
        unitCost: 68.9,
        count: 0,
      },
      galaDinner: {
        total: 0,
        unitCost: 64.66,
        count: 0,
      },
      dayOneActivity: {
        total: 0,
        unitCost: 0,
        count: 0,
        id: "none",
      },
    },
    donation: 0,
    withHotel: {
      hotel: {
        unitCost: 726,
        id: "lourdes",
      },
      nightsBefore: {
        total: 0,
        unitCost: 107.8,
        count: 0,
      },
      nightsAfter: {
        total: 0,
        unitCost: 107.8,
        count: 0,
      },
      companionStatus: {
        total: 0,
        id: "noCompanion",
      },
    },
  },
  userId: "hmkGnE0KTCah0KyW4Wd994KrVv32",
  selectedPackage: "conventionWithHotel",
  total: 726,
  registration: {
    phone: "",
    city: "Córdoba",
    name: "ARY DEMITROPULOS w",
    title: "none",
  },
  currency: "USD",
  hotelReservation: {
    noConventionPeopleCount: 0,
    daysAfter: 0,
    guestData: [{}, {}, {}, {}],
    travelingWithCompanion: "noCompanion",
    justConventionPeopleCount: 0,
    hotelName: "lourdes",
    daysBefore: 0,
    companionData: {},
  },
  email: "demitropulosary@gmail.com",
}; */
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const defaultValueStep1 = () => {
  return {
    emergencyContactEmail: "",
    emergencyContactName: "",
    emergencyContactPhone: "",
    emergencyContactRelation: "",
    honorTitle: "none",
    name: "",
    phone: "",
  };
};

const defaultValueStep2 = () => {
  return {
    emergencyContactEmail: "",
    emergencyContactName: "",
    emergencyContactPhone: "",
    emergencyContactRelation: "",
    honorTitle: "none",
    name: "",
    phone: "",
  };
};

const defaultValueStep4 = {
  comments: "",
  donationAmount: 0,
  hasGivenPermissions: "yes",
  partialTotal: 0,
};

export function RegisterStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const { stepNameList } = useRegistrationFormUtils();
  const [formData, setFormData] = useState({});
  const [total, setTotal] = useState({});
  const [registration, setRegistration] = useState({});

  const handleFormData = (key, data) => {
    const partialTotal = data.partialTotal || 0;
    setFormData((form) => {
      const newData = { ...form };
      newData[key] = data;

      return newData;
    });

    setTotal((oldTotal) => {
      const newTotal = { ...oldTotal };
      newTotal[key] = parseFloat(partialTotal);
      return newTotal;
    });
    handleNext();
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        //return <FinalStep registration={registration2} />;
        return (
          <Step1
            onHandleSuccess={handleFormData}
            defaultFormValue={defaultValueStep1()}
            values={formData[STEP_PERSONAL_INFO]}
          />
        );
      case 1:
        return (
          <Step2
            onHandleSuccess={handleFormData}
            defaultFormValue={defaultValueStep1()}
            handlePrevious={handleBack}
            total={total}
            values={formData[STEP_ACCOMMODATION_INFO]}
          />
        );
      case 2:
        return (
          <Step3
            onHandleSuccess={handleFormData}
            defaultFormValue={defaultValueStep2()}
            handlePrevious={handleBack}
            total={total}
            values={formData[STEP_ACTIVITY_INFO]}
          />
        );
      case 3:
        return (
          <Step4
            onHandleSuccess={handleFormData}
            defaultFormValue={defaultValueStep4}
            handlePrevious={handleBack}
            total={total}
            values={formData[STEP_ADDITIONAL_INFO]}
          />
        );
      case 4:
        return (
          <Summary
            onHandleSuccess={goToFinalStep}
            handlePrevious={handleBack}
            data={formData}
          />
        );
      case 5:
        return <FinalStep registration={registration} />;
      default:
        return "Unknown step";
    }
  }

  function goToFinalStep(registration) {
    setRegistration(registration);
    handleNext();
  }
  const handleStepChange = () => {
    window.scrollTo(0, 0);
  };

  const handleNext = () => {
    handleStepChange();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    handleStepChange();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {stepNameList.map(({ id, label }) => {
          return (
            <Step key={id}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === stepNameList.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <div>{getStepContent(activeStep)}</div>
        )}
      </div>
    </div>
  );
}
