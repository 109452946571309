import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { RegistrationController } from "./RegistrationController";
import registrationsStyles from "assets/jss/material-kit-pro-react/views/registrationStyles";
const useStyles = makeStyles(registrationsStyles);

export const RegistrationsContainer = () => {
  const classes = useStyles();
  const { t } = useTranslation(["registrationForm"]);

  return (
    <div className="cd-section">
      <div className={classes.blog}>
        <div className={classes.registrationContainer}>
          <br />
          <br />
          <h2 className={classes.title}>{t("registrationFormTitle")}</h2>
          <div> {t("registrationFormSubtitle")}</div>
          <RegistrationController />
        </div>
      </div>
    </div>
  );
};
