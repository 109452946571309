import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { firebase } from "../../../../../firebase/firebase-config";
import axios from "axios";
// @material-ui/icons
import Theaters from "@material-ui/icons/Theaters";
import { useTranslation } from "react-i18next";

// core components

import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js";

/* import dg1 from "assets/img/dg1.jpg";
import dg2 from "assets/img/dg2.jpg";
import dg3 from "assets/img/dg3.jpg";
 */

import dg1 from "assets/img/convention/winter.jpg";
import { Link, Redirect } from "react-router-dom";

const useStyles = makeStyles(headersStyle);

export default function SectionHeaders({ t, ...rest }) {
  const isLogged = useSelector((state) => state.auth.uid);
  const { i18n } = useTranslation();
  const classes = useStyles();
  const [claimed, setClaimed] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const englishGift = "video_3525f6b8db62f4c95829a8554be2cb44";
  const spanishGift = "video_ca77b03e6f89ec6d73cce411e98776e4";
  const language = i18n.language;

  const myList = useSelector((state) => state.courses.myList);

  useEffect(() => {
    if (isLogged) {
      myList.forEach(({ id }) => {
        if (
          (language === "es" && id === spanishGift) ||
          (language === "en" && id === englishGift)
        ) {
          setClaimed(true);
        }
      });
    }
  }, [myList, isLogged, language]);
  const claimFreeVideo = async () => {
    //TODO: Poner la llamada al video gratis y setear el redirect a true cuando de todo ok

    await firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((token) => {
        let giftID = englishGift;
        if (language === "es") giftID = spanishGift;
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_URI}videos/buy`,
            { videos: [giftID] },
            {
              headers: { "x-firebase-token": token },
            }
          )
          .then((res) => {
            setClaimed(true);
            setRedirect(true);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    // we've set the className to cd-section so we can make smooth scroll to it

    <div>
      <div
        className={classes.pageHeader}
        style={{ backgroundImage: `url("${dg1}")` }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12}>
              <h1 className={classes.title}>{t("free-video-title")}</h1>
              <h4>{t("free-video-par1")}</h4>
              <h4>{t("free-video-par2")}</h4>
              <br />
              {isLogged && !claimed && (
                <Button
                  color="danger"
                  size="lg"
                  onClick={claimFreeVideo}
                  disabled={claimed}
                >
                  <Theaters />
                  {claimed
                    ? t("free-video-button-claimed")
                    : t("free-video-button")}
                </Button>
              )}
              {!isLogged && (
                <Link to="/login-page">
                  <Button color="info" size="lg">
                    {t("free-video-button-login")}
                  </Button>
                </Link>
              )}
            </GridItem>
            {isLogged && claimed && (
              <GridItem xs={12}>
                <h4>{t("free-video-go-to-my-courses-title")}</h4>
                <Link to="/my-courses">
                  <Button color="info" size="lg">
                    {t("free-video-go-to-my-courses-button")}
                  </Button>
                </Link>
              </GridItem>
            )}
            {redirect && (
              <Redirect
                to={{
                  pathname: "/my-courses",
                }}
              />
            )}
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
