import React from "react";
import logoImage from "assets/img/convention/logo.jpg";
import { makeStyles } from "@material-ui/core/styles";
import loginPageStyle from "./ImageLoginStyle";
const useStyles = makeStyles(loginPageStyle);

export default function ImageLogin() {
  const classes = useStyles();

  return (
    <div className={classes.imageContainer}>
      <img
        src={logoImage}
        alt="..."
        className={
          classes.imgRoundedCircle +
          " " +
          classes.imgFluid +
          " " +
          classes.imageLogo
        }
      />
    </div>
  );
}
