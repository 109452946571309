import React from "react";
import { useTranslation } from "react-i18next";

import GridItem from "components/Grid/GridItem";
import Info from "components/Typography/Info";

import { NumberOfPeopleSelect } from "components/Registrer/components/NumberOfPeopleSelect";
import { WhatIsIncluded } from "components/Registrer/components/WhatIsIncluded";
import { CompanionDataForm } from "components/Registrer/components/CompanionDataForm";
import { PACKAGE_CONVENTION_WITHOUT_HOTEL } from "constants/registrationForm";
import { useRegistrationFormCosts } from "hooks/useRegistrationFormCosts";
import SnackbarContent from "components/Snackbar/SnackbarContent";

export const ConventionWithoutHotel = ({
  register,
  control,
  justConventionPeopleCount,
}) => {
  const { t } = useTranslation(["registrationForm"]);
  const selectedPackage = PACKAGE_CONVENTION_WITHOUT_HOTEL;
  const { getJustConventionCosts } = useRegistrationFormCosts();

  return (
    <>
      <GridItem xs="12" style={{ marginTop: "20px" }}>
        <SnackbarContent
          message={
            <span>
              <b>{t("hotelAdvice")}</b>
            </span>
          }
          color="info"
          icon="hotel"
        />
      </GridItem>
      <GridItem xs={12}>
        <NumberOfPeopleSelect
          control={control}
          name="justConventionPeopleCount"
          allowJustMe
          costCalculator={getJustConventionCosts}
        />
      </GridItem>
      {justConventionPeopleCount > 1 && (
        <>
          <GridItem xs={12}>
            <Info>{t("packageNoConventionOtherPeopleInfo")}</Info>
          </GridItem>
          <GridItem xs={12}>
            <h5>{t("packageNoConventionOtherPeopleTitle")}: </h5>
          </GridItem>
          <CompanionDataForm
            register={register}
            numberOfCompanions={justConventionPeopleCount - 1}
            control={control}
            name="guest"
          />
        </>
      )}
      <WhatIsIncluded selectedPackage={selectedPackage} />
    </>
  );
};
