import React from "react";
import { makeStyles } from "@material-ui/core";
import style from "./SubtitleSmallerStyle";

const useStyles = makeStyles(style);

const SubtitleSmaller = ({ subtitle, align = "start" }) => {
  const classes = useStyles();
  return (
    <h3
      className={classes.subtitle}
      style={{
        textAlign: align,
      }}
    >
      {subtitle}
    </h3>
  );
};

export default SubtitleSmaller;