import { textJustify } from "assets/jss/material-kit-pro-react";
import {
  container,
  title,
  whiteColor,
  PrimaryFontColor,
  SecondaryFontColor,
} from "assets/jss/material-kit-pro-react.js";

const style = (theme) => ({
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    "& h1, & h4, & h6": {
      color: whiteColor,
    },
  },

  title: {
    ...title,
    textAlign: "center",
  },
  pageHeader: {
    position: "relative",
    height: "100vh",
    maxHeight: "1600px",
    backgroundPosition: "50%",
    backgroundSize: "cover",
    margin: "0",
    padding: "0",
    border: "0",
    display: "flex",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center",

    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''",
    },
  },

  titleContainer: {
    backgroundPosition: "left",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    justifyContent: "flex-end",
  },
  img: {
    height: "500px",
    width: "100%",
    backgroundSize: "contain",
    zIndex: "1000",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  subtitle: {
    ...title,
    color: SecondaryFontColor,
    textAlign: "center",
  },
  subtitleDescripcion: {
    color: PrimaryFontColor,
    fontWeight: 400,
    ...textJustify,
  },
  text: {
    color: PrimaryFontColor,
    fontSize: "20px",
    paddingTop: "15px",
    ...textJustify,
  },
  buttonContainer: {
    marginTop: "50px",
    marginBottom: "50px",
    display: "flex",
    justifyContent: "center",
  },
  numbersContainer: {
    display: "flex",
    alignItems: "center",
    height: "90%",
  },
});

export default style;
