import { types } from "types/authTypes";
import { db, firebase, googleAuthProvider } from "../firebase/firebase-config";
import { resetStatusState } from "./status";
import { resetCoursesState } from "./courses";

export const startLoginEmailPassword = (email, password) => {
  return (dispatch) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(({ user }) => {
        dispatch(login(user.uid, user.displayName, user.email));
      })
      .catch((e) => {
        dispatch(error(e));
        console.log(e);
      });
  };
};

export const startRegisterWithEmailPasswordName = (email, password, name) => {
  return (dispatch) => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async ({ user }) => {
        await user.updateProfile({ displayName: name });
        //TODO: enable allow create security to avoid users from being modified
        //TODO: set redirect to home
        db.collection("users").doc(user.uid).set({
          name: user.displayName,
          videos: [],
        });
        dispatch(login(user.uid, user.displayName, user.email));
      })
      .catch((e) => {
        dispatch(error(e));
        console.log(e);
      });
  };
};

export const startLogout = () => {
  return async (dispatch) => {
    await firebase.auth().signOut();
    dispatch(logout());
    dispatch(resetCoursesState());
    dispatch(resetStatusState());
  };
};

export const startGoogleLogin = () => {
  return (dispatch) => {
    firebase
      .auth()
      .signInWithPopup(googleAuthProvider)
      .then(({ additionalUserInfo, user }) => {
        if (additionalUserInfo.isNewUser) {
          db.collection("users").doc(user.uid).set({
            name: user.displayName,
            videos: [],
          });
        }
        //dispatch(login(user.uid, user.displayName));
      });
  };
};

export const login = (uid, displayName, email) => ({
  type: types.login,
  payload: { uid, displayName, email },
});

export const logout = () => ({
  type: types.logout,
});

export const error = (error) => ({
  type: types.error,
  payload: { error },
});
