import { types } from "types/coursesTypes";
const initialState = {
  list: [],
  cart: [],
  myList: [],
};
export const coursesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.loadList:
      return { ...state, list: action.payload.data };
    case types.loadMyList:
      return { ...state, myList: action.payload.data };
    case types.addToCart:
      if (!state.cart.includes(action.payload.data)) {
        return { ...state, cart: [...state.cart, action.payload.data] };
      } else {
        return state;
      }

    case types.removeFromCart: {
      const newCart = state.cart.filter(
        (course) => course !== action.payload.data
      );
      return { ...state, cart: newCart };
    }
    case types.cleanCart: {
      return { ...state, cart: [] };
    }
    case types.reset: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
