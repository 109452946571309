import { SecondaryFontColor } from "assets/jss/material-kit-pro-react.js";
import {
  subtitleLandingMedium,
  subtitleLandingLight,
  cardTitle,
  subtitleLandingBold,
  description,
  textJustify,
} from "assets/jss/material-kit-pro-react.js";

const style = (theme) => ({
  title: { ...subtitleLandingMedium, textTransform: "none" },
  cardTitle: {
    ...subtitleLandingBold,
  },
  description: {
    ...description,
    ...subtitleLandingLight,
    color: "white",
    fontSize: "16px",
    ...textJustify,
  },
  showMoreOrLess: {
    ...subtitleLandingLight,
    cursor: "pointer",
    color: "black",
    marginTop: "10px",
    textDecoration: "underline"
  },
  speakerContainer: {
    margin: "20px",
  },
  imgContainer: {
    width: "100%",
    paddingTop: "100%",
    position: "relative",
    marginBottom: "15px",
  },
  imgContainerWhite: {
    backgroundColor: "white",
    padding: "3px",
    borderRadius: "50%",
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
  },

  name: {
    textTransform: "uppercase",
    color: SecondaryFontColor,
  },
  justifyContent: {
    WebkitBoxPack: "center !important",
    MsFlexPack: "center !important",
    justifyContent: "center !important",
  },
  btn: {
    ...subtitleLandingLight,
    textTransform: "none"
  }
});

export default style;
