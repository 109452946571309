import React from "react";
import "./customStyles.css";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

import { Controller } from "react-hook-form";
import {
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";

export const CustomRadioInput = ({
  name,
  label,
  control,
  defaultValue,
  children,
  errors,
  options,
  rules,
  ...props
}) => {
  return (
    <FormControl
      component="fieldset"
      error={errors && !!errors.selectedOption}
      {...props}
    >
      <h5>{label}</h5>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        as={
          <RadioGroup>
            {options.map((el) => (
              <FormControlLabel
                className="form-control-radio-input-label"
                key={el.id}
                value={el.id}
                control={<Radio />}
                label={el.label}
                style={{ width: "100%" }}
              />
            ))}
          </RadioGroup>
        }
      />
      {errors && errors.selectedOption && (
        <FormHelperText>{errors.selectedOption.message}</FormHelperText>
      )}
    </FormControl>
  );
};

/**
 *  render={({ field }) => (
        <RadioGroup {...field}>
          <FormControlLabel value="option1" control={<Radio />} label="Option 1" />
          <FormControlLabel value="option2" control={<Radio />} label="Option 2" />
          <FormControlLabel value="option3" control={<Radio />} label="Option 3" />
        </RadioGroup>
      )}
 */
