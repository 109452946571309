import React, { useEffect, useState } from "react";

import { useRegistrationForm } from "hooks/useRegistrationForm";
import { Error } from "./Error";
import { RegisterStepper } from "components/Registrer";
import Loading from "components/Loading/Loading";
import { ShortSummary } from "components/Registrer/components/ShortSummary";
import { RegistrationInfo } from "./RegistrationInfo";

export const SectionController = () => {
  const [showRegistrationInfo, setShowRegistrationInfo] = useState(false);
  const {
    error,
    loading,
    registration,
    getRegistrationData,
    hasToRegistrate,
  } = useRegistrationForm();

  useEffect(() => {
    getRegistrationData();
  }, []);

  const goToStepper = () => {
    setShowRegistrationInfo(true);
  };

  if (registration) return <ShortSummary registration={registration} />;
  if (loading) return <Loading />;
  if (hasToRegistrate) {
    if (!showRegistrationInfo)
      return <RegistrationInfo onRegisterStart={goToStepper} />;
    return <RegisterStepper />;
  }
  if (error) return <Error error={error} />;

  return <></>;
};
